.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modalHeader h2 {
    color: #fff;
    margin: 0;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modalBody {
    color: #fff;
  }
  
  .taskModalDetails {
    margin-bottom: 20px;
  }
  
  .taskModalDescriptionTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .taskModalReward {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .taskModalCoin {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .taskModalRewards {
    display: flex;
    flex-direction: column;
  }
  
  .taskModalRewardsLabel {
    font-size: 14px;
    color: #888;
  }
  
  .taskModalRewardsValue {
    font-size: 18px;
    font-weight: bold;
  }
  
  .startButton {
    width: 100%;
    padding: 10px;
    background-color: #dfa21e;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .startButton:hover {
    background-color: #dfa21e;
  }

  /* Стили для Toastify */
.Toastify__toast-container {
    width: 100%;
  }
  
  .Toastify__toast--dark {
    background-color: #1a1a1a;
    color: #ffffff;
  }
  
  .Toastify__progress-bar--dark {
    background-color: #B364D8;
  }