.img-responsive {
    display: block;
    height: auto;
    max-width: 100%
}

.no-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.no-select:focus {
    outline: none!important
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

body,html {
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    text-rendering: optimizeLegibility!important;
    width: 100%;
    -webkit-font-smoothing: antialiased!important
}

html {
    background-color: #111112
}

body {
    color: #fff;
    cursor: default;
    font-family: 'Orbitron', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    min-width: 320px;
    -webkit-overflow-scrolling: touch!important
}

footer,main,section {
    position: relative
}

.page {
    flex-direction: column;
    margin: 0 auto;
    max-width: 768px;
    min-height: 100vh;
    position: relative;
    width: 100%;
    z-index: 1
}

.main,.page {
    align-items: stretch;
    display: flex
}

.main {
    justify-content: flex-start
}

.main,.main-center {
    flex: 1 1 auto;
    flex-direction: column
}

.main-center {
    align-items: center;
    display: flex;
    justify-content: center
}

.main-center>svg {
    height: 75px;
    width: 150px
}

.page-enter-active,.page-leave-active {
    transition: opacity .1s .25s;
    -webkit-transition: opacity .1s .25s
}

.page-enter,.page-leave-to {
    opacity: 0
}

.container {
    margin: 0 auto;
    max-width: 768px;
    width: 100%
}

img {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

a {
    text-decoration: none
}

svg {
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

u1l {
    list-style: none
}

.button {
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-family: inherit;
    outline: none;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%
}

.button:disabled {
    cursor: not-allowed
}

.button:disabled.button-primary {
    background-color: #4e4f50cc
}

.button-copy {
    background-color: transparent;
    color: #4e4f50
}

.button-copy:active,.button-copy:hover {
    color: #fff
}

.button-squad {
    align-items: center;
    background: #5a60ff;
    border-radius: 12px;
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    line-height: 16px;
    padding: 12px 24px;
    width: auto
}

.button-squad:hover {
    background-color: #383fff
}

.button-primary {
    background: #5a60ff;
    color: #fff
}

.button-primary:active,.button-primary:hover {
    background-color: #db8600
}

.button-dark {
    background: #272a2f;
    border: 2px solid hsla(0,0%,100%,.1);
    color: #fff
}

.button-dark:active,.button-dark:hover {
    background-color: #25282d
}

.button-small {
    border-radius: 16px;
    display: inline-flex;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    padding: 0 48px;
    width: auto
}

.button-small svg {
    fill: #fff;
    height: 20px;
    margin-left: 6px;
    width: 20px
}

.button-small-v2 {
    border-radius: 16px;
    display: inline-flex;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    padding: 0 24px;
    width: auto
}

.button-small-v2 svg {
    fill: #fff;
    height: 20px;
    margin-left: 6px;
    width: 20px
}

.button-xs {
    border-radius: 16px;
    display: inline-flex;
    font-size: 16px;
    font-weight: 700;
    height: 32px;
    padding: 0 24px;
    width: auto
}

.button-xs svg {
    fill: #fff;
    height: 20px;
    margin-left: 6px;
    width: 20px
}

.button-default {
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    height: 60px
}

.button-default svg {
    height: 24px;
    margin-left: 6px;
    width: 24px
}

.button-default img {
    margin-left: 6px
}

.button-large {
    border-radius: 20px;
    font-size: 20px;
    font-weight: 700;
    height: 80px;
    padding: 0 12px
}

.button-large svg {
    height: 24px;
    margin-left: 6px;
    width: 24px
}

.button-large img {
    margin-left: 6px
}

.button-gradient {
    background: linear-gradient(96deg,#5a60ff,#b65aff 99.09%);
    border-radius: 8px;
    padding: 8px
}

.button-gradient:before {
    border: 2px solid hsla(0,0%,100%,.12);
    border-radius: 8px;
    content: "";
    display: block;
    height: calc(100% - 4px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 4px);
    z-index: 2;
    -webkit-tap-highlight-color: transparent;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.button-gradient:hover {
    background: linear-gradient(96deg,#b65aff,#5a60ff 99.09%)
}

.button-gradient:hover:before {
    border-color: #ffffff4d
}

.pulse {
    animation: pulse-animation 1s ease-in-out infinite!important
}

.pulse-balance {
    animation: pulse-balance-animation .5s ease-in-out infinite!important
}

@keyframes pulse-animation {
    0% {
        transform: scale(.98)
    }

    50% {
        transform: scale(1.02)
    }

    to {
        transform: scale(.98)
    }
}

@keyframes pulse-balance-animation {
    0% {
        transform: scale(.98)
    }

    50% {
        transform: scale(1.02)
    }

    to {
        transform: scale(.98)
    }
}

.input {
    border: 1px solid #111112;
    box-shadow: none;
    display: block;
    font-family: inherit;
    outline: none;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.input.input-default {
    background-color: #272a2f;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    height: 48px;
    margin-bottom: 6px;
    padding: 0 16px;
    width: 100%
}

.input.input-default::-webkit-input-placeholder {
    color: #8b8e93;
    font-size: 14px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.input.input-default:-moz-placeholder,.input.input-default::-moz-placeholder {
    color: #8b8e93;
    font-size: 14px;
    -moz-transition: all .25s ease;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.input.input-default:-ms-input-placeholder {
    color: #8b8e93;
    font-size: 14px;
    -ms-transition: all .25s ease;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.input.input-default:hover::-webkit-input-placeholder {
    opacity: .5
}

.input.input-default:hover:-moz-placeholder,.input.input-default:hover::-moz-placeholder {
    opacity: .5
}

.input.input-default:hover:-ms-input-placeholder {
    opacity: .5
}

.input-wrapper {
    position: relative
}

.input-wrapper.has-check .input {
    padding-right: 36px
}

.input-wrapper.has-check .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%)
}

.icon {
    align-items: center;
    display: flex;
    justify-content: center
}

.icon svg {
    height: 100%;
    width: 100%
}

.icon.is-white {
    fill: #fff
}

.icon.is-yellow {
    color: #ffd337
}

.icon.icon-info,.icon.icon-info svg {
    height: 20px;
    width: 20px
}

.icon.icon-earn,.icon.icon-earn svg,.icon.icon-friends,.icon.icon-friends svg,.icon.icon-mine,.icon.icon-mine svg,.icon.icon-squad,.icon.icon-squad svg {
    height: 28px;
    width: 28px
}

.icon.icon-heart,.icon.icon-heart svg {
    height: 24px;
    width: 24px
}

.icon.icon-arrow-onboarding,.icon.icon-arrow-onboarding svg {
    height: 89px;
    width: 89px
}

.icon.icon-info svg {
    height: 18px;
    width: 18px
}

.icon.icon-energy-large svg {
    height: 104px;
    width: 80px
}

.icon.is-12 {
    height: 12px;
    width: 12px
}

.icon.is-16 {
    height: 16px;
    width: 16px
}

.icon.is-24 {
    height: 24px;
    width: 24px
}

.icon.is-32 {
    height: 32px;
    width: 32px
}

.is-token-image {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}




.is-title,h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px
}

.text-yellow {
    color: #ffd337
}

.text-green {
    color: #82f88e
}

.text-blue {
    color: #7277ff
}

.text-orange {
    color: #ff9060
}

.text-grey {
    color: #8b8e93
}

.text-white40 {
    color: #fff6
}

.section-title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 32px
}

.section-description {
    color: #8b8e93;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: -12px
}

.airdrop-top-image {
    height: 106px;
    margin: 24px auto;
    position: relative;
    width: 106px
}

.airdrop-top-image img {
    position: relative;
    z-index: 2
}

.airdrop-top-image .icon {
    height: 275px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 275px;
    z-index: 1
}

.airdrop-top-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 12px;
    margin-top: 44px;
    text-align: center
}

.airdrop-info {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    margin: 0 -6px
}

.airdrop-info-item {
    background: #272a2f;
    border-radius: 20px;
    flex: 1 1;
    margin: 0 6px;
    padding: 12px
}

.airdrop-info-item-title {
    color: #8b8e93;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    min-height: 32px;
    text-align: center
}

.airdrop-info-item-status {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
    line-height: 32px
}

.airdrop-info-item-status .icon {
    margin-right: 4px
}

.airdrop-item {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    padding-right: 12px;
    position: relative;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.airdrop-item:last-child {
    margin-bottom: 0
}

.airdrop-item:hover .earn-item-icon svg {
    color: #fff
}

.airdrop-item.is-disabled {
    cursor: not-allowed;
    opacity: .5
}

.airdrop-item.is-disabled-task {
    cursor: not-allowed
}

.airdrop-item.is-disabled-task .airdrop-item-content-bottom,.airdrop-item.is-disabled-task .airdrop-item-content-top,.airdrop-item.is-disabled-task .airdrop-item-image {
    opacity: .6
}

.airdrop-item.is-disabled-task .airdrop-item-content-top {
    padding-right: 80px
}

.airdrop-item.is-disabled-task.is-connect_ton_wallet {
    background: linear-gradient(98deg,#35a6eb99 3.58%,#309adb99 101.32%)
}

.airdrop-item.is-completed {
    background: #272a2fb3
}

.airdrop-item.is-completed svg {
    color: #fff
}

.airdrop-item.is-not-available {
    cursor: not-allowed
}

.airdrop-item.is-not-available .boost-item-content-bottom {
    opacity: .3
}

.airdrop-item.is-connect_ton_wallet {
    background: linear-gradient(98deg,#35a6eb 3.58%,#309adb 101.32%)
}

.airdrop-item.is-connect_ton_wallet .airdrop-item-icon-success {
    background: #fff
}

.airdrop-item.is-connect_ton_wallet .airdrop-item-icon-success .icon svg {
    color: #309adb!important
}

.airdrop-item.is-connect_ton_wallet .airdrop-item-icon .icon svg {
    color: #ffffff80
}

.airdrop-item-image {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-width: 72px;
    padding: 8px;
    width: 72px
}

.airdrop-item-image img,.airdrop-item-image svg {
    border-radius: 10px;
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto
}

.airdrop-item-image .exchange-image {
    height: 100%;
    width: 100%
}

.airdrop-item-content {
    flex: 1 1 auto
}

.airdrop-item-content-soon {
    background: #35a5e9;
    border-radius: 8px;
    bottom: 10px;
    font-size: 12px;
    padding: 4px 6px;
    position: absolute;
    right: 10px
}

.airdrop-item-content-top {
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 4px
}

.airdrop-item-content-bottom {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: flex-start
}

.airdrop-item-content-bottom span {
    font-weight: 700;
    margin-left: 6px
}

.airdrop-item-icon-success {
    align-items: center;
    background: linear-gradient(180deg,#74fc82,#297e32);
    border-radius: 50%;
    display: flex;
    height: 26px;
    justify-content: center;
    width: 26px
}

.airdrop-item-icon-success svg {
    color: #fff;
    height: 12px;
    width: 12px
}

.airdrop-item-icon>.icon svg {
    color: #4e4f50;
    height: 24px;
    width: 24px
}

.airdrop-item-bottom-info {
    bottom: 12px;
    color: #8b8e93;
    font-size: 14px;
    position: absolute;
    right: 20px
}

.app-bar {
    bottom: 0;
    justify-content: center;
    left: 0;
    padding: 9px 16px;
    pointer-events: none;
    position: fixed;
    right: 0;
    z-index: 10
}

.app-bar,.app-bar-nav {
    align-items: center;
    display: flex;
    width: 100%
}

.app-bar-nav {
    border-radius: 20px;
    justify-content: space-between;
    max-width: 768px;
    padding: 0px;
    pointer-events: all
}

.app-bar-item {
    align-items: center;
    border-radius: 16px;
    color: #8b8e93;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 10px;
    height: 56px;
    justify-content: flex-start;
    padding: 5px;
    position: relative
}

.app-bar-item-notification {
    background-color: #ff3b30;
    border-radius: 50%;
    height: 10px;
    left: 50%;
    margin-left: 10px;
    position: absolute;
    top: 6px;
    width: 10px
}

.app-bar-item-image {
    align-items: center;
    display: flex;
    height: 28px;
    justify-content: center;
    margin-bottom: 5px;
    width: 28px
}

.app-bar-item-image img {
    display: block;
    height: auto;
    max-width: 100%
}

.app-bar-item-image .icon {
    color: #4e4f50
}

.app-bar-item-image picture {
    display: block;
    height: auto;
    max-height: 28px;
    max-width: 28px;
    width: 100%
}

.app-bar-item-image picture img {
    display: block;
    height: auto;
    max-width: 100%
}

.app-bar-item:hover,.app-bar-item:hover .icon {
    color: #fff
}

.app-bar-item.is-active,.app-bar-item.router-link-active {
    background-color: #1c1f24;
    color: #fff
}

.app-bar-item.is-active .icon svg,.app-bar-item.router-link-active .icon svg {
    color: #fff
}

.boost-top-text {
    color: #8b8e93
}

.boost-top-link,.boost-top-text {
    font-size: 16px;
    text-align: center
}

.boost-top-link {
    color: #ffd337;
    cursor: pointer;
    display: block;
    margin-bottom: 8px
}

.boost-row {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.boost-row .boost-item {
    flex: 1 1;
    margin-right: 12px
}

.boost-row .boost-item:last-child {
    margin-right: 0
}

.boost-row .boost-item-image {
    height: 100%;
    width: 56px
}

.boost-row .boost-item-image svg {
    height: 32px;
    margin-bottom: 0;
    width: 32px
}

.boost-row .boost-item-image img {
    height: 48px;
    width: 48px
}

.boost-column {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}

.boost-column .boost-item {
    margin-bottom: 6px
}

.boost-column .boost-item:last-child {
    margin-bottom: 0
}

.boost-column .boost-item-image {
    height: 100%;
    width: 72px
}

.boost-column .boost-item-image svg {
    height: 32px;
    margin-bottom: 0;
    width: 32px
}

.boost-item {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    padding-right: 12px;
    position: relative;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.boost-item:hover .boost-item-icon svg {
    color: #fff
}

.boost-item.is-disabled {
    cursor: not-allowed;
    opacity: .5
}

.boost-item.is-completed {
    cursor: default;
    opacity: .7
}

.boost-item.is-not-available {
    cursor: not-allowed
}

.boost-item.is-not-available .boost-item-content-bottom {
    opacity: .3
}

.boost-item-image {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 6px
}

.boost-item-image svg {
    height: 32px;
    margin-bottom: 0;
    width: 32px
}

.boost-item-image img {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto
}

.boost-item-content {
    flex: 1 1 auto
}

.boost-item-content-top {
    font-size: 16px;
    margin-bottom: 4px
}

.boost-item-content-top:last-child {
    margin-bottom: 0
}

.boost-item-content-bottom {
    align-items: center;
    color: #8b8e93;
    display: flex;
    font-size: 14px;
    justify-content: flex-start
}

.boost-item-content-bottom.is-locked {
    color: #ffd337;
    font-size: 14px;
    font-weight: 700
}

.boost-item-content-bottom.is-locked svg {
    height: 20px;
    margin-right: 6px;
    width: 20px
}

.boost-item-icon svg {
    color: #4e4f50;
    height: 24px;
    width: 24px
}

.boost-item-bottom-info {
    bottom: 12px;
    color: #8b8e93;
    font-size: 14px;
    position: absolute;
    right: 20px
}

.boost-list {
    list-style: none
}

.boost-list li {
    background: #272a2f;
    border-radius: 20px;
    height: 64px;
    margin-bottom: 6px;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.boost-tag {
    background: #34383f;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 6px;
    position: absolute;
    right: 8px;
    top: 8px
}

.boost-profit-info {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center
}

.boost-profit-info span {
    font-size: 16px;
    font-weight: 700
}

.content {
    background: #111112;
    border-radius: 40px 40px 0 0;
    flex: 1 1 auto;
    padding: 20px 16px 70px
}

.content.is-main {
    min-height: calc(100vh - 62px)
}

.content.is-exchange {
    margin-top: 12px;
    padding-bottom: 20px
}

.content.is-exchange h1 {
    margin-bottom: 32px;
    text-align: center
}

.content.is-onboarding {
    margin-top: 12px;
    padding-bottom: 20px;
    padding-top: 30px
}

.content.is-onboarding .user-level-progress {
    margin-top: 32px
}

.content.has-glow {
  background: url('bg.jpg') no-repeat center center;
  background-size: cover; /* Заполняет весь фон */
  border-radius: 0px 0px 0 0;
  border-top: 2px solid #fe9305;
  box-shadow: 0 -4px 64px #8e49cc99;
  margin-top: 12px;
}

.content-onboarding-info {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0
}

.content-onboarding-info p {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-right: 18px;
    max-width: 60%
}

.content-onboarding-info p span {
    color: #ffd337;
    white-space: nowrap
}

.content-onboarding-info-text {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px
}

.content-no-telegram {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.content-no-telegram img {
    display: block;
    height: auto;
    margin-bottom: 20px;
    max-width: 240px
}

.content-no-telegram p {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center
}

.content-no-telegram p.is-version {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 24px
}

.content-no-telegram a {
    color: #fff;
    font-size: 24px
}

.content-coins {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 999999
}

.content-coins>div {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    line-height: 1;
    pointer-events: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100px;
    z-index: 60
}

.inner {
    flex: 1 1 auto;
    padding: 20px 16px 70px
}

.daily-combo {
    margin-bottom: 16px;
    position: relative
}

.daily-combo.is-morse {
    margin-bottom: 0;
    margin-top: 12px
}

.daily-combo.is-morse .daily-combo-progress {
    margin-bottom: 0
}

.daily-combo-timer {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    margin-bottom: 8px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.daily-combo-timer .icon,.daily-combo-timer p {
    color: #8b8e93;
    cursor: pointer
}

.daily-combo-timer .icon {
    margin-left: 6px
}

.daily-combo-timer .icon:hover {
    color: #fff
}

.daily-combo-progress {
    align-items: center;
    background: #272a2f;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 6px
}

.daily-combo-progress-title {
    flex: 1 1 calc(50% - 30px);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    max-width: 50%;
    padding-left: 6px;
    padding-right: 6px
}

.daily-combo-progress-center {
    align-items: center;
    display: flex;
    flex: 0 0 60px;
    justify-content: center
}

.daily-combo-progress-center u1l {
    align-items: center;
    display: inline-flex;
    justify-content: center
}

.daily-combo-progress-center u1l li {
    background: #4e4f50;
    border-radius: 50%;
    height: 14px;
    margin: 0 2px;
    position: relative;
    width: 14px
}

.daily-combo-progress-center u1l li:before {
    border: 2px solid hsla(0,0%,100%,.15);
    border-radius: 50%;
    content: "";
    display: block;
    height: calc(100% - 4px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 4px)
}

.daily-combo-progress-center u1l li.is-completed {
    background: linear-gradient(180deg,#74fc82,#297e32)
}

.daily-combo-progress-button {
    display: flex;
    flex: 0 0 calc(50% - 30px);
    justify-content: flex-end
}

.daily-combo-progress-button .button {
    width: auto
}

.daily-combo-progress-claimed {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end
}

.daily-combo-progress-claimed-info {
    align-items: center;
    background-color: #1c1f24;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    min-height: 36px;
    padding: 8px
}

.daily-combo-progress-claimed-check {
    align-items: center;
    background: linear-gradient(180deg,#74fc82,#297e32);
    border-radius: 50%;
    color: #1c1f24;
    display: flex;
    height: 20px;
    justify-content: center;
    margin-left: 12px;
    width: 20px
}

.daily-combo-cards {
    margin: 0 -6px
}

.daily-combo-card,.daily-combo-cards {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative
}

.daily-combo-card {
    cursor: pointer;
    flex: 1 1;
    height: 112px;
    margin: 0 6px
}

.daily-combo-card-inner {
    align-items: center;
    background: #272a2f;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 2;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.daily-combo-card-image {
    height: 72px;
    margin-bottom: 6px;
    margin-top: 6px;
    padding: 0 6px;
    width: 100%
}

.daily-combo-card-name {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 6px;
    min-height: 24px;
    padding: 0 6px;
    text-align: center
}

.daily-combo-card-unknown {
    background-color: #ffffff0d;
    border-radius: 12px;
    height: calc(100% - 32px);
    margin: 16px;
    position: relative;
    width: calc(100% - 32px)
}

.daily-combo-card-unknown-wrap {
    border-radius: 12px;
    bottom: 0;
    height: calc(100% + 20px);
    overflow: hidden;
    position: absolute;
    width: 100%
}

.daily-combo-card-unknown-wrap img {
    bottom: -10px;
    left: 50%;
    transform: translate(-50%)
}

.daily-combo-card-unknown-wrap img,.daily-combo-card:before {
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.daily-combo-card:before {
    background: linear-gradient(180deg,#4146ff,#272a2f);
    border-radius: 16px;
    content: "";
    display: block;
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    z-index: 1
}

.daily-combo-card.is-completed:before {
    background: linear-gradient(180deg,#61cb6c,#272a2f)
}

.daily-combo-success {
    align-items: center;
    background-color: #111112d9;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    min-width: 320px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999990
}

.daily-combo-success .bs-content-subtitle {
    max-width: 280px
}

.daily-combo-success-content {
    position: relative
}

.daily-combo-success-content canvas {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: -1
}

.daily-combo-success-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 16px;
    text-align: center;
    width: 100%
}

.daily-combo-success-divider {
    flex: 1 1
}

.daily-combo-success-header p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px
}

.daily-combo-success-button {
    position: relative;
    width: 100%;
    z-index: 10
}

.daily-cipher-code {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all .5s ease;
    -webkit-transition: all .5s ease
}

.daily-cipher-code span {
    font-size: 18px;
    font-weight: 700;
    margin: 0 1px
}

.daily-cipher-code span.new-char {
    animation: scaleAndFade .5s ease
}

@keyframes scaleAndFade {
    0% {
        opacity: 0;
        transform: scale(7)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

.earn-image,.earn-image picture {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.earn-top-image {
    height: 106px;
    margin: 24px auto;
    position: relative;
    width: 106px
}

.earn-top-image img {
    position: relative;
    z-index: 2
}

.earn-top-image .icon {
    height: 275px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 275px;
    z-index: 1
}

.earn-top-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 12px;
    margin-top: 44px;
    text-align: center
}

.earn-top-link {
    color: #ffd337;
    cursor: pointer;
    display: block;
    font-size: 16px;
    text-align: center
}

.earn-item {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    padding-right: 12px;
    position: relative;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.earn-item:last-child {
    margin-bottom: 0
}

.earn-item:hover .earn-item-icon svg {
    color: #fff
}

.earn-item.is-disabled {
    cursor: not-allowed;
    opacity: .5
}

.earn-item.is-completed {
    background: #272a2fb3
}

.earn-item.is-completed svg {
    color: #fff
}

.earn-item.is-not-available {
    cursor: not-allowed
}

.earn-item.is-not-available .boost-item-content-bottom {
    opacity: .3
}

.earn-item-image {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-width: 72px;
    padding: 8px;
    width: 72px
}

.earn-item-image img,.earn-item-image svg {
    border-radius: 10px;
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto
}

.earn-item-image .exchange-image {
    height: 100%;
    width: 100%
}

.earn-item-content {
    flex: 1 1 auto;
    padding-right: 4px
}

.earn-item-content-top {
    font-size: 16px;
    margin-bottom: 4px
}

.earn-item-content-top:last-child {
    margin-bottom: 0
}

.earn-item-content-bottom {
    align-items: center;
    color: #8b8e93;
    display: flex;
    font-size: 14px;
    justify-content: flex-start
}

.earn-item-icon-success {
    align-items: center;
    background: linear-gradient(180deg,#74fc82,#297e32);
    border-radius: 50%;
    display: flex;
    height: 26px;
    justify-content: center;
    width: 26px
}

.earn-item-icon-success svg {
    color: #fff;
    height: 12px;
    width: 12px
}

.earn-item-icon>.icon svg {
    color: #4e4f50;
    height: 24px;
    width: 24px
}

.earn-item-bottom-info {
    bottom: 12px;
    color: #8b8e93;
    font-size: 14px;
    position: absolute;
    right: 20px
}

.exchange-info {
    margin-bottom: 20px
}

.exchange-info,.exchange-info-image {
    align-items: center;
    display: flex;
    justify-content: center
}

.exchange-info-image {
    height: 40px;
    margin-right: 12px;
    width: 40px
}

.exchange-info-image img {
    display: block;
    height: auto;
    max-width: 100%
}

.exchange-info p {
    font-size: 20px;
    font-weight: 700
}

.exchange-list {
    list-style: none
}

.exchange-list li {
    align-items: center;
    background-color: #272a2f;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 12px 16px
}

.exchange-list li:last-child {
    margin-bottom: 0
}

.exchange-list li.is-disabled {
    cursor: not-allowed;
    opacity: .5
}

.exchange-list-image {
    align-items: center;
    display: flex;
    height: 46px;
    justify-content: center;
    margin-right: 16px;
    width: 46px
}

.exchange-list-image img {
    max-height: 40px;
    max-width: 40px
}

.exchange-list-content {
    flex: 1 1 auto
}

.exchange-list-content p {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px
}

.exchange-list-content p:last-child {
    margin-bottom: 0
}

.exchange-list-content span {
    color: #8b8e93;
    display: block;
    font-size: 14px;
    line-height: 1
}

.exchange-list-bonus {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    justify-content: flex-end
}

.exchange-list-bonus p {
    margin-left: 6px
}

.exchange-image {
    overflow: hidden
}

.exchange-image.is-bingx {
    border-radius: 10px
}

.exchange-image.is-bingx.is-border-6 {
    border-radius: 6px
}

.exchange-image img,.exchange-image svg {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%
}

.friends {
    margin-bottom: 66px
}

.friends.is-exchange {
    margin-bottom: 154px
}

.friends.is-exchange-added {
    margin-bottom: 66px
}

.friends-button {
    bottom: 70px;
    left: 0;
    padding: 0 16px;
    position: fixed;
    right: 0;
    z-index: 10
}

.friends-button-inner {
    align-items: center;
    display: flex
}

.friends-button-inner .is-invite {
    flex: 1 1 auto
}

.friends-button-inner .is-copy {
    flex: 0 0 60px;
    margin-left: 6px
}

.friends-button-inner .is-copy svg {
    margin-left: 0
}

.friends-button.is-exchange {
    background-color: #000
}

.friends-button.is-exchange-added {
    background-color: transparent
}

.friends-button .section-title {
    margin-bottom: 6px;
    margin-top: 12px
}

.friends-button.is-keyboard-opened {
    bottom: 20vh;
    min-height: 40vh;
    position: absolute
}

.friends-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 12px;
    text-align: center
}

.friends-description {
    margin-bottom: 32px;
    text-align: center
}

.friends-invite li {
    align-items: flex-start;
    background: #272a2f;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 12px 12px 24px
}

.friends-invite li:last-child {
    margin-bottom: 0
}

.friends-invite-image {
    flex: 0 0 60px;
    margin-right: 12px;
    position: relative
}

.friends-invite-image.is-square {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 60px
}

.friends-invite-info {
    flex: 1 1 auto
}

.friends-invite-info-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 12px;
    margin-top: 4px
}

.friends-invite-info-item {
    align-items: flex-start;
    display: flex;
    font-size: 14px;
    position: relative
}

.friends-invite-info-item.no-circle:before {
    display: none
}

.friends-invite-info-item:before {
    background-color: #ffd337;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 6px;
    margin-right: 6px;
    margin-top: 7px;
    width: 6px
}

.friends-invite-info-item span {
    display: inline;
    margin-left: 2px;
    margin-top: 3px
}

.friends-no {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    min-height: 80px;
    padding: 16px;
    text-align: center
}

.friends-no>p {
    color: #4e4f50;
    font-weight: 700
}

.friends-no-error {
    font-size: 14px;
    line-height: 20px
}

.friends-no-error p {
    margin-bottom: 12px
}

.friends-no-error p:last-child {
    margin-bottom: 0
}

.friends-no-error p.is-bold {
    font-weight: 700
}

.friends-no-icon {
    height: 100px;
    margin: 0 auto 20px;
    width: 100px
}

.friends-no-icon .icon,.friends-no-icon .icon svg {
    height: 100%;
    width: 100%
}

.friends-more-bonuses {
    color: #5a60ff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.friends-bonuses {
    margin-top: 32px
}

.friends-bonuses>p {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.friends-bonuses-header {
    align-items: center;
    color: #8b8e93;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 20px
}

.friends-bonuses-header span:first-child {
    flex: 0 0 40%;
    padding-left: 12px
}

.friends-bonuses-header span:nth-child(2),.friends-bonuses-header span:nth-child(3) {
    flex: 0 0 30%
}

.friends-bonuses-image {
    background-color: #1c1f24;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    height: 40px;
    position: relative;
    width: 40px
}

.friends-bonuses-image span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: 4px;
    text-align: right;
    text-shadow: 1px 1px rgba(28,31,36,.5);
    top: 50%;
    transform: translateY(-50%)
}

.friends-bonuses-list li {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 12px 0
}

.friends-bonuses-list li:last-child {
    margin-bottom: 0
}

.friends-bonuses-list li .price-value {
    font-size: 12px
}

.friends-bonuses-list li>div:first-child {
    align-items: center;
    display: flex;
    flex: 0 0 40%;
    padding-left: 12px
}

.friends-bonuses-list li>div:first-child p {
    font-size: 12px;
    margin-left: 6px
}

.friends-bonuses-list li>div:nth-child(2) {
    flex: 0 0 30%;
    height: 20px;
    line-height: 1
}

.friends-bonuses-list li>div:nth-child(3) {
    flex: 0 0 30%;
    height: 20px;
    line-height: 1;
    padding-right: 12px
}

.friends-progress {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0
}

.friends-item {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 8px 12px
}

.friends-item:last-child {
    margin-bottom: 0
}

.friends-item .price-value {
    font-size: 14px
}

.friends-item-stats {
    flex: 0 0
}

.friends-item-user {
    flex: 1 1 auto;
    padding-right: 12px
}

.friends-item-user .user-info p {
    font-size: 12px;
    text-overflow: ellipsis
}

.friends-wrap .section-title {
    position: relative
}

.friends-wrap .section-title .button {
    align-items: center;
    background-color: transparent;
    color: #fff;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -6px;
    width: 24px
}

.friends-wrap .section-title .button span {
    font-size: 7px;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%,-50%)
}

.friends-wrap .section-title .button svg {
    height: 100%;
    width: 100%
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: -12px;
    padding: 12px 16px
}

.header.is-jc {
    justify-content: center
}

.header-left {
    justify-content: flex-start
}

.header-left,.header-right {
    align-items: center;
    display: flex
}

.header-right {
    justify-content: flex-end
}

.header-settings {
    color: #8b8e93
}

.header-settings:hover {
    color: #fff
}

.ln {
    background-color: #13111c;
    font-family: Outfit,sans-serif;
    margin: 0 auto;
    max-width: 1920px;
    overflow: hidden;
    padding: 0 20px;
    z-index: 1
}

.ln,.ln-inner,.ln-top {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: -webkit-fill-available;
    min-height: 100vh;
    position: relative;
    width: 100%
}

.ln-loading {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    justify-content: center;
    width: 100%
}

.ln-loading img {
    height: 60px;
    width: 60px
}

.ln-loading p {
    font-size: 26px;
    font-weight: 500;
    margin-left: 16px
}

.ln-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

@media (min-width: 768px) {
    .ln-header {
        align-items:flex start;
        flex-wrap: nowrap
    }
}

.ln-header-left {
    order: 2;
    padding-top: 16px
}

@media (min-width: 768px) {
    .ln-header-left {
        order:1;
        padding-top: 32px
    }
}

.ln-header-center {
    align-items: center;
    border: 2px solid #2a2832;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top: none;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    order: 1;
    padding: 12px 20px
}

@media (min-width: 768px) {
    .ln-header-center {
        flex:0 0 auto
    }
}

@media (min-width: 1024px) {
    .ln-header-center {
        border-bottom:8px solid #2a2832;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        border-left: 8px solid #2a2832;
        border-right: 8px solid #2a2832;
        order: 2;
        padding: 32px 60px
    }
}

.ln-header-center-logo {
    height: 60px;
    position: relative;
    width: 60px
}

@media (min-width: 1024px) {
    .ln-header-center-logo {
        height:90px;
        width: 90px
    }
}

.ln-header-center-logo:before {
    background-color: #ffffff0d;
    border-radius: 50%;
    content: "";
    display: block;
    height: 66px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 66px;
    z-index: 2
}

@media (min-width: 1024px) {
    .ln-header-center-logo:before {
        height:98px;
        width: 98px
    }
}

.ln-header-center-logo:after {
    background-color: #ffffff0d;
    border-radius: 50%;
    content: "";
    display: block;
    height: 72px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 72px;
    z-index: 1
}

@media (min-width: 1024px) {
    .ln-header-center-logo:after {
        height:114px;
        width: 114px
    }
}

.ln-header-center-logo img {
    display: block;
    height: auto;
    max-width: 100%;
    z-index: 3
}

.ln-header-center p {
    font-size: 26px;
    font-weight: 500;
    margin-left: 16px
}

@media (min-width: 1024px) {
    .ln-header-center p {
        font-size:32px;
        margin-left: 30px
    }
}

.ln-header-right {
    order: 3;
    padding-top: 16px
}

@media (min-width: 768px) {
    .ln-header-right {
        order:3;
        padding-top: 32px
    }
}

.ln-header-right .button {
    align-items: center;
    background: linear-gradient(90deg,#2253ff 1.46%,#3a66ff00 50.8%,#2253ff 99.81%);
    border: 1px solid #2252fd;
    border-radius: 71px;
    color: #fff;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    padding: 0 10px;
    position: relative
}

@media (min-width: 1024px) {
    .ln-header-right .button {
        font-size:22px;
        height: 54px;
        padding: 0 20px
    }
}

.ln-header-right .button span {
    align-items: center;
    background: linear-gradient(93deg,#5a60ff,#bf36ff);
    border-radius: 8px;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 26px;
    justify-content: center;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -13px;
    width: 90px
}

.ln-header-right .button span:after {
    border: 2px solid hsla(0,0%,100%,.18);
    border-radius: 8px;
    content: "";
    display: block;
    height: 22px;
    left: 0;
    position: absolute;
    top: 0;
    width: 86px
}

.ln-header-right .button .icon {
    height: 28px;
    margin-left: 16px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    width: 28px
}

@media (min-width: 1024px) {
    .ln-header-right .button .icon {
        margin-left:32px
    }
}

.ln-header-right .button .icon svg {
    height: 100%;
    width: 100%
}

.ln-header-social {
    align-items: center;
    border: 1px solid hsla(0,0%,100%,.15);
    border-radius: 60px;
    display: flex;
    padding: 5px
}

@media (min-width: 1024px) {
    .ln-header-social {
        border-radius:100px;
        padding: 8px
    }
}

.ln-header-social p {
    color: #ffffff80;
    font-size: 16px;
    font-weight: 500;
    margin: 0 6px
}

@media (min-width: 1024px) {
    .ln-header-social p {
        font-size:22px;
        margin: 0 12px
    }
}

.ln-header-social a {
    align-items: center;
    background-color: #ffffff26;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    height: 28px;
    justify-content: center;
    margin-right: 4px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    width: 28px
}

@media (min-width: 1024px) {
    .ln-header-social a {
        height:52px;
        width: 52px
    }
}

.ln-header-social a:hover {
    background-color: #ffffff4d
}

.ln-header-social a.is-x .icon {
    height: 18px;
    width: 18px
}

@media (min-width: 1024px) {
    .ln-header-social a.is-x .icon {
        height:30px;
        width: 30px
    }
}

.ln-header-social a.is-telegram .icon {
    height: 16px;
    width: 16px
}

@media (min-width: 1024px) {
    .ln-header-social a.is-telegram .icon {
        height:32px;
        width: 32px
    }
}

.ln-header-social a .icon svg {
    height: 100%;
    width: 100%
}

.ln-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0 48px;
    text-align: center
}

.ln-content-hamster {
    display: none;
    position: relative;
    width: 100%;
    z-index: -1
}

@media (min-width: 1440px) {
    .ln-content-hamster {
        display:block
    }
}

.ln-content-hamster-image {
    height: 200px;
    position: absolute;
    width: 200px;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.ln-content-hamster-image img,.ln-content-hamster-image picture {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%
}

.ln-content-hamster-image:before {
    background: linear-gradient(180deg,#13111c00 .24%,#13111c0f 26.21%,#13111c 50.19%);
    bottom: 0;
    content: "";
    display: block;
    height: 75%;
    left: 0;
    position: absolute;
    width: 100%
}

.ln-content-hamster-image.is-1 {
    left: 20px
}

.ln-content-hamster-image.is-2 {
    left: 120px;
    top: 200px
}

.ln-content-hamster-image.is-3 {
    right: 20px
}

.ln-content-hamster-image.is-4 {
    right: 120px;
    top: 200px
}

.ln-content-exchanges {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 12px
}

@media (min-width: 768px) {
    .ln-content-exchanges {
        margin-bottom:20px
    }
}

.ln-content-exchange {
    align-items: center;
    background: #2a2832;
    border: 3px solid #13111c;
    border-radius: 50%;
    display: flex;
    height: 54px;
    justify-content: center;
    margin-left: -24px;
    padding: 10px;
    width: 54px
}

@media (min-width: 1024px) {
    .ln-content-exchange {
        border:5px solid #13111c;
        height: 64px;
        width: 64px
    }
}

.ln-content-exchange:first-child {
    margin-left: 0
}

.ln-content h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 12px
}

@media (min-width: 768px) {
    .ln-content h1 {
        font-size:60px;
        line-height: 64px;
        margin-bottom: 20px
    }
}

@media (min-width: 1024px) {
    .ln-content h1 {
        font-size:80px;
        font-weight: 700;
        line-height: 84px;
        margin-bottom: 20px
    }
}

.ln-content p {
    color: #fffc;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px
}

.ln-content p:last-child {
    margin-bottom: 0
}

.ln-content p.is-hidden-mobile {
    display: none
}

@media (min-width: 768px) {
    .ln-content p.is-hidden-mobile {
        display:block
    }
}

@media (min-width: 1024px) {
    .ln-content p {
        font-size:24px;
        line-height: 40px
    }
}

.ln-content-button {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    width: 100%
}

.ln-content .button {
    background: linear-gradient(90deg,#2253ff 1.46%,#1744e1 99.81%);
    border: 1px solid #fff;
    border-radius: 71px;
    box-shadow: 0 2px 72.7px #0f2368bd;
    color: #fff;
    display: inline-flex;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    height: 80px;
    margin-top: 36px;
    max-width: 471px;
    position: relative;
    z-index: 3
}

@media (min-width: 768px) {
    .ln-content .button {
        height:94px;
        margin-top: 72px
    }
}

.ln-content .button span {
    position: relativa;
    z-index: 3
}

.ln-content .button:hover:after {
    filter: blur(40px)
}

.ln-content .button:before {
    background: linear-gradient(90deg,#2253ff 1.46%,#3a66ff00 50.8%,#2253ff 99.81%);
    border: 2px solid #2252fd;
    border-radius: 71px;
    height: calc(100% + 32px);
    width: calc(100% + 32px);
    z-index: 2
}

.ln-content .button:after,.ln-content .button:before {
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

.ln-content .button:after {
    background: linear-gradient(90deg,#2253ff 1.46%,#3a66ff82 50.8%,#2253ff 99.81%);
    border-radius: 71px;
    filter: blur(20px);
    height: calc(100% + 50px);
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    width: calc(100% + 50px);
    z-index: 1
}

@media (min-width: 768px) {
    .ln-content .button:after {
        filter:blur(26px)
    }
}

.ln-bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media (min-width: 768px) {
    .ln-bottom {
        padding-bottom:32px
    }
}

.ln-bottom-inner {
    align-items: center;
    background: #2a2832;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 20px;
    width: 100%
}

@media (min-width: 768px) {
    .ln-bottom-inner {
        border:12px solid #13111c;
        border-radius: 171px;
        flex-direction: row;
        padding: 0 54px;
        width: auto
    }
}

.ln-bottom-inner>img {
    display: none;
    height: 90px;
    width: 90px
}

@media (min-width: 768px) {
    .ln-bottom-inner>img {
        display:block;
        height: 156px;
        width: 156px
    }
}

.ln-bottom-inner p {
    color: #ffa826;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin: 24px 0 12px;
    text-align: center
}

@media (min-width: 768px) {
    .ln-bottom-inner p {
        margin:24px
    }
}

@media (min-width: 1024px) {
    .ln-bottom-inner p {
        font-size:46px;
        line-height: 48px;
        margin: 0 56px
    }
}

.ln-bottom-players {
    align-items: center;
    display: flex;
    justify-content: center
}

.ln-bottom-players .user-avatar {
    background: #34383f;
    border: 2px solid #262335;
    border-radius: 50%;
    height: 48px;
    margin-left: -24px;
    width: 48px
}

.ln-bottom-players .user-avatar:first-child {
    margin-left: 0
}

.ln-roadmap {
    margin: 0 auto;
    max-width: 1514px;
    width: 100%
}

@media (min-width: 1024px) {
    .ln-roadmap {
        margin-bottom:60px
    }
}

.ln-roadmap h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    margin: 60px 0;
    text-align: center
}

@media (min-width: 768px) {
    .ln-roadmap h2 {
        font-size:80px;
        line-height: 84px;
        margin: 120px 0
    }
}

.ln-roadmap-item {
    background: linear-gradient(180deg,#ffffff1a,#ffffff0a);
    border-left: 6px solid transparent;
    border-radius: 20px;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 20px;
    position: relative
}

@media (min-width: 768px) {
    .ln-roadmap-item {
        align-items:center;
        border-left: 12px solid transparent;
        border-radius: 53px;
        display: flex;
        min-height: 160px;
        padding: 0 50px 0 78px
    }
}

.ln-roadmap-item.is-completed {
    border-color: #19de8b
}

.ln-roadmap-title {
    color: #ffffff80;
    flex: 1 1;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px
}

@media (min-width: 1024px) {
    .ln-roadmap-title {
        font-size:60px;
        line-height: 64px
    }
}

@media (min-width: 1440px) {
    .ln-roadmap-title {
        font-size:80px;
        line-height: 84px;
        margin-bottom: 0
    }
}

.ln-roadmap-content {
    flex: 1 1;
    font-size: 20px;
    line-height: 28px
}

@media (min-width: 1440px) {
    .ln-roadmap-content {
        font-size:24px;
        line-height: 32px
    }
}

.ln-roadmap-content u1l li {
    padding-left: 24px;
    position: relative
}

.ln-roadmap-content u1l li:before {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: 6px;
    left: 0;
    position: absolute;
    top: 12px;
    width: 6px
}

@media (min-width: 1440px) {
    .ln-roadmap-content u1l li:before {
        top:15px
    }
}

.ln-roadmap-hamster {
    display: none;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

@media (min-width: 1024px) {
    .ln-roadmap-hamster {
        display:block
    }
}

.ln-roadmap-hamster.is-1 {
    bottom: 0;
    right: -60px
}

.ln-roadmap-hamster.is-1 .ln-roadmap-hamster-bg {
    border-radius: 53px;
    overflow: hidden;
    right: 60px
}

.ln-roadmap-hamster.is-1 .ln-roadmap-hamster-bg img {
    position: absolute;
    right: -50px
}

.ln-roadmap-hamster.is-2 {
    bottom: 0;
    left: 20%
}

@media (min-width: 1440px) {
    .ln-roadmap-hamster.is-2 {
        left:25%
    }
}

.ln-roadmap-hamster.is-2 .ln-roadmap-hamster-bg {
    left: -50px
}

.ln-roadmap-hamster-bg {
    border-radius: 53px;
    bottom: 0;
    height: 160px;
    position: absolute;
    width: 550px;
    z-index: -1
}

.ln-roadmap-hamster-image {
    height: 280px;
    overflow: hidden;
    width: 420px
}

.ln-roadmap-hamster-image img {
    display: block;
    height: auto;
    width: 100%
}

.ln-footer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
    margin-top: 20px
}

@media (min-width: 1024px) {
    .ln-footer {
        margin-bottom:48px;
        margin-top: 0
    }
}

.ln-footer-social {
    align-items: center;
    border: 1px solid hsla(0,0%,100%,.15);
    border-radius: 100px;
    display: flex;
    padding: 8px
}

.ln-footer-social a {
    align-items: center;
    background-color: #ffffff26;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    height: 52px;
    justify-content: center;
    margin: 0 4px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    width: 52px
}

.ln-footer-social a:hover {
    background-color: #ffffff4d
}

.ln-footer-social a.is-discord .icon,.ln-footer-social a.is-medium .icon {
    height: 36px;
    width: 36px
}

.ln-footer-social a.is-telegram .icon {
    height: 32px;
    width: 32px
}

.league-slider.swiper {
    overflow: visible
}



.league-slider.swiper.is-Bronze:before {
    background: linear-gradient(133deg,#ffae50 5.47%,#994330 97.66%)
}

.league-slider.swiper.is-Silver:before {
    background: linear-gradient(133deg,#7995be 5.47%,#8c96a6 97.66%)
}

.league-slider.swiper.is-Gold:before {
    background: linear-gradient(133deg,#ffac2f 5.47%,#ffc34f 97.66%)
}

.league-slider.swiper.is-Platinum:before {
    background: linear-gradient(133deg,#79aabe 5.47%,#8f8ca6 97.66%)
}

.league-slider.swiper.is-Diamond:before {
    background: linear-gradient(126deg,#2ef2ff 30.31%,#0d16ff 92.09%)
}

.league-slider.swiper.is-Epic:before {
    background: linear-gradient(133deg,#5768ff 18.37%,#ff67f9 88.58%)
}

.league-slider.swiper.is-Legendary:before {
    background: linear-gradient(133deg,#73eeff 18.37%,#00c092 88.58%)
}

.league-slider.swiper.is-Master:before {
    background: linear-gradient(139deg,#0f44ff 26.58%,#29ffd9 75.09%)
}

.league-slider.swiper.is-Grandmaster:before {
    background: linear-gradient(131deg,#6889ff 12.82%,#0c15ff 95.77%)
}

.league-slider.swiper.is-Lord:before {
    background: linear-gradient(131deg,#fff 12.82%,#ffbb7c 53%,#b75800 95.77%)
}

.league-slide {
    opacity: 0!important
}

.league-stats {
    align-items: center;
    background: #272a2f;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%
}

.league-stats:hover .league-stats-action {
    color: #fff
}

.league-stats-users {
    align-items: center;
    display: flex
}

.league-stats-users .user-avatar {
    border: 1px solid #020202;
    margin-right: -14px
}

.league-stats-users .user-avatar:last-child {
    margin-right: 0
}

.league-stats-info {
    flex: 1 1 auto;
    font-size: 14px;
    padding-left: 12px
}

.league-stats-action {
    align-items: center;
    color: #8b8e93;
    display: flex;
    font-size: 14px;
    justify-content: center
}

.league-stats-action p {
    margin-right: 6px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.league-item {
    position: relative
}

.league-item,.league-item-info {
    align-items: center;
    display: flex;
    justify-content: center
}

.league-item-info {
    background: linear-gradient(93deg,#5a60ff,#bf36ff);
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    left: 50%;
    padding: 0 10px;
    position: absolute;
    top: -46px;
    transform: translate(-50%);
    white-space: nowrap;
    z-index: 10
}

.league-item-info .icon {
    margin-left: 6px
}

.league-item-info:hover {
    background: linear-gradient(93deg,#bf36ff,#5a60ff)
}

.league-item-info:before {
    border: 2px solid hsla(0,0%,100%,.18);
    border-radius: 10px;
    content: "";
    display: block;
    height: 100%;
    left: -2px;
    position: absolute;
    top: -2px;
    width: 100%;
    z-index: 2
}

.league-item-image {
    align-items: center;
    background-color: #ffffff0d;
    border-radius: 28px;
    display: flex;
    height: 162px;
    justify-content: center;
    margin: 6px 0 22px;
    position: relative;
    width: 162px
}

.league-item-image:before {
    background-color: #ffffff0d;
    border-radius: 32px;
    content: "";
    display: block;
    height: 174px;
    left: -6px;
    position: absolute;
    top: -6px;
    width: 174px;
    z-index: -1
}

.league-data {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 10px
}

.league-data-name {
    font-size: 32px;
    font-weight: 700
}

.league-data-level {
    align-items: center;
    display: flex;
    font-size: 16px;
    justify-content: flex-end;
    margin-left: 10px;
    position: relative;
    top: -2px
}

.league-data-level p {
    font-weight: 700
}

.league-data-level span {
    color: #8b8e93
}

.league-numbers {
    color: #fff9;
    font-size: 14px;
    font-weight: 700;
    text-align: center
}

.league-progress {
    background: #272a2f;
    border: 2px solid #2c3032;
    border-radius: 10px;
    display: block;
    width: 100%
}

.league-progress-outer {
    height: 16px;
    margin-bottom: 20px;
    margin-top: 12px
}

.league-progress-bar {
    background: linear-gradient(90deg,#82f88e,#d885ff 53%,#5960ff);
    border-radius: 37px;
    height: 12px
}

.league-users.is-Bronze .league-user .user-avatar {
    background: linear-gradient(133deg,#ffae50 5.47%,#994330 97.66%)
}

.league-users.is-Silver .league-user .user-avatar {
    background: linear-gradient(133deg,#7995be 5.47%,#8c96a6 97.66%)
}

.league-users.is-Gold .league-user .user-avatar {
    background: linear-gradient(133deg,#ffac2f 5.47%,#ffc34f 97.66%)
}

.league-users.is-Platinum .league-user .user-avatar {
    background: linear-gradient(133deg,#79aabe 5.47%,#8f8ca6 97.66%)
}

.league-users.is-Diamond .league-user .user-avatar {
    background: linear-gradient(126deg,#2ef2ff 30.31%,#0d16ff 92.09%)
}

.league-users.is-Epic .league-user .user-avatar {
    background: linear-gradient(133deg,#5768ff 18.37%,#ff67f9 88.58%)
}

.league-users.is-Legendary .league-user .user-avatar {
    background: linear-gradient(133deg,#73eeff 18.37%,#00c092 88.58%)
}

.league-users.is-Master .league-user .user-avatar {
    background: linear-gradient(139deg,#0f44ff 26.58%,#29ffd9 75.09%)
}

.league-users.is-Grandmaster .league-user .user-avatar {
    background: linear-gradient(131deg,#6889ff 12.82%,#0c15ff 95.77%)
}

.league-users.is-Lord .league-user .user-avatar {
    background: linear-gradient(131deg,#fff 12.82%,#ffbb7c 53%,#b75800 95.77%)
}

.league-users-progress {
    align-items: center;
    display: flex;
    justify-content: center
}

.league-users>p {
    font-size: 18px;
    font-weight: 700;
    text-align: center
}

.league-user {
    align-items: center;
    background: #272a2f;
    border-radius: 20px;
    display: flex;
    height: 64px;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 0 12px;
    position: relative
}

.league-user.current-user {
    background: #97740b29;
  }

.league-user.is-my {
    bottom: 70px;
    position: sticky
}

.league-user.is-my:before {
    border: 1px solid #8b8e93;
    border-radius: 20px;
    content: "";
    height: calc(100% - 2px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 2px)
}

.league-user:last-child {
    margin-bottom: 0
}

.league-user-avatar {
    border-radius: 12px;
    height: 50px;
    min-width: 50px;
    overflow: hidden;
    width: 50px
}

.league-user-position {
    color: #8b8e93;
    flex: 0 0 48px;
    font-size: 26px;
    padding-left: 12px;
    text-align: right
}

.league-user-info {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px
}

.league-user-info-top {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px
}

.league-user-info-top .exchange-image {
    align-items: center;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 6px;
    min-width: 18px;
    width: 18px
}

.league-user-info p {
    font-size: 14px;
    margin-top: 2px;
    max-height: 28px;
    overflow: hidden
}

.league-user-info .price-value {
    color: #8b8e93;
    font-size: 14px;
    font-weight: 700
}

.league .swiper-button-next,.league .swiper-button-prev {
    align-items: center;
    color: #8b8e93;
    cursor: pointer;
    display: flex;
    height: 174px;
    justify-content: center;
    position: absolute;
    top: 22px;
    width: 100px;
    z-index: 10
}

.league .swiper-button-next.swiper-button-disabled,.league .swiper-button-prev.swiper-button-disabled {
    cursor: auto;
    opacity: .35;
    pointer-events: none
}

.league .swiper-button-next.swiper-button-hidden,.league .swiper-button-prev.swiper-button-hidden {
    cursor: auto;
    opacity: 0;
    pointer-events: none
}

.league .swiper-navigation-disabled .swiper-button-next,.league .swiper-navigation-disabled .swiper-button-prev {
    display: none!important
}

.league .swiper-button-next svg,.league .swiper-button-prev svg {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    transform-origin: center;
    width: 100%
}

.league .swiper-rtl .swiper-button-next svg,.league .swiper-rtl .swiper-button-prev svg {
    transform: rotate(180deg)
}

.league .swiper-button-prev,.league .swiper-rtl .swiper-button-next {
    left: -16px;
    right: auto
}

.league .swiper-button-next,.league .swiper-rtl .swiper-button-prev {
    left: auto;
    right: -16px
}

.league .swiper-button-lock {
    display: none
}

.league .swiper-button-next:after,.league .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 24px;
    font-variant: normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none!important
}

.league .swiper-button-prev:after,.league .swiper-rtl .swiper-button-next:after {
    content: "prev"
}

.league .swiper-button-next:after,.league .swiper-rtl .swiper-button-prev:after {
    content: "next"
}

.swiper-slide-fully-visible .league-item:before,.swiper-slide-fully-visible.league-slide {
    opacity: 1!important
}

.settings-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 12px;
    text-align: center
}

.settings-item {
    align-items: center;
    background: #272a2f;
    border: 1px solid #272a2f;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 10px 12px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    width: 100%
}

.settings-item:hover:not(.is-disabled):not(.is-active) {
    border-color: #8b8e93
}

.settings-item:hover:not(.is-disabled):not(.is-active) .settings-item-action {
    color: #fff
}

.settings-item.is-active {
    border-color: #4e4f50;
    cursor: default
}

.settings-item.is-disabled {
    cursor: not-allowed;
    opacity: .5
}

.settings-item:last-child {
    margin-bottom: 0
}

.settings-item-image {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 12px;
    width: 40px
}

.settings-item-image img {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto
}

.settings-item-content {
    flex: 1 1 auto;
    text-align: left
}

.settings-item-content p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px
}

.settings-item-content p:last-child {
    margin-bottom: 0
}

.settings-item-content span {
    color: #8b8e93;
    display: block;
    font-size: 14px;
    line-height: 1
}

.settings-item-action {
    color: #8b8e93
}

.settings-toggle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding: 0 16px
}

.settings-toggle p {
    font-size: 16px;
    font-weight: 700
}

.slider-onb.swiper {
    background-color: #000;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10
}

.slider-onb .swiper-pagination {
    align-items: center;
    bottom: 72px;
    display: flex;
    height: 50px;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 10
}

.slider-onb .swiper-pagination .swiper-pagination-bullet {
    background: #4e4f50;
    border-radius: 50%;
    height: 10px;
    margin: 0 4px;
    width: 10px
}

.slider-onb .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #5a60ff
}

.slider-onb-button {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 20px;
    position: absolute;
    right: 0;
    z-index: 10
}

.slider-onb-button .button {
    max-width: 414px
}

.slider-onb-item {
    background-color: #000;
    display: flex;
    height: 100%;
    padding: 20px 16px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.slider-onb-item h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0 auto 20px;
    max-width: 280px
}

.slider-onb-item p {
    font-size: 14px;
    line-height: 18px
}

.slider-onb-next {
    align-items: center;
    background: #5a60ff;
    border: 2px solid #1c1f24;
    border-radius: 16px;
    bottom: 72px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    position: absolute;
    right: 20px;
    width: 50px;
    z-index: 10
}

.slider-onb-next .icon {
    color: #fff
}

.slider-onb-next .icon svg {
    height: 32px;
    width: 32px
}

.slider-onb-image {
    bottom: 120px;
    height: 400px;
    left: 50%;
    margin-left: -138px;
    position: absolute;
    width: 276px;
    z-index: -1
}

.slider-onb-image>div {
    border: 6px solid hsla(0,0%,100%,.1);
    border-radius: 44px 44px 0 0;
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 5
}

.slider-onb-image>div img {
    display: block;
    height: auto;
    width: 100%
}

.slider-onb-image:before {
    background-color: #3763ff;
    content: "";
    display: block;
    filter: blur(30px);
    height: calc(100% - 80px);
    position: absolute;
    width: 100%;
    z-index: 1
}

.slider-onb-image:after {
    background: #000;
    background: linear-gradient(180deg,transparent,#000 90%);
    bottom: -6px;
    content: "";
    display: block;
    height: 80px;
    left: -6px;
    position: absolute;
    right: -6px;
    width: calc(100% + 12px);
    z-index: 5
}

.upgrade-list {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -6px
}

.upgrade-list .section-title {
    flex: 0 0 100%;
    margin-top: 16px;
    padding-left: 6px
}

.upgrade-list .section-title:first-child {
    margin-top: 0
}

.upgrade-item {
    align-items: stretch;
    background: #272a2f;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex: 0 0 calc(50% - 12px);
    flex-direction: column;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-left: 6px;
    margin-right: 6px;
    max-width: calc(50% - 12px);
    -webkit-tap-highlight-color: transparent;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.upgrade-item.is-special {
    box-shadow: 0 0 6px #fab822cc
}

.upgrade-item.is-disabled {
    background: #272a2f80;
    cursor: not-allowed
}

.upgrade-item.is-disabled.is-special {
    box-shadow: 0 0 6px #fab82233
}

.upgrade-item-top {
    display: flex;
    margin: 0 12px;
    padding: 8px 0
}

.upgrade-item-image {
    align-items: center;
    display: flex;
    flex: 0 0 60px;
    height: 60px;
    justify-content: center;
    margin-left: -4px;
    margin-right: 8px;
    position: relative;
    width: 60px
}

.upgrade-item-image img {
    display: block;
    height: auto;
    max-width: 100%;
    position: relative;
    z-index: 1
}

.upgrade-item-image.is-disabled:before {
    background-color: #34383fcc;
    border-radius: 50%;
    content: "";
    display: block;
    height: 68px;
    left: -4px;
    overflow: hidden;
    position: absolute;
    top: -4px;
    width: 68px;
    z-index: 2
}

.upgrade-item-image.is-disabled>.icon {
    color: #fff;
    height: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 24px;
    z-index: 3
}

.upgrade-item-image.is-disabled>.icon svg {
    height: 24px;
    width: 24px;
    fill: #fff
}

.upgrade-item-info {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2px
}

.upgrade-item-title {
    flex: 1 1 auto;
    font-weight: 400;
    max-height: 36px;
    text-overflow: ellipsis
}

.upgrade-item-title.is-long {
    font-size: 11px
}

.upgrade-item-profit {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 4px
}

.upgrade-item-profit>p {
    font-size: 10px
}

.upgrade-item-spec {
    display: flex;
    flex-wrap: wrap;
    max-width: 150px
}

.upgrade-item-spec p {
    flex: 0 0 50%;
    font-weight: 700;
    margin-top: 6px
}

.upgrade-item-bottom {
    align-items: center;
    border-top: 1px solid #34383f;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px
}

.upgrade-item-divider {
    background-color: #34383f;
    flex: 0 0 1px;
    height: 24px;
    margin-right: 12px;
    width: 1px
}

.upgrade-item-level {
    font-weight: 700;
    min-width: 40px;
    padding-right: 6px;
    white-space: nowrap
}

.upgrade-item-level .icon {
    height: 16px;
    width: 16px
}

.upgrade-item-level .icon svg {
    height: 100%;
    width: 100%
}

.upgrade-item-detail {
    flex: 1 1 auto
}

.upgrade-item-detail p span {
    font-weight: 600
}

.upgrade-buy {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center
}

.upgrade-buy-image {
    align-items: center;
    display: flex;
    height: 115px;
    justify-content: center;
    margin-bottom: 16px;
    position: relative;
    width: 100%
}

.upgrade-buy-image.lambo_for_ceo:before {
    background-color: #319f43e6;
    border-radius: 50%;
    content: "";
    display: block;
    filter: blur(20px);
    height: 100px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    z-index: -1
}

.upgrade-buy-image.venom_blockchain {
    height: 160px
}

.upgrade-buy-image img {
    display: block;
    height: auto;
    max-width: 100%
}

.upgrade-buy-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 12px
}

.upgrade-buy-title.venom_blockchain {
    font-size: 32px
}

.upgrade-buy-description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
    max-width: 280px
}

.upgrade-buy-stats {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 20px;
    width: 100%
}

.upgrade-buy-stats li {
    flex: 1 1
}

.upgrade-buy-stats li>p {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px
}

.upgrade-buy-stats-info {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    height: 20px
}

.upgrade-buy-info,.upgrade-buy-stats-info {
    align-items: center;
    display: flex;
    justify-content: center
}

.upgrade-buy-info {
    color: #8b8e93;
    font-size: 24px;
    font-style: normal;
    font-weight: 400
}

.upgrade-image {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.upgrade-image.is-blur {
    filter: blur(4px)
}

.upgrade-image .icon {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.upgrade-image img,.upgrade-image svg {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%
}

.upgrade-image picture {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.upgrade-image img.is-rounded,.upgrade-image picture.is-rounded {
    border-radius: 12px;
    overflow: hidden
}

.upgrade-block {
    align-items: center;
    background-color: #1c1f24e6;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 0;
    padding-top: 48px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 20
}

.upgrade-block-title {
    font-size: 18px;
    font-weight: 700
}

.upgrade-block .button {
    margin-top: 24px
}

.upgrade-block-icon {
    margin-bottom: 24px
}

.upgrade-block-icon svg {
    height: 40px;
    width: 40px
}

.upgrade-special {
    border-radius: 20px;
    cursor: pointer;
    flex: 0 0 calc(50% - 12px);
    font-size: 12px;
    margin-bottom: 12px;
    margin-left: 6px;
    margin-right: 6px;
    max-width: calc(50% - 12px);
    -webkit-tap-highlight-color: transparent;
    min-height: 224px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.upgrade-special.is-disabled,.upgrade-special.is-expired {
    cursor: not-allowed
}

.upgrade-special.is-disabled .upgrade-special-inner:before,.upgrade-special.is-expired .upgrade-special-inner:before {
    opacity: .3
}

.upgrade-special .is-upgrade-lock {
    height: 60px;
    left: 50%;
    margin-left: -30px;
    position: absolute;
    top: 52px;
    width: 60px;
    z-index: 10
}

.upgrade-special .is-upgrade-lock svg {
    fill: #fff;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5))
}

.upgrade-special-inner {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    width: 100%
}

.upgrade-special-inner>* {
    position: relative;
    z-index: 2
}

.upgrade-special-inner:before {
    border-radius: 20px;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1
}

.upgrade-special-inner.taker_carlson_interview:before {
    background: linear-gradient(180deg,#f7ae41,#262526)
}

.upgrade-special-inner.token2049:before {
    background: linear-gradient(180deg,#dd4cb1,#662ae6 50.5%,#262526)
}

.upgrade-special-inner.save_hamsters_from_drowning:before {
    background: linear-gradient(180deg,#5a60ff,#202332)
}

.upgrade-special-inner.blockchain_life_2024:before {
    background: linear-gradient(180deg,#0086ff,#202732)
}

.upgrade-special-inner.usdt_on_ton:before {
    background: linear-gradient(180deg,#08c,#013048)
}

.upgrade-special-inner.villa_for_dev_team:before {
    background: linear-gradient(180deg,#38b7df 34.08%,#262526)
}

.upgrade-special-inner.success_with_tucker:before {
    background: linear-gradient(180deg,#ac9787,#3f342a)
}

.upgrade-special-inner.short_squeeze:before {
    background: linear-gradient(180deg,#34d461,#262526)
}

.upgrade-special-inner.long_squeeze:before {
    background: linear-gradient(180deg,#dd4c4c,#262526)
}

.upgrade-special-inner.two_chairs:before {
    background: linear-gradient(180deg,#ff7b7b,#262526)
}

.upgrade-special-inner.bogdanoff:before {
    background: linear-gradient(180deg,#8256ff,#291a3c)
}

.upgrade-special-inner.forbes:before {
    background: linear-gradient(180deg,#adebff,#262526)
}

.upgrade-special-inner.apps_center_listing:before,.upgrade-special-inner.special_hamster_conference:before {
    background: linear-gradient(180deg,#ffa13f,#393107)
}

.upgrade-special-inner.bitcoin_halving:before {
    background: linear-gradient(180deg,#8799ac,#2a383f)
}

.upgrade-special-inner.lambo_for_ceo:before {
    background: linear-gradient(180deg,red,#763a22 63.26%,#262526)
}

.upgrade-special-inner.contract_with_football_club:before {
    background: linear-gradient(180deg,#fff,#212121)
}

.upgrade-special-inner.dubai_office:before {
    background: linear-gradient(180deg,#ff3f9b,#390707)
}

.upgrade-special-inner.joe_rogan_podcast:before {
    background: linear-gradient(180deg,#56ffff,#291a3c)
}

.upgrade-special-inner.venom_blockchain:before {
    background: linear-gradient(180deg,#42e0c1,#3188a7 56%,#021725)
}

.upgrade-special-inner.notcoin_listing:before {
    background: linear-gradient(180deg,#0ccb80 50%,#34383f)
}

.upgrade-special-inner.hamster_ai:before {
    background: linear-gradient(180deg,#a056ff,#1a223c)
}

.upgrade-special-inner.nft_collection_launch:before {
    background: linear-gradient(180deg,#08c,#013048)
}

.upgrade-special-inner.top10_global:before {
    background: linear-gradient(180deg,#1c6bc7,#061638)
}

.upgrade-special-inner.bitcoin_pizza_day:before {
    background: linear-gradient(180deg,#ffa73f,#391907)
}

.upgrade-special-inner.ceo_21m:before {
    background: linear-gradient(180deg,#ff79e2,#1f1f1f)
}

.upgrade-special-inner.hamster_daily_show:before {
    background: linear-gradient(180deg,#38b7df 34.08%,#262526)
}

.upgrade-special-inner.hamster_youtube_channel:before {
    background: linear-gradient(180deg,#000,#272a2f 63.26%,#262526)
}

.upgrade-special-inner.hamster_youtube_gold_button:before {
    background: linear-gradient(180deg,#ff4f4f,#262526)
}

.upgrade-special-inner.consensys_piranja_pass:before {
    background: linear-gradient(180deg,#2fd0cd,#091633)
}

.upgrade-special-inner.web3_academy_launch:before {
    background: linear-gradient(180deg,#d26829,#431507)
}

.upgrade-special-inner.football_club_winner:before {
    background: linear-gradient(180deg,#794aff,#1b0834)
}

.upgrade-special-inner.partner_announce:before {
    background: linear-gradient(180deg,#0da7f4,#013048)
}

.upgrade-special-inner.hamster_drop:before {
    background: linear-gradient(180deg,#ffbb37,#341300)
}

.upgrade-special-inner.premarket_launch:before {
    background: linear-gradient(180deg,#34d461,#262526)
}

.upgrade-special-top {
    height: 24px;
    margin: 12px 12px 0
}

.upgrade-special-top-timer {
    align-items: center;
    background: #1111121a;
    border-radius: 8px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.upgrade-special-top-timer .icon {
    color: #ffd337;
    height: 16px;
    width: 16px
}

.upgrade-special-top-timer .icon svg {
    height: 100%;
    width: 100%
}

.upgrade-special-top-timer p {
    font-size: 13px;
    margin-left: 4px
}

.upgrade-special-image {
    height: 76px;
    margin: 6px auto;
    width: 100%
}

.upgrade-special-image.blockchain_life_2024 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center
}

.upgrade-special-image.blockchain_life_2024 .upgrade-image {
    height: 55px;
    width: 55px
}

.upgrade-special-image.blockchain_life_2024 p {
    font-size: 10px;
    margin-top: 2px
}

.upgrade-special-title {
    font-size: 11px;
    font-weight: 700;
    padding: 0 12px;
    text-align: center
}

.upgrade-special-text {
    color: #fff6;
    font-size: 10px;
    line-height: 14px;
    margin-top: 4px;
    text-align: center
}

.upgrade-special-bottom {
    align-items: center;
    border-top: 1px solid hsla(0,0%,100%,.1);
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin: 12px 16px 0;
    padding: 12px 0
}

.upgrade-special-divider {
    background-color: #ffffff1a;
    flex: 0 0 1px;
    height: 24px;
    margin-right: 12px;
    width: 1px
}

.upgrade-special-level {
    font-size: 12px;
    font-weight: 700;
    min-width: 40px;
    padding-right: 6px;
    white-space: nowrap
}

.upgrade-special-detail {
    flex: 1 1 auto
}

.upgrade-special-detail.is-center {
    align-items: center;
    display: flex;
    justify-content: center
}

.upgrade-special-detail p.is-text-center {
    text-align: center
}

.upgrade-special-detail p span {
    font-weight: 600
}

.upgrade-special-spacer {
    flex: 1 1 auto
}

.upgrade-special-profit {
    align-items: center;
    display: flex;
    height: 14px;
    justify-content: center;
    margin-top: 6px
}

.upgrade-special-profit-text {
    color: #fff6;
    font-size: 9px;
    text-align: right
}

.upgrade-special-profit-price {
    padding-left: 6px
}

.upgrade-no-special {
    align-items: center;
    background-color: #272a2f;
    border-radius: 20px;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 224px;
    justify-content: center;
    line-height: 20px;
    margin-bottom: 12px;
    margin-left: 6px;
    margin-right: 6px;
    padding: 32px;
    text-align: center;
    width: 100%
}

.upgrade-no-special.is-my {
    background: linear-gradient(180deg,#ff5f1b,#faa019 65%,#121212)
}

.upgrade-no-special.is-new {
    background: linear-gradient(180deg,#2eb7ed,#2a0544 73.5%,#121212)
}

.upgrade-no-special.is-missed {
    background: linear-gradient(180deg,#ed2e2e,#440505 73.5%,#121212)
}

.upgrade-save-hamsters {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 320px
}

.upgrade-save-hamsters img {
    height: 36px;
    margin-top: 12px;
    width: 36px
}

.upgrade-save-hamsters p {
    font-size: 14px;
    margin-top: 12px
}

.upgrade-save-hamsters p.is-bold {
    font-weight: 700
}

.upgrade-progress {
    height: 72px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 72px;
    z-index: 10
}

.upgrade-progress.is-special {
    height: 82px;
    width: 82px
}

.upgrade-progress.is-special span {
    font-size: 11px
}

.upgrade-progress:before {
    background-color: #34383fcc;
    border-radius: 50%;
    content: "";
    display: block;
    height: calc(100% - 8px);
    left: 4px;
    position: absolute;
    top: 4px;
    width: calc(100% - 8px);
    z-index: -1
}

.upgrade-progress-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    z-index: 11
}

.upgrade-progress-info span {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 2px
}

.user-info {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    justify-content: center
}

.user-info-avatar {
    background-color: #1c1f24;
    border-radius: 12px;
    flex: 0 0 40px;
    height: 40px;
    margin-right: 12px;
    overflow: hidden;
    width: 40px
}

.user-info-avatar img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.user-info p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700
}

.user-info-content-data {
    align-items: center;
    display: flex;
    font-size: 10px;
    justify-content: flex-start;
    margin-top: 4px
}

.user-info-content-data .is-circle {
    background-color: #8b8e93;
    border-radius: 50%;
    height: 4px;
    margin: 0 6px;
    width: 4px
}

.user-info-content-data .price-value {
    font-size: 10px
}

.user-info-content-data-image {
    align-items: center;
    background-color: #1c1f24;
    border-radius: 4px;
    display: flex;
    height: 20px;
    justify-content: center;
    margin-right: 4px;
    width: 20px
}

.user-info-content-data-image img {
    display: block;
    height: auto;
    max-width: 100%
}

.user-balance {
    align-items: center;
    background-color: #272a2f;
    border-radius: 20px;
    display: inline-flex;
    padding: 8px;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.user-balance-image {
    margin-right: 6px
}

.user-balance p {
    font-size: 14px;
    font-weight: 700
}

.user-balance-large {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.user-balance-large-inner {
    align-items: center;
    display: inline-flex;
    justify-content: center
}

.user-balance-large-image {
    margin-right: 12px
}

.user-balance-large p {
    font-size: 40px;
    font-weight: 700
}

.user-balance-large p.is-36 {
    font-size: 36px;
    letter-spacing: -.9px
}

.user-balance-large p.is-32 {
    font-size: 32px;
    letter-spacing: -.9px
}

.user-stats {
    list-style: none;
    position: relative
}

.user-stats li {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px
}

.user-stats-item {
    align-items: center;
    background-color: #19102357;
    border-radius: -2px;
    display: flex;
    flex: 4 1;
    flex-direction: column;
    justify-content: center;
    margin-right: 0px;
    min-height: 54px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
}

.user-stats-item:last-child {
    margin-right: 0
}

.user-stats-item p {
    font-size: 12px;
    line-height: 8px;
    margin-bottom: 4px;
    max-height: 14px;
    text-align: center
}

.user-stats-item .price {
    font-size: 14px
}

.user-stats-item>span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px
}

.user-stats-profit {
    align-items: center;
    display: flex;
    justify-content: center
}

.user-stats-profit p {
    margin-bottom: 0;
    margin-right: 6px
}

.user-stats-profit .price {
    font-size: 14px;
    margin-right: 6px
}

.user-stats-profit .icon-info {
    color: #4e4f50;
    cursor: pointer
}

.user-stats-profit .icon-info:hover {
    color: #8b8e93
}

.user-level {
    color: #fff;
    display: block;
    margin-top: 15%;
    width: 100%
}

.user-level-info {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding: 0 2px
}

.user-level-info-left {
    align-items: center;
    display: flex;
    justify-content: flex-start
}

.user-level-info-right {
    align-items: center;
    display: flex;
    justify-content: flex-end
}

.user-level-info p {
    font-weight: 700
}

.user-level-info span {
    color: #8b8e93
}

.user-level-progress {
    background: #272a2f;
    border: 2px solid #2c3032;
    border-radius: 10px;
    display: block;
    height: 16px;
    margin-top: 6px;
    width: 100%
}

.user-level-progress-bar {
    background: linear-gradient(90deg, #fde029, #ee9404 53%, #fd8605);
    border-radius: 37px;
    height: 12px
}

.user-tap {
    margin-top: 20px;
    position: relative;
    touch-action: none!important;
    -webkit-touch-callout: none
}



.user-tap-button:active:not(:disabled):not([disabled=disabled]):not([disabled=true]) .user-tap-button-circle {
    border: 1px solid rgba(90,96,255,.15)
}

.user-tap-button:active:not(:disabled):not([disabled=disabled]):not([disabled=true]) .user-tap-button-circle:before {
    opacity: .8
}

.user-tap-button:disabled .user-tap-button-circle:before,.user-tap-button[disabled=disabled] .user-tap-button-circle:before,.user-tap-button[disabled=true] .user-tap-button-circle:before {
    opacity: .2
}

.user-tap-button img,.user-tap-button picture {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    position: relative;
    width: auto;
    z-index: 2
}









.user-tap-button.is-morse-mode .user-tap-button-inner {
    background: linear-gradient(180deg,#ff3737,#3b40934f 49.53%,#1c1f24)
}

.user-tap-button.is-morse-mode .user-tap-button-circle:before {
    background-color: #ff3737;
    background: radial-gradient(circle,#ff3737 0,#ff373700 80%)
}

.user-tap-animate {
    position: relative;
    z-index: 50
}

.user-tap-animate>div {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    line-height: 1;
    pointer-events: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100px;
    z-index: 60
}

.user-tap-row {
    justify-content: space-between;
    margin-bottom: 8px
}

.user-tap-energy,.user-tap-row {
    align-items: center;
    display: flex
}

.user-tap-energy {
    justify-content: flex-start
}

.user-tap-energy svg {
    height: 26px;
    margin-right: 6px;
    width: 26px
}

.user-tap-energy svg.nuxt-icon--fill * {
    fill: inherit
}

.user-tap-energy p {
    font-size: 16px;
    font-weight: 700
}

.user-tap-boost {
    align-items: center;
    background: #1c1f24;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    justify-content: center;
    padding-left: 56px;
    padding-right: 11px;
    position: relative;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.user-tap-boost:hover {
    background-color: #191c20
}

.user-tap-boost img {
    height: 48px;
    left: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px
}

.user-earn {
    background: #272a2f;
    border-radius: 32px;
    padding: 16px;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.user-earn.has-gap {
    margin-top: 24px
}

.user-earn.has-large-gap {
    margin-top: 32px
}

.user-earn-button {
    align-items: center;
    background: #5a60ff;
    border: 2px solid #9397ff;
    border-radius: 24px;
    box-shadow: 0 0 60px #5282ffbf,0 0 20px #5282ff69;
    display: flex;
    flex-direction: column;
    font-family: inherit;
    height: 180px;
    justify-content: flex-start;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.user-earn-button.is-referral {
    animation: none;
    justify-content: center
}

.user-earn-button.is-referral .price {
    color: #ffd337;
    font-size: 22px;
    margin-top: 18px
}

.user-earn-button img,.user-earn-button picture {
    height: 96px;
    pointer-events: none;
    width: 96px
}

.user-earn-button>p {
    color: #fff;
    font-size: 20px;
    font-weight: 700
}

.user-earn-button:hover:not(:disabled):not([disabled=disabled]):not([disabled=true]) {
    border-color: #7f84ff
}

.user-earn-button:active:not(:disabled):not([disabled=disabled]):not([disabled=true]) {
    background-color: #db8600
}

.user-earn-button:disabled,.user-earn-button[disabled=disabled],.user-earn-button[disabled=true] {
    background-color: #eac80699
}

.user-earn-animate {
    position: relative
}

.user-earn-animate>div {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    line-height: 1;
    pointer-events: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100px
}

.user-earn-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 24px
}

.user-earn-energy {
    align-items: center;
    display: flex;
    justify-content: flex-start
}

.user-earn-energy svg {
    height: 26px;
    margin-right: 6px;
    width: 26px
}

.user-earn-energy svg.nuxt-icon--fill * {
    fill: inherit
}

.user-earn-energy p {
    font-size: 16px;
    font-weight: 700
}

.user-earn-boost {
    align-items: center;
    background: #1c1f24;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    justify-content: center;
    padding-left: 56px;
    padding-right: 11px;
    position: relative;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.user-earn-boost:hover {
    background-color: #191c20
}

.user-earn-boost img {
    height: 48px;
    left: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px
}

.user-boost-button {
    align-items: center;
    background: #5a60ff;
    border: 2px solid #9397ff;
    border-radius: 24px;
    box-shadow: 0 0 60px #5282ffbf,0 0 20px #5282ff69;
    display: flex;
    font-family: inherit;
    height: 80px;
    justify-content: center;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.user-boost-button:last-child {
    margin-bottom: 0
}

.user-boost-button img {
    max-width: 56px
}

.user-boost-button p {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-left: 16px
}

.user-boost-button:hover {
    border-color: #7f84ff
}

.user-boost-button:active {
    background-color: #db8600
}

.user-avatar {
    background-color: #1c1f24;
    overflow: hidden
}

.user-avatar.is-28 {
    border-radius: 8px;
    height: 28px;
    width: 28px
}

.user-avatar.is-40 {
    border-radius: 12px;
    height: 40px;
    width: 40px
}

.user-avatar.is-50 {
    border-radius: 12px;
    height: 50px;
    width: 50px
}

.user-avatar img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.user-exchange {
    background: #272a2f;
    border-radius: 16px;
    height: 40px;
    padding: 6px 10px
}

.user-exchange .exchange-image {
    align-items: center;
    display: flex;
    height: 28px;
    justify-content: center;
    margin-right: 6px;
    width: 28px
}

.user-exchange p {
    color: #fff;
    font-size: 14px;
    font-weight: 700
}

.user-exchange p.is-not-selected {
    font-size: 10px;
    font-weight: 400
}

.bottom-sheet {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    min-width: 320px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999
}

.bottom-sheet-bg {
    background-color: #272a2fb3;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.bottom-sheet-inner {
    background: #000000;
    border-radius: 15px 15px 0 0;
    border-top: 2px solid #f3ba2f;
    box-shadow: 0 -10px 64px #69ffdd99;
    margin: 0 auto;
    max-width: 770px;
    position: relative;
    width: 100%;
    z-index: 2;
}

.bottom-sheet-scroll {
    max-height: calc(100vh - 64px);
    overflow-y: scroll;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;
    padding: 24px 16px 48px;
    scrollbar-width: none
}

.bottom-sheet-scroll::-webkit-scrollbar {
    display: none
}

.bottom-sheet-close {
    color: #4e4f50;
    cursor: pointer;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.bottom-sheet-close svg {
    height: 32px;
    width: 32px
}

.bottom-sheet-close:hover {
    color: #8b8e93
}

.bottom-sheet-button {
    margin-top: 24px
}

.bottom-sheet-button.is-sticky {
    bottom: 0;
    position: sticky
}

.bs-passive {
    padding-top: 40px
}

.bs-passive .bs-passive-inner {
    align-items: center;
    background: #000000;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -8px;
    padding: 56px 24px 24px;
    position: relative
}

.bs-passive .bs-passive-inner .price-value {
    font-size: 40px;
    font-weight: 700
}

.bs-passive .bs-passive-inner>p {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    margin-top: 16px;
    text-align: center
}

.bs-passive-exchange {
    align-items: center;
    background: #272a2f;
    border: 4px solid #1c1f24;
    border-radius: 40px;
    display: flex;
    height: 80px;
    justify-content: center;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 80px
}

.bs-open-enter-active,.bs-open-enter-active .bottom-sheet-bg,.bs-open-enter-active .bottom-sheet-inner,.bs-open-leave-active,.bs-open-leave-active .bottom-sheet-bg,.bs-open-leave-active .bottom-sheet-inner {
    transition: .1s ease .25s;
    -webkit-transition: .1s ease .25s
}

.bs-open-enter .bottom-sheet-bg,.bs-open-leave-to .bottom-sheet-bg {
    opacity: 0
}

.bs-open-enter .bottom-sheet-inner,.bs-open-leave-to .bottom-sheet-inner {
    transform: translateY(100%)
}

.bs-open-enter-to .bottom-sheet-inner,.bs-open-leave .bottom-sheet-inner {
    transform: translateY(0)
}

.bs-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center
}

.bs-content-dropdown {
    margin-bottom: 18px;
    margin-top: -6px;
    width: 100%
}

.bs-content-image {
    align-items: center;
    border-radius: 16px;
    display: flex;
    height: 115px;
    justify-content: center;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    width: 115px
}

.bs-content-image.is-airdrop,.bs-content-image.is-w100 {
    width: 100%
}

.bs-content-image.is-daily-combo-info {
    height: 226px;
    overflow: visible;
    width: 100%
}

.bs-content-image.is-hamster-drop {
    height: 162px;
    overflow: visible;
    width: 100%
}

.bs-content-image.is-daily-cipher-info {
    height: 226px;
    overflow: visible;
    width: 100%
}

.bs-content-image.is-daily-cipher {
    height: 216px;
    overflow: visible;
    width: 216px
}

.bs-content-image-round-bg {
    background-color: #ffffff0d;
    border-radius: 50%;
    height: 176px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 176px;
    z-index: 3
}

.bs-content-image-round-bg.is-violet:before {
    background-color: #6e4cf4
}

.bs-content-image-round-bg.is-yellow:before {
    background-color: #ffd337
}

.bs-content-image-round-bg:before {
    background-color: #376fff;
    filter: blur(37.907691955566406px);
    height: 160px;
    width: 160px;
    z-index: 1
}

.bs-content-image-round-bg:after,.bs-content-image-round-bg:before {
    border-radius: 50%;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

.bs-content-image-round-bg:after {
    background-color: #ffffff0d;
    height: 150px;
    width: 150px;
    z-index: 2
}

.bs-content-image.is-boost {
    overflow: visible
}

.bs-content-image.is-boost:before {
    background-color: #fc0000e6;
    border-radius: 50%;
    content: "";
    display: block;
    filter: blur(20px);
    height: 100px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px
}

.bs-content-image .exchange-image {
    height: 100%;
    width: 100%
}

.bs-content-image img,.bs-content-image svg {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: auto;
    z-index: 4
}

.bs-content-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 12px
}

.bs-content-title.has-top-gap {
    margin-top: 16px
}

.bs-content-subtitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px
}

.bs-content-description {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    max-width: 280px
}

.bs-content-description>.text-yellow {
    color: #ffd337
}

.bs-content-description.no-gap {
    margin-bottom: 12px;
    max-width: 100%;
    padding: 0 12px
}

.bs-content-description.venom_blockchain {
    font-size: 16px;
    line-height: 20px
}

.bs-content-description.is-hamster-drop {
    font-size: 13px;
    max-width: 100%
}

.bs-content-description.is-button {
    max-width: 100%
}

.bs-content-description.is-button button {
    display: inline-flex;
    min-width: 140px;
    width: auto
}

.bs-content-target {
    margin-bottom: 20px;
    max-width: 280px
}

.bs-content-target.no-gap {
    margin-bottom: 0
}

.bs-content-info {
    align-items: center;
    color: #8b8e93;
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    justify-content: center
}

.bs-content-info.is-tickets {
    color: #fff;
    font-weight: 700
}

.bs-content-info.is-tickets span {
    margin-left: 6px
}

.bs-content-daily {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.bs-content-daily li {
    align-items: center;
    background: #272a2f;
    border-radius: 16px;
    display: flex;
    flex: 0 0 calc(25% - 6px);
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    height: 90px;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 8px;
    position: relative
}

.bs-content-daily li:nth-child(4n) {
    margin-right: 0
}

.bs-content-daily li.is-completed {
    background: linear-gradient(180deg,#62cc6c,#2a7031)
}

.bs-content-daily li.is-current:before {
    border: 2px solid #62cc6c;
    border-radius: 16px;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.bs-content-daily li.is-disabled {
    opacity: .4
}

.bs-content-daily-coin {
    height: 24px;
    position: relative;
    width: 24px
}

.bs-content-daily-coin img {
    position: relative;
    z-index: 3
}

.bs-content-daily-coin:before {
    height: 30px;
    width: 30px
}

.bs-content-daily-coin:after,.bs-content-daily-coin:before {
    background-color: #ffffff1a;
    border-radius: 50%;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2
}

.bs-content-daily-coin:after {
    height: 36px;
    width: 36px
}

.coin {
    display: block
}

.coin.is-14 {
    height: 14px;
    margin-right: -2px;
    min-width: 14px;
    width: 14px
}

.coin.is-18 {
    height: 18px;
    margin-right: -2px;
    min-width: 18px;
    width: 18px
}

.coin.is-20 {
    height: 20px;
    min-width: 20px;
    width: 20px
}

.coin.is-26 {
    height: 26px;
    min-width: 26px;
    width: 26px
}

.coin.is-28 {
    height: 28px;
    min-width: 28px;
    width: 28px
}

.coin.is-40 {
    height: 40px;
    min-width: 40px;
    width: 40px
}

.coin.is-60 {
    height: 60px;
    min-width: 60px;
    width: 60px
}

.connect-ton-wallet {
    margin-bottom: 12px;
    position: relative;
    width: 100%
}

.connect-ton-wallet-button {
    align-items: center;
    background: linear-gradient(98deg,#35a6eb 3.58%,#309adb 101.32%);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 44px;
    justify-content: center;
    padding: 0 12px;
    width: 100%
}

.connect-ton-wallet-button .icon {
    height: 24px;
    margin-right: 6px;
    width: 24px
}

.connect-ton-wallet-info {
    align-items: center;
    background: #ffffff1a;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: 700;
    height: 44px;
    justify-content: flex-start;
    padding: 0 16px
}

.connect-ton-wallet-info .icon {
    color: #8b8e93;
    height: 24px;
    margin-right: 6px;
    width: 24px
}

.connect-ton-wallet-info .icon.is-copy-icon {
    margin-top: -12px;
    position: absolute;
    right: 6px;
    top: 50%
}

.connect-ton-wallet-connected,.connect-ton-wallet-disconnect {
    align-items: center;
    display: flex;
    justify-content: center
}

.connect-ton-wallet-disconnect {
    background: #ffffff1a;
    border-radius: 10px;
    color: #999a9c;
    cursor: pointer;
    height: 44px;
    margin-right: 8px;
    width: 44px
}

.hk-dropdown {
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.hk-dropdown.is-open .hk-dropdown-icon {
    transform: rotate(180deg)
}

.hk-dropdown-select {
    background: #272a2f;
    border-radius: 20px;
    padding: 12px 48px 12px 16px;
    position: relative;
    text-align: left;
    width: 100%
}

.hk-dropdown-placeholder {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 6px
}

.hk-dropdown-value {
    color: #8b8e93;
    font-size: 14px;
    line-height: 1;
    min-height: 14px
}

.hk-dropdown-icon {
    color: #8b8e93;
    height: 24px;
    margin-top: -12px;
    position: absolute;
    right: 16px;
    top: 50%;
    width: 24px
}

.hk-dropdown-icon svg {
    height: 100%;
    transform: rotate(90deg);
    width: 100%
}

.hk-dropdown u1l {
    background-color: #272a2f;
    border-radius: 20px;
    left: 0;
    margin-top: 6px;
    padding: 0 16px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10
}

.hk-dropdown u1l li {
    border-bottom: 1px solid #34383f;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 0;
    text-align: left
}

.hk-dropdown u1l li:last-child {
    border-bottom: none
}

.hk-dropdown u1l li span {
    color: #8b8e93
}

.hk-dropdown u1l li span.is-white {
    color: #fff
}

.language-switcher {
    position: relative;
    z-index: 10
}

.language-switcher-button {
    align-items: center;
    background: #5a60ff;
    border-radius: 12px;
    color: #fff;
    display: inline-flex;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    line-height: 16px;
    padding: 6px 12px;
    width: auto
}

.language-switcher-dropdown {
    background-color: #272a2f;
    box-shadow: 0 8px 16px #0003;
    margin-top: 6px;
    min-width: 100%;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1
}

.language-switcher-dropdown a {
    border-bottom: 1px solid #4e4f50;
    color: #fff;
    display: block;
    font-size: 12px;
    padding: 12px;
    text-align: right;
    text-decoration: none;
    white-space: nowrap
}

.language-switcher-dropdown a:hover {
    background-color: #34383f
}

.language-switcher-dropdown a:last-child {
    border-bottom: none
}

.loading {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    z-index: 10
}

.loading-logout {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 100
}

.loading-progress {
    height: 2px;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 10
}

.loading-progress:before {
    background: linear-gradient(90deg,#5960ff,#d885ff 53%,#82f88e);
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw
}

.loading-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.loading-background .icon {
    height: 100%;
    width: 100%
}

.loading-background svg {
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
    width: 100%
}

.loading-divider {
    flex: 1 1
}

.loading-circle {
    align-self: center
}

.loading-circle svg {
    animation: rotate 2s linear infinite;
    height: 62px;
    width: 62px
}

.loading-text {
    align-self: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    margin-top: 12px;
    text-align: center
}

.loading-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 12px
}

.loading-roadmap {
    list-style: none;
    position: relative
}

.loading-roadmap.has-top-gap {
    margin-top: 32px
}

.loading-roadmap:before {
    background-image: linear-gradient(180deg,transparent 50%,#3e3e3e 0);
    background-size: 100% 6px;
    content: "";
    height: calc(100% - 24px);
    left: 12px;
    position: absolute;
    top: 12px;
    width: 1px;
    z-index: 1
}

.loading-roadmap li {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.5vh;
    min-height: 30px;
    position: relative;
    z-index: 2
}

.loading-roadmap li:last-child {
    margin-bottom: 0
}

.loading-roadmap li p {
    font-size: 20px;
    font-weight: 400;
    margin-left: 12px
}

.loading-roadmap li .earn-image,.loading-roadmap li>img,.loading-roadmap li>picture {
    height: 30px;
    margin-left: 12px;
    width: 30px
}

.loading-roadmap li .earn-image.is-coin,.loading-roadmap li>img.is-coin,.loading-roadmap li>picture.is-coin {
    height: 28px;
    margin-left: 14px;
    margin-right: 2px;
    width: 28px
}

.loading-roadmap-check {
    align-items: center;
    background-color: #5a5a5a;
    border: 4px solid #34383f;
    border-radius: 50%;
    display: flex;
    height: 24px;
    justify-content: center;
    min-width: 24px;
    width: 24px
}

.loading-roadmap-check.is-completed {
    background-color: #62cc6c
}

.loading-launch {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    z-index: 5
}

.loading-launch-image {
    height: 90%;
    left: 0;
    position: absolute;
    right: 0;
    top: -11%;
    z-index: 1
}

.loading-launch-image img {
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    width: 100%
}

.loading-launch-progress {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -12px;
    margin-top: 48px;
    position: relative;
    z-index: 6
}

.loading-launch-progress .icon {
    animation: rotate 2s linear infinite;
    height: 62px;
    margin-bottom: 20px;
    width: 62px
}

.loading-launch-progress-text {
    font-size: 24px;
    font-weight: 700
}

.loading-launch-progress-text.is-morse-mode {
    font-size: 18px
}

.loading-launch-bottom {
    align-items: center;
    background: linear-gradient(180deg,transparent,#000 59.59%,#000);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 50vh;
    padding: 80px 20px 40px;
    text-align: center;
    width: 100%;
    z-index: 5
}

.loading-launch-text-token {
    color: #ffc147;
    font-size: 26px;
    font-weight: 1000;
    margin-bottom: 8px
}

.loading-launch-text-launch {
    color: #ffffffb3;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 8px
}

.loading-launch-text-title {
    font-size: 84px;
    font-weight: 1000
}

.loading-launch-social {
    margin-top: 16px
}

.loading-launch-social p {
    color: #ffffffb3;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px
}

.loading-launch-social span {
    display: block;
    font-size: 16px;
    font-weight: 700
}

.loading-launch-social i {
    color: #8b8e93;
    display: block;
    font-size: 10px;
    font-style: normal;
    margin-top: 12px
}

.loading-launch-social u1l {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 16px
}

.loading-launch-social u1l li {
    align-items: center;
    background: #ffffff1a;
    border: 1px solid hsla(0,0%,100%,.05);
    border-radius: 50%;
    display: flex;
    height: 46px;
    justify-content: center;
    margin: 0 6px;
    width: 46px
}

.loading-launch-social u1l li .icon.is-telegram {
    height: 26px;
    width: 26px
}

.loading-launch-social u1l li .icon.is-youtube {
    height: 20px;
    width: 27px
}

.loading-launch-social u1l li .icon.is-x {
    height: 20px;
    width: 20px
}

@keyframes rotate {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(1turn)
    }
}

body {
    --nv-gap: 4px
}

body .Notivue__close-icon {
    height: 16px;
    width: 16px
}

body .Notivue__content-title {
    margin-bottom: 2px
}

.preloader {
    align-items: center;
    display: inline-flex
}

.preloader span {
    animation: fade 1s ease infinite;
    background-color: #fff;
    border-radius: 50%;
    height: 6px;
    margin: 0 3px;
    opacity: 0;
    width: 6px
}

.preloader span:nth-child(2) {
    animation-delay: .1s
}

.preloader span:nth-child(3) {
    animation-delay: .2s
}

@keyframes fade {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.price {
    color: #fff;
    font-weight: 700;
    position: relative
}

.price,.price-value {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start
}

.price-value {
    margin-left: 6px;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.price.is-grayscale {
    color: #fff6
}

.price.is-grayscale img {
    filter: grayscale(1)
}

.price.is-spoiler {
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.price.is-spoiler:before {
    animation: shimmer 2s infinite;
    background-image: linear-gradient(90deg,#fff0,#fff3 20%,#ffffff80 60%,#fff0);
    content: "";
    display: block;
    height: calc(100% + 6px);
    left: -3px;
    position: absolute;
    top: -3px;
    transform: translate(-100%);
    width: calc(100% + 6px);
    z-index: 11
}

.price.is-spoiler .price-value {
    visibility: hidden
}

.price.is-spoiler:after {
    background: #ccc url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAE0klEQVQ4jW2Ub0gTeBjHP3o2rJZJqZtmy4l4liGrjjVCNxUTWTbC7fxT5xCZQ20TKREzNmwGBlYKgrFdy/4YUTbKTMw2cJ0ehTS8jigcYuYLUfoD2SRfrPNenIrGPe9+z/f7PN/v8zzwA8BoNO4pLS1NBvD7/c6cnByhw+EoBmhsbNRbLJYklUqVGxMTk3jixImY5ZrKly9fRh0+fHgoIiJi/7t37wpYG/fv398GcO3aNatWq9UrlUpdX1+fBKC/v791aWlJDpCWlvZLSUlJJIBUKi0fHBy0A3g8nkQAvF6vJi8vTxMbG9sIUFZWZoyNjc2/d+9ewvj4eOH27dsFbW1thwBsNls1gMvlyllrxmazJQGkpqb+HpaZmfloOf+ovb3dUFtb6wCYn5832u32n3U63dTWrVs3ARQVFf3l9/v18/PzYsAjl8tj1Gp1+PT0tCAnJ8fk8XgqABAKhdkAPp9PWV5e3hUdHS2bmprKcbvd8v7+/jx+iIcPH57SarV/Auh0utqRkZF4u92ev0owGAya+Pj45KSkpOQHDx4oBAJB/LJQwfv37zWTk5MpnZ2dTpfLdWht42/fvoUDTExMlL1+/bp4nWpLS0uC1+v97Uc3AAqF4pRCoegA2LBhQ3lXV1cbwOfPn5sBdu3a1bpKrq+vb37y5Ing7t27coDc3NzjK9jjx4/zANxutxqgqalJAPD9+/fkrKys7OXpJABzc3OGdS5evHhhmJmZyQwLC4vR6/XOgYGBU7Ozs8KGhoZ1rltaWuQAHR0dCSEhIXnXr18vW9fIYrG0AeTn5ysDgYDs5s2b9QCtra31TU1NeoC+vr5ykUgUNTw8nGSxWOp6enoKAUQikaa7u/vcvn379ADR0dGXANBoNLmvXr3KHRoaSpZKpZv+b4+Tk5Pp2dnZcQASieTYWuz8+fO9MplMbbVaaygpKVEAjI+P1wL4/f7jlZWVyhXyzp07jXfu3Dne2dnpPHDggGwlPzo6mm+32w/t3r17/8LCQsStW7cMDodDiEql2pSWlqYXi8VhWq02ub29vfDjx48JFy5ciCouLl5t0NzcvK2mpibRZrMZPnz40ALw6dMn3Qp+8uTJ/3Z95MgRmdfrTQcwGo25AFlZWSaAuro609GjR6srKip0Ho+nJyMjY/WSPp8vDSAQCAhSUlLUK/kQgB07duRt3LgxMDExMbICXL58OSIYDBaIRKJ/4uLinj5//jzcarVOAYjFYsns7Oz0zMzM3mAwuE0ikfxx5syZhqGhoW4AtmzZkjw4OLgH4Pbt22qAlJQUmVKpVD979iz87du31q9fv4oDgUAEgF6vV6w9ikajyTOZTHsAfurt7c12u92LJpMpIyEhQRAaGjoqlUrlKpVK6HQ6n75582avz+dTl5aWds/Nzf06Njb2dzAYzLVarUsDAwNhwLzD4SheXFxciIyMjFpV+fLli2yt6tjYmPDs2bNtK+/ExMTiqqoqXVFRkQGgqqoqyel0hgIsLS0J9Xr9pdVip9N5DqC2tjb9ypUrws2bNyf09PRkrhUwm83ZFy9eLHa5XGqz2bz6EVitVh3A1atX4+rq6sr5MRwOh3xxcfFYamqqGGB4eHjv8sX11dXVhQA3btxIWuEfPHgw3Ww2F5w+fToS4F8g0dVFJm8BmQAAAABJRU5ErkJggg==) repeat;
    content: "";
    display: block;
    height: calc(100% + 6px);
    left: -3px;
    position: absolute;
    top: -3px;
    width: calc(100% + 6px);
    z-index: 10
}

@keyframes shimmer {
    to {
        transform: translate(100%)
    }
}

.tabs-list {
    align-items: stretch;
    background: #272a2f;
    border: 1px solid #1c1f24;
    border-radius: 10px;
    box-shadow: 0 0 4px 4px #1c1f2433;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 4px;
    position: sticky;
    top: 4px;
    z-index: 20
}

.tabs-item {
    align-items: center;
    border-radius: 6px;
    color: #fff;
    display: flex;
    flex: 1 1;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.tabs-item.is-active {
    background: #1c1f24
}

.tabs-inner {
    margin-top: 20px;
    position: relative
}

.atabs {
    border-bottom: 1px solid #34383f
}

.atabs-inner {
    align-items: stretch;
    display: flex;
    height: 42px
}

.atabs-item {
    cursor: pointer;
    flex: 1 1;
    line-height: 1;
    padding-top: 14px;
    position: relative;
    text-align: center;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-tap-highlight-color: transparent;
    color: #8b8e93;
    font-size: 14px;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.atabs-item.is-active {
    color: #fff
}

.atabs-item.is-active:before {
    background-color: #fff;
    bottom: -1px;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%
}

.atabs-item:hover {
    color: #fff
}

.tabs-special {
    align-items: center;
    border-bottom: 1px solid #272a2f;
    display: flex;
    justify-content: flex-start
}

.tabs-special-item {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin-right: 16px;
    padding: 8px 2px;
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.tabs-special-item:last-child {
    margin-right: 0
}

.tabs-special-item.is-active {
    border-color: #ffd337;
    color: #ffd337;
    position: relative
}

.tabs-special-item.is-active:before {
    background-color: #ffd337;
    bottom: -1px;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%
}

.tabs-special-item:hover {
    color: #ffd337
}

.tabs-special-inner {
    margin-top: 20px;
    position: relative
}

.tabs-special-inner .upgrade-list {
    min-height: 224px
}

.toggle-switch {
    background: #8b8e93;
    border-radius: .75em;
    box-shadow: inset 1px 1px 1px #1111121a;
    cursor: pointer;
    flex: none;
    height: 24px;
    position: relative;
    transition: background-color .15s .25s;
    -webkit-transition: background-color .15s .25s;
    width: 3em
}

.toggle-switch:before {
    background: #fff;
    background-image: radial-gradient(circle at .375em .375em,transparent 0,rgba(0,0,0,.05) 1em);
    border-radius: 50%;
    box-shadow: 1px 1px 1px #11111214;
    content: "";
    display: block;
    height: 20px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: left .15s .25s;
    -webkit-transition: left .15s .25s;
    width: 20px;
    will-change: left
}

.toggle-switch:hover {
    box-shadow: inset 1px 1px 1px #1111121f
}

.toggle-switch:hover:before {
    background-image: radial-gradient(circle at .375em .375em,transparent 0,rgba(0,0,0,.037) 1em);
    box-shadow: .0625em .0625em .0625em #1111121f
}

.toggle-switch.checked {
    background-color: #7277ff
}

.toggle-switch.checked:before {
    background-image: radial-gradient(circle at .375em .375em,transparent 0,rgba(0,0,0,.05) 1em);
    left: 26px
}

.v-popper--theme-hk-tooltip.v-popper__popper {
    z-index: 10
}

.v-popper--theme-hk-tooltip .v-popper__inner {
    background-color: #000;
    border-radius: 10px;
    padding: 4px 6px
}

.v-popper--theme-hk-tooltip .v-popper__arrow-inner,.v-popper--theme-hk-tooltip .v-popper__arrow-outer {
    border-color: #000
}

.v-popper--theme-hk-tooltip .v-popper__inner {
    font-size: 11px;
    line-height: 12px;
    text-align: center
}

.v-popper--theme-hk-fixed-tooltip.v-popper__popper {
    z-index: 10
}

.v-popper--theme-hk-fixed-tooltip .v-popper__inner {
    background-color: #000;
    border-radius: 10px;
    max-width: calc(33.33333vw - 18.66667px);
    padding: 4px 6px
}

.v-popper--theme-hk-fixed-tooltip .v-popper__arrow-inner,.v-popper--theme-hk-fixed-tooltip .v-popper__arrow-outer {
    border-color: #000
}

.v-popper--theme-hk-fixed-tooltip .v-popper__inner {
    font-size: 11px;
    line-height: 12px;
    text-align: center
}

[data-notivue=promise-resolve],[data-notivue=success] {
    --nv-bg: var(--nv-success-bg);
    --nv-fg: var(--nv-success-fg);
    --nv-accent: var(--nv-success-accent);
    --nv-border: var(--nv-success-border)
}

[data-notivue=error],[data-notivue=promise-reject] {
    --nv-bg: var(--nv-error-bg);
    --nv-fg: var(--nv-error-fg);
    --nv-accent: var(--nv-error-accent);
    --nv-border: var(--nv-error-border)
}

[data-notivue=warning] {
    --nv-bg: var(--nv-warning-bg);
    --nv-fg: var(--nv-warning-fg);
    --nv-accent: var(--nv-warning-accent);
    --nv-border: var(--nv-warning-border)
}

[data-notivue=info] {
    --nv-bg: var(--nv-info-bg);
    --nv-fg: var(--nv-info-fg);
    --nv-accent: var(--nv-info-accent);
    --nv-border: var(--nv-info-border)
}

[data-notivue=promise] {
    --nv-bg: var(--nv-promise-bg);
    --nv-fg: var(--nv-promise-fg);
    --nv-accent: var(--nv-promise-accent);
    --nv-border: var(--nv-promise-border)
}

.Notivue__notification {
    --tip-width: calc(var(--tip-width-fx, 1)*var(--nv-tip-width, 0px));
    align-items: var(--nv-y-align);
    background-color: var(--nv-bg,var(--nv-global-bg));
    border: var(--nv-border-width,0) solid var(--nv-border,var(--nv-global-border));
    border-radius: var(--nv-radius,0);
    box-shadow: var(--nv-shadow,0 0 transparent),inset var(--tip-width) 0 0 var(--nv-accent,var(--nv-global-accent));
    box-sizing: border-box;
    display: flex;
    max-width: var(--nv-width,100%);
    position: relative;
    width: var(--nv-min-width,auto)
}

.Notivue__notification * {
    box-sizing: border-box;
    touch-action: manipulation
}

.Notivue__notification[data-notivue-has-title=true] {
    align-items: var(--nv-y-align-has-title,var(--nv-y-align))
}

.Notivue__icon {
    align-items: center;
    color: var(--nv-accent,var(--nv-global-accent));
    display: flex;
    height: var(--nv-icon-size);
    justify-content: center;
    margin: var(--nv-spacing) 0 var(--nv-spacing) var(--nv-spacing);
    min-width: var(--nv-icon-size);
    overflow: visible;
    width: var(--nv-icon-size)
}

.Notivue__content {
    --nv-content-space: calc(var(--nv-spacing) + var(--nv-spacing)*.15);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: var(--nv-content-space);
    text-align: inherit
}

.Notivue__content-title {
    color: var(--nv-fg,var(--nv-global-fg));
    font-size: var(--nv-title-size);
    font-weight: 700;
    line-height: 1.15;
    margin: 0 0 .33em;
    padding: 0
}

.Notivue__transition-enter-active {
    --dur-easing: .5s cubic-bezier(.16,1,.3,1);
    transition: opacity var(--dur-easing),transform var(--dur-easing)
}

@media (prefers-reduced-motion:reduce) {
    .Notivue__transition-enter-active {
        transition: none
    }
}

.Notivue__transition-enter-from {
    opacity: 0;
    transform: scale(.8)
}

.Notivue__transition-enter-to {
    opacity: 1;
    transform: scale(1)
}

.Notivue__transition-leave-active {
    transition: opacity 0ms ease
}

.Notivue__transition-leave-from,.Notivue__transition-leave-to {
    opacity: 0
}

.Notivue__content-message {
    line-height: 1.375;
    max-height: 250px;
    overflow: auto;
    padding: 0;
    white-space: pre-line
}

.Notivue__close,.Notivue__content-message {
    color: var(--nv-fg,var(--nv-global-fg));
    font-size: var(--nv-message-size);
    margin: 0
}

.Notivue__close {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    margin: var(--nv-spacing) var(--nv-spacing) var(--nv-spacing) 0;
    padding: calc(var(--nv-spacing)/2);
    position: relative;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.Notivue__close:after {
    background-color: var(--nv-fg,var(--nv-global-fg));
    border-radius: calc(var(--nv-radius)/2);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .1s ease-out;
    width: 100%
}

@media (hover: hover) {
    .Notivue__close:hover {
        opacity:1
    }

    .Notivue__close:hover:after {
        opacity: .1
    }

    .Notivue__close:hover .Notivue__close-icon {
        opacity: 1
    }
}

.Notivue__close-icon {
    color: var(--nv-fg,var(--nv-global-fg));
    display: flex;
    height: var(--nv-icon-size);
    opacity: .65;
    pointer-events: none;
    width: var(--nv-icon-size)
}

.Notivue__spinner {
    animation: Notivue__spinner-kf .75s linear infinite;
    transform-origin: center
}

@keyframes Notivue__spinner-kf {
    to {
        transform: rotate(1turn)
    }
}

@media (prefers-reduced-motion:reduce) {
    .Notivue__spinner {
        animation: none
    }
}

[dir=rtl] .Notivue__notification {
    --tip-width-fx: -1
}

[dir=rtl] .Notivue__icon {
    margin: var(--nv-spacing) var(--nv-spacing) var(--nv-spacing) 0
}

[dir=rtl] .Notivue__content-message {
    font-size: calc(var(--nv-message-size) + .1em)
}

[dir=rtl] .Notivue__content-title {
    font-size: calc(var(--nv-title-size) + .1em)
}

[dir=rtl] .Notivue__close {
    margin: var(--nv-spacing) 0 var(--nv-spacing) var(--nv-spacing)
}

[data-notivue-align=top] .Notivue__enter,[data-notivue-align=top] .Notivue__leave {
    --notivue-ty: -200%
}

[data-notivue-align=bottom] .Notivue__enter,[data-notivue-align=bottom] .Notivue__leave {
    --notivue-ty: 200%
}

.Notivue__enter {
    animation: Notivue__enter-kf .35s cubic-bezier(.5,1,.25,1)
}

.Notivue__leave {
    animation: Notivue__leave-kf .35s ease
}

.Notivue__clearAll {
    animation: Notivue__clearAll-kf .5s cubic-bezier(.22,1,.36,1)
}

@keyframes Notivue__enter-kf {
    0% {
        opacity: 0;
        transform: translate3d(0,var(--notivue-ty),0) scale(.25)
    }

    to {
        opacity: 1;
        transform: translateZ(0) scale(1)
    }
}

@keyframes Notivue__leave-kf {
    0% {
        opacity: .7;
        transform: translateZ(0) scale(1)
    }

    to {
        opacity: 0;
        transform: translate3d(0,var(--notivue-ty),0) scale(.01)
    }
}

@keyframes Notivue__clearAll-kf {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@font-face {
    font-display: swap;
    font-family: Outfit;
    font-style: normal;
    font-weight: 400;
    src: url(./Outfit-400-1.5oMg_0D4.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Outfit;
    font-style: normal;
    font-weight: 400;
    src: url(./Outfit-400-2.CdKZfrg9.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
    font-display: swap;
    font-family: Outfit;
    font-style: normal;
    font-weight: 500;
    src: url(./Outfit-400-1.5oMg_0D4.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Outfit;
    font-style: normal;
    font-weight: 500;
    src: url(./Outfit-400-2.CdKZfrg9.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
    font-display: swap;
    font-family: Outfit;
    font-style: normal;
    font-weight: 700;
    src: url(./Outfit-400-1.5oMg_0D4.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Outfit;
    font-style: normal;
    font-weight: 700;
    src: url(./Outfit-400-2.CdKZfrg9.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto_Flex-400-7.DRTNI9VV.woff2) format("woff2");
    unicode-range: u+0460-052f,u+1c80-1c88,u+20b4,u+2de0-2dff,u+a640-a69f,u+fe2e-fe2f
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto_Flex-400-8.C9LxQtoz.woff2) format("woff2");
    unicode-range: u+0301,u+0400-045f,u+0490-0491,u+04b0-04b1,u+2116
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto_Flex-400-9.DeN4rzfy.woff2) format("woff2");
    unicode-range: u+0370-0377,u+037a-037f,u+0384-038a,u+038c,u+038e-03a1,u+03a3-03ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto_Flex-400-10.Dfb42xSH.woff2) format("woff2");
    unicode-range: u+0102-0103,u+0110-0111,u+0128-0129,u+0168-0169,u+01a0-01a1,u+01af-01b0,u+0300-0301,u+0303-0304,u+0308-0309,u+0323,u+0329,u+1ea0-1ef9,u+20ab
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto_Flex-400-11.DdIPVSJ2.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto_Flex-400-12.C2pcyVKb.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    src: url(./Roboto_Flex-400-7.DRTNI9VV.woff2) format("woff2");
    unicode-range: u+0460-052f,u+1c80-1c88,u+20b4,u+2de0-2dff,u+a640-a69f,u+fe2e-fe2f
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    src: url(./Roboto_Flex-400-8.C9LxQtoz.woff2) format("woff2");
    unicode-range: u+0301,u+0400-045f,u+0490-0491,u+04b0-04b1,u+2116
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    src: url(./Roboto_Flex-400-9.DeN4rzfy.woff2) format("woff2");
    unicode-range: u+0370-0377,u+037a-037f,u+0384-038a,u+038c,u+038e-03a1,u+03a3-03ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    src: url(./Roboto_Flex-400-10.Dfb42xSH.woff2) format("woff2");
    unicode-range: u+0102-0103,u+0110-0111,u+0128-0129,u+0168-0169,u+01a0-01a1,u+01af-01b0,u+0300-0301,u+0303-0304,u+0308-0309,u+0323,u+0329,u+1ea0-1ef9,u+20ab
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    src: url(./Roboto_Flex-400-11.DdIPVSJ2.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 600;
    src: url(./Roboto_Flex-400-12.C2pcyVKb.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto_Flex-400-7.DRTNI9VV.woff2) format("woff2");
    unicode-range: u+0460-052f,u+1c80-1c88,u+20b4,u+2de0-2dff,u+a640-a69f,u+fe2e-fe2f
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto_Flex-400-8.C9LxQtoz.woff2) format("woff2");
    unicode-range: u+0301,u+0400-045f,u+0490-0491,u+04b0-04b1,u+2116
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto_Flex-400-9.DeN4rzfy.woff2) format("woff2");
    unicode-range: u+0370-0377,u+037a-037f,u+0384-038a,u+038c,u+038e-03a1,u+03a3-03ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto_Flex-400-10.Dfb42xSH.woff2) format("woff2");
    unicode-range: u+0102-0103,u+0110-0111,u+0128-0129,u+0168-0169,u+01a0-01a1,u+01af-01b0,u+0300-0301,u+0303-0304,u+0308-0309,u+0323,u+0329,u+1ea0-1ef9,u+20ab
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto_Flex-400-11.DdIPVSJ2.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto_Flex-400-12.C2pcyVKb.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 1000;
    src: url(./Roboto_Flex-400-7.DRTNI9VV.woff2) format("woff2");
    unicode-range: u+0460-052f,u+1c80-1c88,u+20b4,u+2de0-2dff,u+a640-a69f,u+fe2e-fe2f
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 1000;
    src: url(./Roboto_Flex-400-8.C9LxQtoz.woff2) format("woff2");
    unicode-range: u+0301,u+0400-045f,u+0490-0491,u+04b0-04b1,u+2116
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 1000;
    src: url(./Roboto_Flex-400-9.DeN4rzfy.woff2) format("woff2");
    unicode-range: u+0370-0377,u+037a-037f,u+0384-038a,u+038c,u+038e-03a1,u+03a3-03ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 1000;
    src: url(./Roboto_Flex-400-10.Dfb42xSH.woff2) format("woff2");
    unicode-range: u+0102-0103,u+0110-0111,u+0128-0129,u+0168-0169,u+01a0-01a1,u+01af-01b0,u+0300-0301,u+0303-0304,u+0308-0309,u+0323,u+0329,u+1ea0-1ef9,u+20ab
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 1000;
    src: url(./Roboto_Flex-400-11.DdIPVSJ2.woff2) format("woff2");
    unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20c0,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
    font-display: swap;
    font-family: Roboto Flex;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 1000;
    src: url(./Roboto_Flex-400-12.C2pcyVKb.woff2) format("woff2");
    unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

.resize-observer[data-v-b329ee4c] {
    background-color: transparent;
    border: none;
    opacity: 0
}

.resize-observer[data-v-b329ee4c],.resize-observer[data-v-b329ee4c] object {
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.v-popper__popper {
    left: 0;
    outline: none;
    top: 0;
    z-index: 10000
}

.v-popper__popper.v-popper__popper--hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity .15s,visibility .15s;
    visibility: hidden
}

.v-popper__popper.v-popper__popper--shown {
    opacity: 1;
    transition: opacity .15s;
    visibility: visible
}

.v-popper__popper.v-popper__popper--skip-transition,.v-popper__popper.v-popper__popper--skip-transition>.v-popper__wrapper {
    transition: none!important
}

.v-popper__backdrop {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.v-popper__inner {
    box-sizing: border-box;
    overflow-y: auto;
    position: relative
}

.v-popper__inner>div {
    max-height: inherit;
    max-width: inherit;
    position: relative;
    z-index: 1
}

.v-popper__arrow-container {
    height: 10px;
    position: absolute;
    width: 10px
}

.v-popper__popper--arrow-overflow .v-popper__arrow-container,.v-popper__popper--no-positioning .v-popper__arrow-container {
    display: none
}

.v-popper__arrow-inner,.v-popper__arrow-outer {
    border-style: solid;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0
}

.v-popper__arrow-inner {
    border-width: 7px;
    visibility: hidden
}

.v-popper__arrow-outer {
    border-width: 6px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-inner {
    left: -2px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer,.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-outer {
    left: -1px
}

.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-outer {
    border-bottom-color: transparent!important;
    border-bottom-width: 0;
    border-left-color: transparent!important;
    border-right-color: transparent!important
}

.v-popper__popper[data-popper-placement^=top] .v-popper__arrow-inner {
    top: -2px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-container {
    top: 0
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer {
    border-left-color: transparent!important;
    border-right-color: transparent!important;
    border-top-color: transparent!important;
    border-top-width: 0
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner {
    top: -4px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer {
    top: -6px
}

.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner {
    top: -2px
}

.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-outer,.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer {
    top: -1px
}

.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer {
    border-bottom-color: transparent!important;
    border-left-color: transparent!important;
    border-left-width: 0;
    border-top-color: transparent!important
}

.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-inner {
    left: -4px
}

.v-popper__popper[data-popper-placement^=right] .v-popper__arrow-outer {
    left: -6px
}

.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-container {
    right: -10px
}

.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-inner,.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-outer {
    border-bottom-color: transparent!important;
    border-right-color: transparent!important;
    border-right-width: 0;
    border-top-color: transparent!important
}

.v-popper__popper[data-popper-placement^=left] .v-popper__arrow-inner {
    left: -2px
}

.v-popper--theme-tooltip .v-popper__inner {
    background: #000c;
    border-radius: 6px;
    color: #fff;
    padding: 7px 12px 6px
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
    border-color: #000c
}

.v-popper--theme-dropdown .v-popper__inner {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 6px 30px #1111121a;
    color: #000
}

.v-popper--theme-dropdown .v-popper__arrow-inner {
    border-color: #fff;
    visibility: visible
}

.v-popper--theme-dropdown .v-popper__arrow-outer {
    border-color: #ddd
}


.clickAmount {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 9000;
  }

  .gem-mining-bg .content.has-glow {
    background: url('gembg.jpg') no-repeat center center;
    background-size: cover;
    border-radius: 0px 0px 0 0;
    border-top: 2px solid #8e49cc;
    box-shadow: 0 -4px 64px rgba(142, 73, 204, 0.6);
  }

  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('loading.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-content {
    width: 80%;
    max-width: 300px;
  }
  
  .loading-screen .user-level {
    width: 100%;
  }
  
  .loading-screen .user-level-progress {
    height: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .loading-screen .user-level-progress-bar {
    height: 100%;
    background-color: #ffffff;
    transition: width 0.2s ease-out;
  }
  
  .featherAnimation {
    pointer-events: none;
  }

  .app-bar-black {
    background-color: black;
  }


  .unsupported-platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .unsupported-platform h1 {
    margin-bottom: 20px;
  }
  
  .qr-code {
    max-width: 200px;
    height: auto;
  }



  .exchange-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .exchange-button {
    margin-top: 10%;
    padding: 14px 30px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    background-color: #ffd300;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
}
  
  .exchange-button:hover {
    background-color: #ffcc00;
  }
  
  .exchange-button:active {
    transform: scale(0.95);
  }


 
  .user-tap-button {
    background-color: transparent;
    height: 260px;
    margin: 0 auto;
    width: 75%;
    position: relative;
    transition: none;
  }
  
  .user-tap-button-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.1s;
  }
  
  .user-tap-button-circle {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .coin-button-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }
  

  .user-tap-button {
    background-color: transparent;
    height: 260px;
    margin: 0 auto;
    width: 75%;
    position: relative;
    transition: none;
  }
  
  .user-tap-button-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.1s;
  }
  
  .user-tap-button-circle {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .coin-button-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }
  
  .is-hamster-image {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    z-index: 2;
  }
  
  .is-hamster-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .user-tap-button:active:not(:disabled) .user-tap-button-inner {
    transform: scale(0.95);
  }
  
  .user-tap-button:disabled {
    cursor: not-allowed;
  }

  
