:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-primary-rgb: 13, 110, 253;
	--bs-secondary-rgb: 108, 117, 125;
	--bs-success-rgb: 25, 135, 84;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 255, 193, 7;
	--bs-danger-rgb: 220, 53, 69;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 33, 37, 41;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-b0dy-color-rgb: 33, 37, 41;
	--bs-b0dy-bg-rgb: 255, 255, 255;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--bs-b0dy-font-family: var(--bs-font-sans-serif);
	--bs-b0dy-font-size: 1rem;
	--bs-b0dy-font-weight: 400;
	--bs-b0dy-line-height: 1.5;
	--bs-b0dy-color: #212529;
	--bs-b0dy-bg: #fff
}

*,
*::before,
*::after {
	box-sizing: border-box
}

b0dy {
	margin: 0;
	font-family: var(--bs-b0dy-font-family);
	font-size: var(--bs-b0dy-font-size);
	font-weight: var(--bs-b0dy-font-weight);
	line-height: var(--bs-b0dy-line-height);
	color: var(--bs-b0dy-color);
	text-align: var(--bs-b0dy-text-align);
	background-color: var(--bs-b0dy-bg);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: .25
}

hr:not([size]) {
	height: 1px
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin-top: 0;
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2
}

h1,
.h1 {
	font-size: calc(1.375rem + 1.5vw)
}

@media(min-width:1200px) {

	h1,
	.h1 {
		font-size: 2.5rem
	}
}

h2,
.h2 {
	font-size: calc(1.325rem + 0.9vw)
}

@media(min-width:1200px) {

	h2,
	.h2 {
		font-size: 2rem
	}
}

h3,
.h3 {
	font-size: calc(1.3rem + 0.6vw)
}

@media(min-width:1200px) {

	h3,
	.h3 {
		font-size: 1.75rem
	}
}

h4,
.h4 {
	font-size: calc(1.275rem + 0.3vw)
}

@media(min-width:1200px) {

	h4,
	.h4 {
		font-size: 1.5rem
	}
}

h5,
.h5 {
	font-size: 1.25rem
}

h6,
.h6 {
	font-size: 1rem
}

p1 {
	margin-top: 0;
	margin-bottom: 1rem
}

abbr[title],
abbr[data-bs-original-title] {
	text-decoration: underline dotted;
	cursor: help;
	text-decoration-skip-ink: none
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit
}

ol,
ul {
	padding-left: 2rem
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0
}

dt {
	font-weight: 700
}

dd {
	margin-bottom: .5rem;
	margin-left: 0
}

blockquote {
	margin: 0 0 1rem
}

b,
strong {
	font-weight: bolder
}

small,
.small {
	font-size: .875em
}

mark,
.mark {
	padding: .2em;
	background-color: #fcf8e3
}

sub,
sup {
	position: relative;
	font-size: .75em;
	line-height: 0;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

a {
	color: #0d6efd;
	text-decoration: underline
}

a:hover {
	color: #0a58ca
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none
}

pre,
code,
kbd,
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: .875em
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal
}

code {
	font-size: .875em;
	color: #d63384;
	word-wrap: break-word
}

a>code {
	color: inherit
}

kbd {
	padding: .2rem .4rem;
	font-size: .875em;
	color: #fff;
	background-color: #212529;
	border-radius: .2rem
}

kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700
}

figure {
	margin: 0 0 1rem
}

img,
svg {
	vertical-align: middle
}

table {
	caption-side: bottom;
	border-collapse: collapse
}

caption {
	padding-top: .5rem;
	padding-bottom: .5rem;
	color: #6c757d;
	text-align: left
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent
}

thead,
tb0dy,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0
}

label {
	display: inline-block
}

button {
	border-radius: 0
}

button:focus:not(:focus-visible) {
	outline: 0
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button,
select {
	text-transform: none
}

[role=button] {
	cursor: pointer
}

select {
	word-wrap: normal
}

select:disabled {
	opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
	display: none
}

button,
[type=button],
[type=reset],
[type=submit] {
	-webkit-appearance: button
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
	cursor: pointer
}

::-moz-focus-inner {
	padding: 0;
	border-style: none
}

textarea {
	resize: vertical
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit
}

@media(min-width:1200px) {
	legend {
		font-size: 1.5rem
	}
}

legend+* {
	clear: left
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0
}

::-webkit-inner-spin-button {
	height: auto
}

[type=search] {
	outline-offset: -2px;
	-webkit-appearance: textfield
}

::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
	padding: 0
}

::file-selector-button {
	font: inherit
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button
}

output {
	display: inline-block
}

iframe {
	border: 0
}

summary {
	display: list-item;
	cursor: pointer
}

progress {
	vertical-align: baseline
}

[hidden] {
	display: none !important
}

.lead {
	font-size: 1.25rem;
	font-weight: 300
}

.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 300;
	line-height: 1.2
}

@media(min-width:1200px) {
	.display-1 {
		font-size: 5rem
	}
}

.display-2 {
	font-size: calc(1.575rem + 3.9vw);
	font-weight: 300;
	line-height: 1.2
}

@media(min-width:1200px) {
	.display-2 {
		font-size: 4.5rem
	}
}

.display-3 {
	font-size: calc(1.525rem + 3.3vw);
	font-weight: 300;
	line-height: 1.2
}

@media(min-width:1200px) {
	.display-3 {
		font-size: 4rem
	}
}

.display-4 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 300;
	line-height: 1.2
}

@media(min-width:1200px) {
	.display-4 {
		font-size: 3.5rem
	}
}

.display-5 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1.2
}

@media(min-width:1200px) {
	.display-5 {
		font-size: 3rem
	}
}

.display-6 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1.2
}

@media(min-width:1200px) {
	.display-6 {
		font-size: 2.5rem
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none
}

.list-inline {
	padding-left: 0;
	list-style: none
}

.list-inline-item {
	display: inline-block
}

.list-inline-item:not(:last-child) {
	margin-right: .5rem
}

.initialism {
	font-size: .875em;
	text-transform: uppercase
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem
}

.blockquote>:last-child {
	margin-bottom: 0
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: .875em;
	color: #6c757d
}

.blockquote-footer::before {
	content: "— "
}

.img-fluid {
	max-width: 100%;
	height: auto
}

.img-thumbnail {
	padding: .25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	max-width: 100%;
	height: auto
}

.figure {
	display: inline-block
}

.figure-img {
	margin-bottom: .5rem;
	line-height: 1
}

.figure-caption {
	font-size: .875em;
	color: #6c757d
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto
}

.container {
	max-width: 540px
}

.container {
	max-width: 720px
}

.container {
	max-width: 960px
}

.container {
	max-width: 1140px
}

.container {
	max-width: 1320px
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	/* margin-right: calc(-.5 * var(--bs-gutter-x)); */
	margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-top: var(--bs-gutter-y)
}

.col {
	flex: 1 0 0%
}

.row-cols-auto>* {
	flex: 0 0 auto;
	width: auto
}

.row-cols-1>* {
	flex: 0 0 auto;
	width: 100%
}

.row-cols-2>* {
	flex: 0 0 auto;
	width: 50%
}

.row-cols-3>* {
	flex: 0 0 auto;
	width: 33.3333333333%
}

.row-cols-4>* {
	flex: 0 0 auto;
	width: 25%
}

.row-cols-5>* {
	flex: 0 0 auto;
	width: 20%
}

.row-cols-6>* {
	flex: 0 0 auto;
	width: 16.6666666667%
}

.col-auto {
	flex: 0 0 auto;
	width: auto
}

.col-1 {
	flex: 0 0 auto;
	width: 8.33333333%
}

.col-2 {
	flex: 0 0 auto;
	width: 16.66666667%
}

.col-3 {
	flex: 0 0 auto;
	width: 25%
}

.col-4 {
	flex: 0 0 auto;
	width: 33.33333333%
}

.col-5 {
	flex: 0 0 auto;
	width: 41.66666667%
}

.col-6 {
	flex: 0 0 auto;
	width: 50%
}

.col-7 {
	flex: 0 0 auto;
	width: 58.33333333%
}

.col-8 {
	flex: 0 0 auto;
	width: 66.66666667%
}

.col-9 {
	flex: 0 0 auto;
	width: 75%
}

.col-10 {
	flex: 0 0 auto;
	width: 83.33333333%
}

.col-11 {
	flex: 0 0 auto;
	width: 91.66666667%
}

.col-12 {
	flex: 0 0 auto;
	width: 100%
}

.offset-1 {
	margin-left: 8.33333333%
}

.offset-2 {
	margin-left: 16.66666667%
}

.offset-3 {
	margin-left: 25%
}

.offset-4 {
	margin-left: 33.33333333%
}

.offset-5 {
	margin-left: 41.66666667%
}

.offset-6 {
	margin-left: 50%
}

.offset-7 {
	margin-left: 58.33333333%
}

.offset-8 {
	margin-left: 66.66666667%
}

.offset-9 {
	margin-left: 75%
}

.offset-10 {
	margin-left: 83.33333333%
}

.offset-11 {
	margin-left: 91.66666667%
}

.g-0,
.gx-0 {
	--bs-gutter-x: 0
}

.g-0,
.gy-0 {
	--bs-gutter-y: 0
}

.g-1,
.gx-1 {
	--bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
	--bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
	--bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
	--bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
	--bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
	--bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
	--bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
	--bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
	--bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
	--bs-gutter-y: 3rem
}

.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: rgba(0, 0, 0, 0.075);
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	vertical-align: top;
	border-color: #dee2e6
}

.table>:not(caption)>*>* {
	padding: .5rem;
	background-color: var(--bs-table-bg);
	border-bottom-width: 1px;
	box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tb0dy {
	vertical-align: inherit
}

.table>thead {
	vertical-align: bottom
}

.table>:not(:first-child) {
	border-top: 2px solid currentColor
}

.caption-top {
	caption-side: top
}

.table-sm>:not(caption)>*>* {
	padding: .25rem
}

.table-bordered>:not(caption)>* {
	border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
	border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
	border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
	border-top-width: 0
}

.table-striped>tb0dy>tr:nth-of-type(odd)>* {
	--bs-table-accent-bg: var(--bs-table-striped-bg);
	color: var(--bs-table-striped-color)
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color)
}

.table-hover>tb0dy>tr:hover>* {
	--bs-table-accent-bg: var(--bs-table-hover-bg);
	color: var(--bs-table-hover-color)
}

.table-primary {
	--bs-table-bg: #cfe2ff;
	--bs-table-striped-bg: #c5d7f2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bacbe6;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #bfd1ec;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bacbe6
}

.table-secondary {
	--bs-table-bg: #e2e3e5;
	--bs-table-striped-bg: #d7d8da;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #cbccce;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #d1d2d4;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #cbccce
}

.table-success {
	--bs-table-bg: #d1e7dd;
	--bs-table-striped-bg: #c7dbd2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bcd0c7;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #c1d6cc;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bcd0c7
}

.table-info {
	--bs-table-bg: #cff4fc;
	--bs-table-striped-bg: #c5e8ef;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #badce3;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #bfe2e9;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #badce3
}

.table-warning {
	--bs-table-bg: #fff3cd;
	--bs-table-striped-bg: #f2e7c3;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e6dbb9;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ece1be;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #e6dbb9
}

.table-danger {
	--bs-table-bg: #f8d7da;
	--bs-table-striped-bg: #eccccf;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfc2c4;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e5c7ca;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfc2c4
}

.table-light {
	--bs-table-bg: #f8f9fa;
	--bs-table-striped-bg: #ecedee;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfe0e1;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e5e6e7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfe0e1
}

.table-dark {
	--bs-table-bg: #212529;
	--bs-table-striped-bg: #2c3034;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #373b3e;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #323539;
	--bs-table-hover-color: #fff;
	color: #fff;
	border-color: #373b3e
}

.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch
}

.form-label {
	margin-bottom: .5rem
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.25rem
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: .875rem
}

.form-text {
	margin-top: .25rem;
	font-size: .875em;
	color: #6c757d
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.form-control {
		transition: none
	}
}

.form-control[type=file] {
	overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
	cursor: pointer
}

.form-control:focus {
	color: #212529;
	background-color: #fff;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-control::-webkit-date-and-time-value {
	height: 1.5em
}

.form-control::placeholder {
	color: #6c757d;
	opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1
}

.form-control::file-selector-button {
	padding: .375rem .75rem;
	margin: -.375rem -.75rem;
	margin-inline-end: .75rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.form-control::file-selector-button {
		transition: none
	}
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
	padding: .375rem .75rem;
	margin: -.375rem -.75rem;
	margin-inline-end: .75rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.form-control::-webkit-file-upload-button {
		transition: none
	}
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
	background-color: #dde0e3
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: .375rem 0;
	margin-bottom: 0;
	line-height: 1.5;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0
}

.form-control-sm {
	min-height: calc(1.5em + 0.5rem + 2px);
	padding: .25rem .5rem;
	font-size: .875rem;
	border-radius: .2rem
}

.form-control-sm::file-selector-button {
	padding: .25rem .5rem;
	margin: -.25rem -.5rem;
	margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
	padding: .25rem .5rem;
	margin: -.25rem -.5rem;
	margin-inline-end: .5rem
}

.form-control-lg {
	min-height: calc(1.5em + 1rem + 2px);
	padding: .5rem 1rem;
	font-size: 1.25rem;
	border-radius: .3rem
}

.form-control-lg::file-selector-button {
	padding: .5rem 1rem;
	margin: -.5rem -1rem;
	margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
	padding: .5rem 1rem;
	margin: -.5rem -1rem;
	margin-inline-end: 1rem
}

textarea.form-control {
	min-height: calc(1.5em + 0.75rem + 2px)
}

textarea.form-control-sm {
	min-height: calc(1.5em + 0.5rem + 2px)
}

textarea.form-control-lg {
	min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
	width: 3rem;
	height: auto;
	padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer
}

.form-control-color::-moz-color-swatch {
	height: 1.5em;
	border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
	height: 1.5em;
	border-radius: .25rem
}

.form-select {
	display: block;
	width: 100%;
	padding: .375rem 2.25rem .375rem .75rem;
	-moz-padding-start: calc(0.75rem - 3px);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+);
	background-repeat: no-repeat;
	background-position: right .75rem center;
	background-size: 16px 12px;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	appearance: none
}

@media(prefers-reduced-motion:reduce) {
	.form-select {
		transition: none
	}
}

.form-select:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
	padding-right: .75rem;
	background-image: none
}

.form-select:disabled {
	background-color: #e9ecef
}

.form-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #212529
}

.form-select-sm {
	padding-top: .25rem;
	padding-bottom: .25rem;
	padding-left: .5rem;
	font-size: .875rem;
	border-radius: .2rem
}

.form-select-lg {
	padding-top: .5rem;
	padding-bottom: .5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
	border-radius: .3rem
}

.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: .125rem
}

.form-check .form-check-input {
	float: left;
	margin-left: -1.5em
}

.form-check-input {
	width: 1em;
	height: 1em;
	margin-top: .25em;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid rgba(0, 0, 0, .25);
	appearance: none;
	color-adjust: exact
}

.form-check-input[type=checkbox] {
	border-radius: .25em
}

.form-check-input[type=radio] {
	border-radius: 50%
}

.form-check-input:active {
	filter: brightness(90%)
}

.form-check-input:focus {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-check-input:checked {
	background-color: #0d6efd;
	border-color: #0d6efd
}

.form-check-input:checked[type=checkbox] {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZmZmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBsMyAzbDYtNicvPjwvc3ZnPg==)
}

.form-check-input:checked[type=radio] {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPScyJyBmaWxsPScjZmZmJy8+PC9zdmc+)
}

.form-check-input[type=checkbox]:indeterminate {
	background-color: #0d6efd;
	border-color: #0d6efd;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZmZmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBoOCcvPjwvc3ZnPg==)
}

.form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: .5
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
	opacity: .5
}

.form-switch {
	padding-left: 2.5em
}

.form-switch .form-check-input {
	width: 2em;
	margin-left: -2.5em;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPSdyZ2JhKDAsIDAsIDAsIDAuMjUpJy8+PC9zdmc+);
	background-position: left center;
	border-radius: 2em;
	transition: background-position .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.form-switch .form-check-input {
		transition: none
	}
}

.form-switch .form-check-input:focus {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPScjODZiN2ZlJy8+PC9zdmc+)
}

.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPScjZmZmJy8+PC9zdmc+)
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
	pointer-events: none;
	filter: none;
	opacity: .65
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: transparent;
	appearance: none
}

.form-range:focus {
	outline: 0
}

.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range::-moz-focus-outer {
	border: 0
}

.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -.25rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	appearance: none
}

@media(prefers-reduced-motion:reduce) {
	.form-range::-webkit-slider-thumb {
		transition: none
	}
}

.form-range::-webkit-slider-thumb:active {
	background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem
}

.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #0d6efd;
	border: 0;
	border-radius: 1rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	appearance: none
}

@media(prefers-reduced-motion:reduce) {
	.form-range::-moz-range-thumb {
		transition: none
	}
}

.form-range::-moz-range-thumb:active {
	background-color: #b6d4fe
}

.form-range::-moz-range-track {
	width: 100%;
	height: .5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem
}

.form-range:disabled {
	pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
	background-color: #adb5bd
}

.form-floating {
	position: relative
}

.form-floating>.form-control,
.form-floating>.form-select {
	height: calc(3.5rem + 2px);
	line-height: 1.25
}

.form-floating>label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem .75rem;
	pointer-events: none;
	border: 1px solid transparent;
	transform-origin: 0 0;
	transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.form-floating>label {
		transition: none
	}
}

.form-floating>.form-control {
	padding: 1rem .75rem
}

.form-floating>.form-control::placeholder {
	color: transparent
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: .625rem
}

.form-floating>.form-select {
	padding-top: 1.625rem;
	padding-bottom: .625rem
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
	opacity: .65;
	transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
	opacity: .65;
	transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%
}

.input-group>.form-control,
.input-group>.form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
	z-index: 3
}

.input-group .btn {
	position: relative;
	z-index: 2
}

.input-group .btn:focus {
	z-index: 3
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: .25rem
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
	padding: .5rem 1rem;
	font-size: 1.25rem;
	border-radius: .3rem
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
	padding: .25rem .5rem;
	font-size: .875rem;
	border-radius: .2rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
	padding-right: 3rem
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: .875em;
	color: #198754
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .25rem .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	color: #fff;
	background-color: rgba(25, 135, 84, .9);
	border-radius: .25rem
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
	display: block
}

.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #198754;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMxOTg3NTQnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .form-select:valid,
.form-select.is-valid {
	border-color: #198754
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMxOTg3NTQnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
	background-position: right .75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
	border-color: #198754;
	box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
	border-color: #198754
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
	background-color: #198754
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
	box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
	color: #198754
}

.form-check-inline .form-check-input~.valid-feedback {
	margin-left: .5em
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
	z-index: 1
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
	z-index: 3
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: .875em;
	color: #dc3545
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .25rem .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	color: #fff;
	background-color: rgba(220, 53, 69, .9);
	border-radius: .25rem
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #dc3545;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
	border-color: #dc3545
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
	background-position: right .75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
	border-color: #dc3545
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
	background-color: #dc3545
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
	color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
	margin-left: .5em
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
	z-index: 2
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
	z-index: 3
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	border-radius: .25rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.btn {
		transition: none
	}
}

.btn:hover {
	color: #212529
}

.btn-check:focus+.btn,
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
	pointer-events: none;
	opacity: .65
}

.btn-primary {
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd
}

.btn-primary:hover {
	color: #fff;
	background-color: #0b5ed7;
	border-color: #0a58ca
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
	color: #fff;
	background-color: #0b5ed7;
	border-color: #0a58ca;
	box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #0a58ca;
	border-color: #0a53be
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-primary:disabled,
.btn-primary.disabled {
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd
}

.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d
}

.btn-secondary:hover {
	color: #fff;
	background-color: #5c636a;
	border-color: #565e64
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
	color: #fff;
	background-color: #5c636a;
	border-color: #565e64;
	box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #565e64;
	border-color: #51585e
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-secondary:disabled,
.btn-secondary.disabled {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d
}

.btn-success {
	color: #fff;
	background-color: #198754;
	border-color: #198754
}

.btn-success:hover {
	color: #fff;
	background-color: #157347;
	border-color: #146c43
}

.btn-check:focus+.btn-success,
.btn-success:focus {
	color: #fff;
	background-color: #157347;
	border-color: #146c43;
	box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: #146c43;
	border-color: #13653f
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-success:disabled,
.btn-success.disabled {
	color: #fff;
	background-color: #198754;
	border-color: #198754
}

.btn-info {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0
}

.btn-info:hover {
	color: #000;
	background-color: #31d2f2;
	border-color: #25cff2
}

.btn-check:focus+.btn-info,
.btn-info:focus {
	color: #000;
	background-color: #31d2f2;
	border-color: #25cff2;
	box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
	color: #000;
	background-color: #3dd5f3;
	border-color: #25cff2
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-info:disabled,
.btn-info.disabled {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0
}

.btn-warning {
	color: #000;
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-warning:hover {
	color: #000;
	background-color: #ffca2c;
	border-color: #ffc720
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
	color: #000;
	background-color: #ffca2c;
	border-color: #ffc720;
	box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
	color: #000;
	background-color: #ffcd39;
	border-color: #ffc720
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-warning:disabled,
.btn-warning.disabled {
	color: #000;
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-danger {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-danger:hover {
	color: #fff;
	background-color: #bb2d3b;
	border-color: #b02a37
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
	color: #fff;
	background-color: #bb2d3b;
	border-color: #b02a37;
	box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #b02a37;
	border-color: #a52834
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-danger:disabled,
.btn-danger.disabled {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-light {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-light:hover {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb
}

.btn-check:focus+.btn-light,
.btn-light:focus {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
	box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-light:disabled,
.btn-light.disabled {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-dark {
	color: #fff;
	background-color: #212529;
	border-color: #212529
}

.btn-dark:hover {
	color: #fff;
	background-color: #1c1f23;
	border-color: #1a1e21
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
	color: #fff;
	background-color: #1c1f23;
	border-color: #1a1e21;
	box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #1a1e21;
	border-color: #191c1f
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-dark:disabled,
.btn-dark.disabled {
	color: #fff;
	background-color: #212529;
	border-color: #212529
}

.btn-outline-primary {
	color: #0d6efd;
	border-color: #0d6efd
}

.btn-outline-primary:hover {
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
	color: #0d6efd;
	background-color: transparent
}

.btn-outline-secondary {
	color: #6c757d;
	border-color: #6c757d
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
	box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
	color: #6c757d;
	background-color: transparent
}

.btn-outline-success {
	color: #198754;
	border-color: #198754
}

.btn-outline-success:hover {
	color: #fff;
	background-color: #198754;
	border-color: #198754
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
	box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
	color: #fff;
	background-color: #198754;
	border-color: #198754
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
	color: #198754;
	background-color: transparent
}

.btn-outline-info {
	color: #0dcaf0;
	border-color: #0dcaf0
}

.btn-outline-info:hover {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
	box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
	color: #0dcaf0;
	background-color: transparent
}

.btn-outline-warning {
	color: #ffc107;
	border-color: #ffc107
}

.btn-outline-warning:hover {
	color: #000;
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
	box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
	color: #000;
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
	color: #ffc107;
	background-color: transparent
}

.btn-outline-danger {
	color: #dc3545;
	border-color: #dc3545
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
	color: #dc3545;
	background-color: transparent
}

.btn-outline-light {
	color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-outline-light:hover {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
	box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
	color: #f8f9fa;
	background-color: transparent
}

.btn-outline-dark {
	color: #212529;
	border-color: #212529
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #212529;
	border-color: #212529
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
	box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
	color: #fff;
	background-color: #212529;
	border-color: #212529
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
	color: #212529;
	background-color: transparent
}

.btn-link {
	font-weight: 400;
	color: #0d6efd;
	text-decoration: underline
}

.btn-link:hover {
	color: #0a58ca
}

.btn-link:disabled,
.btn-link.disabled {
	color: #6c757d
}

.btn-lg,
.btn-group-lg>.btn {
	padding: .5rem 1rem;
	font-size: 1.25rem;
	border-radius: .3rem
}

.btn-sm,
.btn-group-sm>.btn {
	padding: .25rem .5rem;
	font-size: .875rem;
	border-radius: .2rem
}

.fade {
	transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
	.fade {
		transition: none
	}
}

.fade:not(.show) {
	opacity: 0
}

.collapse:not(.show) {
	display: none
}

.collapsing {
	height: 0;
	overflow: hidden;
	transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {
	.collapsing {
		transition: none
	}
}

.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	transition: width .35s ease
}

@media(prefers-reduced-motion:reduce) {
	.collapsing.collapse-horizontal {
		transition: none
	}
}

.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative
}

.dropdown-toggle {
	white-space: nowrap
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
	margin-left: 0
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: .5rem 0;
	margin: 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: .125rem
}

.dropdown-menu-start {
	--bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
	right: auto;
	left: 0
}

.dropdown-menu-end {
	--bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
	right: 0;
	left: auto
}

.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: 0;
	border-right: .3em solid transparent;
	border-bottom: .3em solid;
	border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: .125rem
}

.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: 0;
	border-bottom: .3em solid transparent;
	border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropend .dropdown-toggle::after {
	vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: ""
}

.dropstart .dropdown-toggle::after {
	display: none
}

.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid transparent;
	border-right: .3em solid;
	border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
	margin-left: 0
}

.dropstart .dropdown-toggle::before {
	vertical-align: 0
}

.dropdown-divider {
	height: 0;
	margin: .5rem 0;
	overflow: hidden;
	border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: .25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #1e2125;
	background-color: #e9ecef
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #0d6efd
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: transparent
}

.dropdown-menu.show {
	display: block
}

.dropdown-he4der {
	display: block;
	padding: .5rem 1rem;
	margin-bottom: 0;
	font-size: .875rem;
	color: #6c757d;
	white-space: nowrap
}

.dropdown-item-text {
	display: block;
	padding: .25rem 1rem;
	color: #212529
}

.dropdown-menu-dark {
	color: #dee2e6;
	background-color: #343a40;
	border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
	color: #dee2e6
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
	color: #fff;
	background-color: #0d6efd
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
	color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
	color: #dee2e6
}

.dropdown-menu-dark .dropdown-he4der {
	color: #adb5bd
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	flex: 1 1 auto
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
	z-index: 1
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start
}

.btn-toolbar .input-group {
	width: auto
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
	margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.dropdown-toggle-split {
	padding-right: .5625rem;
	padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
	margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
	margin-right: 0
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
	padding-right: .375rem;
	padding-left: .375rem
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
	padding-right: .75rem;
	padding-left: .75rem
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
	width: 100%
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
	margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.nav-link {
	display: block;
	padding: .5rem 1rem;
	color: #0d6efd;
	text-decoration: none;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.nav-link {
		transition: none
	}
}

.nav-link:hover,
.nav-link:focus {
	color: #0a58ca
}

.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
	margin-bottom: -1px;
	background: 0 0;
	border: 1px solid transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
	isolation: isolate
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0
}

.nav-pills .nav-link {
	background: 0 0;
	border: 0;
	border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #fff;
	background-color: #0d6efd
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	width: 100%
}

.tab-content>.tab-pane {
	display: none
}

.tab-content>.active {
	display: block
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: .5rem;
	padding-bottom: .5rem
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between
}

.navbar-brand {
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0
}

.navbar-nav .dropdown-menu {
	position: static
}

.navbar-text {
	padding-top: .5rem;
	padding-bottom: .5rem
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center
}

.navbar-toggler {
	padding: .25rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: .25rem;
	transition: box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.navbar-toggler {
		transition: none
	}
}

.navbar-toggler:hover {
	text-decoration: none
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto
}

.navbar-expand {
	flex-wrap: nowrap;
	justify-content: flex-start
}

.navbar-expand .navbar-nav {
	flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: .5rem;
	padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
	overflow: visible
}

.navbar-expand .navbar-collapse {
	display: flex !important;
	flex-basis: auto
}

.navbar-expand .navbar-toggler {
	display: none
}

.navbar-expand .offcanvas-he4der {
	display: none
}

.navbar-expand .offcanvas {
	position: inherit;
	bottom: 0;
	z-index: 1000;
	flex-grow: 1;
	visibility: visible !important;
	background-color: transparent;
	border-right: 0;
	border-left: 0;
	transition: none;
	transform: none
}

.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
	height: auto;
	border-top: 0;
	border-bottom: 0
}

.navbar-expand .offcanvas-b0dy {
	display: flex;
	flex-grow: 0;
	padding: 0;
	overflow-y: visible
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, .55);
	border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMCAzMCc+PHBhdGggc3Ryb2tlPSdyZ2JhKDAsIDAsIDAsIDAuNTUpJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1taXRlcmxpbWl0PScxMCcgc3Ryb2tlLXdpZHRoPScyJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==)
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
	color: #fff
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, .55);
	border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMCAzMCc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNTUpJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1taXRlcmxpbWl0PScxMCcgc3Ryb2tlLXdpZHRoPScyJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==)
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: #fff
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: .25rem
}

.card>hr {
	margin-right: 0;
	margin-left: 0
}

.card>.list-group {
	border-top: inherit;
	border-bottom: inherit
}

.card>.list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px)
}

.card>.list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px)
}

.card>.card-he4der+.list-group,
.card>.list-group+.card-footer {
	border-top: 0
}

.card-b0dy {
	flex: 1 1 auto;
	padding: 1rem
}

.card-title {
	margin-bottom: .5rem
}

.card-subtitle {
	margin-top: -.25rem;
	margin-bottom: 0
}

.card-text:last-child {
	margin-bottom: 0
}

.card-link+.card-link {
	margin-left: 1rem
}

.card-he4der {
	padding: .5rem 1rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, .03);
	border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-he4der:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.card-footer {
	padding: .5rem 1rem;
	background-color: rgba(0, 0, 0, .03);
	border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}

.card-he4der-tabs {
	margin-right: -.5rem;
	margin-bottom: -.5rem;
	margin-left: -.5rem;
	border-bottom: 0
}

.card-he4der-pills {
	margin-right: -.5rem;
	margin-left: -.5rem
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	border-radius: calc(0.25rem - 1px)
}

.card-img,
.card-img-top,
.card-img-bottom {
	width: 100%
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px)
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px)
}

.card-group {
	display: flex;
	flex-flow: row wrap
}

.card-group>.card {
	margin-bottom: .75rem
}

.card-group>.card {
	flex: 1 0 0%;
	margin-bottom: 0
}

.card-group>.card+.card {
	margin-left: 0;
	border-left: 0
}

.card-group>.card:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0
}

.card-group>.card:not(:last-child) .card-img-top,
.card-group>.card:not(:last-child) .card-he4der {
	border-top-right-radius: 0
}

.card-group>.card:not(:last-child) .card-img-bottom,
.card-group>.card:not(:last-child) .card-footer {
	border-bottom-right-radius: 0
}

.card-group>.card:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0
}

.card-group>.card:not(:first-child) .card-img-top,
.card-group>.card:not(:first-child) .card-he4der {
	border-top-left-radius: 0
}

.card-group>.card:not(:first-child) .card-img-bottom,
.card-group>.card:not(:first-child) .card-footer {
	border-bottom-left-radius: 0
}

.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media(prefers-reduced-motion:reduce) {
	.accordion-button {
		transition: none
	}
}

.accordion-button:not(.collapsed) {
	color: #0c63e4;
	background-color: #e7f1ff;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed)::after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzBjNjNlNCc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+);
	transform: rotate(-180deg)
}

.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzIxMjUyOSc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+);
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform .2s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.accordion-button::after {
		transition: none
	}
}

.accordion-button:hover {
	z-index: 2
}

.accordion-button:focus {
	z-index: 3;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.accordion-he4der {
	margin-bottom: 0
}

.accordion-item {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
	border-top: 0
}

.accordion-item:last-of-type {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.accordion-b0dy {
	padding: 1rem 1.25rem
}

.accordion-flush .accordion-collapse {
	border-width: 0
}

.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0
}

.accordion-flush .accordion-item:first-child {
	border-top: 0
}

.accordion-flush .accordion-item:last-child {
	border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
	border-radius: 0
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin-bottom: 1rem;
	list-style: none
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
	float: left;
	padding-right: .5rem;
	color: #6c757d;
	content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
	color: #6c757d
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none
}

.page-link {
	position: relative;
	display: block;
	color: #0d6efd;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #dee2e6;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.page-link {
		transition: none
	}
}

.page-link:hover {
	z-index: 2;
	color: #0a58ca;
	background-color: #e9ecef;
	border-color: #dee2e6
}

.page-link:focus {
	z-index: 3;
	color: #0a58ca;
	background-color: #e9ecef;
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6
}

.page-link {
	padding: .375rem .75rem
}

.page-item:first-child .page-link {
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
	padding: .75rem 1.5rem;
	font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: .3rem;
	border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: .3rem;
	border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
	padding: .25rem .5rem;
	font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: .2rem;
	border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: .2rem;
	border-bottom-right-radius: .2rem
}

.badge {
	display: inline-block;
	padding: .35em .65em;
	font-size: .75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25rem
}

.badge:empty {
	display: none
}

.btn .badge {
	position: relative;
	top: -1px
}

.alert {
	position: relative;
	padding: 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem
}

.alert-heading {
	color: inherit
}

.alert-link {
	font-weight: 700
}

.alert-dismissible {
	padding-right: 3rem
}

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem
}

.alert-primary {
	color: #084298;
	background-color: #cfe2ff;
	border-color: #b6d4fe
}

.alert-primary .alert-link {
	color: #06357a
}

.alert-secondary {
	color: #41464b;
	background-color: #e2e3e5;
	border-color: #d3d6d8
}

.alert-secondary .alert-link {
	color: #34383c
}

.alert-success {
	color: #0f5132;
	background-color: #d1e7dd;
	border-color: #badbcc
}

.alert-success .alert-link {
	color: #0c4128
}

.alert-info {
	color: #055160;
	background-color: #cff4fc;
	border-color: #b6effb
}

.alert-info .alert-link {
	color: #04414d
}

.alert-warning {
	color: #664d03;
	background-color: #fff3cd;
	border-color: #ffecb5
}

.alert-warning .alert-link {
	color: #523e02
}

.alert-danger {
	color: #842029;
	background-color: #f8d7da;
	border-color: #f5c2c7
}

.alert-danger .alert-link {
	color: #6a1a21
}

.alert-light {
	color: #636464;
	background-color: #fefefe;
	border-color: #fdfdfe
}

.alert-light .alert-link {
	color: #4f5050
}

.alert-dark {
	color: #141619;
	background-color: #d3d3d4;
	border-color: #bcbebf
}

.alert-dark .alert-link {
	color: #101214
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem
	}
}

.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: .75rem;
	background-color: #e9ecef;
	border-radius: .25rem
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #0d6efd;
	transition: width .6s ease
}

@media(prefers-reduced-motion:reduce) {
	.progress-bar {
		transition: none
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem
}

.progress-bar-animated {
	animation: 1s linear infinite progress-bar-stripes
}

@media(prefers-reduced-motion:reduce) {
	.progress-bar-animated {
		animation: none
	}
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: .25rem
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section
}

.list-group-numbered>li::before {
	content: counters(section, ".") ". ";
	counter-increment: section
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa
}

.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef
}

.list-group-item {
	position: relative;
	display: block;
	padding: .5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #0d6efd;
	border-color: #0d6efd
}

.list-group-item+.list-group-item {
	border-top-width: 0
}

.list-group-item+.list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px
}

.list-group-horizontal {
	flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
	border-bottom-left-radius: .25rem;
	border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
	border-top-right-radius: .25rem;
	border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
	margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
	border-top-width: 1px;
	border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px
}

.list-group-flush {
	border-radius: 0
}

.list-group-flush>.list-group-item {
	border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
	border-bottom-width: 0
}

.list-group-item-primary {
	color: #084298;
	background-color: #cfe2ff
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #084298;
	background-color: #bacbe6
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #084298;
	border-color: #084298
}

.list-group-item-secondary {
	color: #41464b;
	background-color: #e2e3e5
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #41464b;
	background-color: #cbccce
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #41464b;
	border-color: #41464b
}

.list-group-item-success {
	color: #0f5132;
	background-color: #d1e7dd
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #0f5132;
	background-color: #bcd0c7
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #0f5132;
	border-color: #0f5132
}

.list-group-item-info {
	color: #055160;
	background-color: #cff4fc
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: #055160;
	background-color: #badce3
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #055160;
	border-color: #055160
}

.list-group-item-warning {
	color: #664d03;
	background-color: #fff3cd
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: #664d03;
	background-color: #e6dbb9
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #664d03;
	border-color: #664d03
}

.list-group-item-danger {
	color: #842029;
	background-color: #f8d7da
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #842029;
	background-color: #dfc2c4
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #842029;
	border-color: #842029
}

.list-group-item-light {
	color: #636464;
	background-color: #fefefe
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #636464;
	background-color: #e5e5e5
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #636464;
	border-color: #636464
}

.list-group-item-dark {
	color: #141619;
	background-color: #d3d3d4
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #141619;
	background-color: #bebebf
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #141619;
	border-color: #141619
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: .25em;
	color: #000;
	background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
	border: 0;
	border-radius: .25rem;
	opacity: .5
}

.btn-close:hover {
	color: #000;
	text-decoration: none;
	opacity: .75
}

.btn-close:focus {
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
	opacity: 1
}

.btn-close:disabled,
.btn-close.disabled {
	pointer-events: none;
	user-select: none;
	opacity: .25
}

.btn-close-white {
	filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: .875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, .85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .1);
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
	border-radius: .25rem
}

.toast.showing {
	opacity: 0
}

.toast:not(.show) {
	display: none
}

.toast-container {
	width: max-content;
	max-width: 100%;
	pointer-events: none
}

.toast-container>:not(:last-child) {
	margin-bottom: .75rem
}

.toast-he4der {
	display: flex;
	align-items: center;
	padding: .5rem .75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, .85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, .05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px)
}

.toast-he4der .btn-close {
	margin-right: -.375rem;
	margin-left: .75rem
}

.toast-b0dy {
	padding: .75rem;
	word-wrap: break-word
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: .5rem;
	pointer-events: none
}

.modal.fade .modal-dialog {
	transition: transform .3s ease-out;
	transform: translate(0, -50px)
}

@media(prefers-reduced-motion:reduce) {
	.modal.fade .modal-dialog {
		transition: none
	}
}

.modal.show .modal-dialog {
	transform: none
}

.modal.modal-static .modal-dialog {
	transform: scale(1.02)
}

.modal-dialog-scrollable {
	height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
	max-height: 100%;
	overflow: hidden
}

.modal-dialog-scrollable .modal-b0dy {
	overflow-y: auto
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem)
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: .3rem;
	outline: 0
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100vw;
	height: 100vh;
	background-color: #000
}

.modal-backdrop.fade {
	opacity: 0
}

.modal-backdrop.show {
	opacity: .5
}

.modal-he4der {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px)
}

.modal-he4der .btn-close {
	padding: .5rem;
	margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5
}

.modal-b0dy {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: .75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px)
}

.modal-footer>* {
	margin: .25rem
}

.modal-dialog {
	max-width: 500px;
	margin: 1.75rem auto
}

.modal-dialog-scrollable {
	height: calc(100% - 3.5rem)
}

.modal-dialog-centered {
	min-height: calc(100% - 3.5rem)
}

.modal-sm {
	max-width: 300px
}

.modal-lg,
.modal-xl {
	max-width: 800px
}

.modal-xl {
	max-width: 1140px
}

.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0
}

.modal-fullscreen .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0
}

.modal-fullscreen .modal-he4der {
	border-radius: 0
}

.modal-fullscreen .modal-b0dy {
	overflow-y: auto
}

.modal-fullscreen .modal-footer {
	border-radius: 0
}

.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	opacity: 0
}

.tooltip.show {
	opacity: .9
}

.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: .8rem;
	height: .4rem
}

.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
	padding: .4rem 0
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
	bottom: 0
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
	top: -1px;
	border-width: .4rem .4rem 0;
	border-top-color: #000
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^=right] {
	padding: 0 .4rem
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
	left: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
	right: -1px;
	border-width: .4rem .4rem .4rem 0;
	border-right-color: #000
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
	padding: .4rem 0
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
	top: 0
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 .4rem .4rem;
	border-bottom-color: #000
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^=left] {
	padding: 0 .4rem
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
	right: 0;
	width: .4rem;
	height: .8rem
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
	left: -1px;
	border-width: .4rem 0 .4rem .4rem;
	border-left-color: #000
}

.tooltip-inner {
	max-width: 200px;
	padding: .25rem .5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: .25rem
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1070;
	display: block;
	max-width: 276px;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: .3rem
}

.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: .5rem
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
	bottom: calc(-0.5rem - 1px)
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
	bottom: 0;
	border-width: .5rem .5rem 0;
	border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
	bottom: 1px;
	border-width: .5rem .5rem 0;
	border-top-color: #fff
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
	left: calc(-0.5rem - 1px);
	width: .5rem;
	height: 1rem
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
	left: 0;
	border-width: .5rem .5rem .5rem 0;
	border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
	left: 1px;
	border-width: .5rem .5rem .5rem 0;
	border-right-color: #fff
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
	top: calc(-0.5rem - 1px)
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
	top: 0;
	border-width: 0 .5rem .5rem;
	border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
	top: 1px;
	border-width: 0 .5rem .5rem;
	border-bottom-color: #fff
}

.bs-popover-bottom .popover-he4der::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-he4der::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
	right: calc(-0.5rem - 1px);
	width: .5rem;
	height: 1rem
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
	right: 0;
	border-width: .5rem 0 .5rem .5rem;
	border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
	right: 1px;
	border-width: .5rem 0 .5rem .5rem;
	border-left-color: #fff
}

.popover-he4der {
	padding: .5rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f0f0f0;
	border-bottom: 1px solid rgba(0, 0, 0, .2);
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px)
}

.popover-he4der:empty {
	display: none
}

.popover-b0dy {
	padding: 1rem;
	color: #212529
}

.carousel {
	position: relative
}

.carousel.pointer-event {
	touch-action: pan-y
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden
}

.carousel-inner::after {
	display: block;
	clear: both;
	content: ""
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	backface-visibility: hidden;
	transition: transform .6s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
	.carousel-item {
		transition: none
	}
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
	transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
	transform: translateX(-100%)
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-property: opacity;
	transform: none
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
	z-index: 1;
	opacity: 1
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s .6s
}

@media(prefers-reduced-motion:reduce) {

	.carousel-fade .active.carousel-item-start,
	.carousel-fade .active.carousel-item-end {
		transition: none
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: 0 0;
	border: 0;
	opacity: .5;
	transition: opacity .15s ease
}

@media(prefers-reduced-motion:reduce) {

	.carousel-control-prev,
	.carousel-control-next {
		transition: none
	}
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: .9
}

.carousel-control-prev {
	left: 0
}

.carousel-control-next {
	right: 0
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%
}

.carousel-control-prev-icon {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTExLjM1NCAxLjY0NmEuNS41IDAgMCAxIDAgLjcwOEw1LjcwNyA4bDUuNjQ3IDUuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMCAxIDAtLjcwOGw2LTZhLjUuNSAwIDAgMSAuNzA4IDB6Jy8+PC9zdmc+)
}

.carousel-control-next-icon {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTQuNjQ2IDEuNjQ2YS41LjUgMCAwIDEgLjcwOCAwbDYgNmEuNS41IDAgMCAxIDAgLjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOC0uNzA4TDEwLjI5MyA4IDQuNjQ2IDIuMzU0YS41LjUgMCAwIDEgMC0uNzA4eicvPjwvc3ZnPg==)
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none
}

.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: .5;
	transition: opacity .6s ease
}

@media(prefers-reduced-motion:reduce) {
	.carousel-indicators [data-bs-target] {
		transition: none
	}
}

.carousel-indicators .active {
	opacity: 1
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
	filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000
}

.carousel-dark .carousel-caption {
	color: #000
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg)
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -.125em;
	border: .25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: .2em
}

@keyframes spinner-grow {
	0% {
		transform: scale(0)
	}

	50% {
		opacity: 1;
		transform: none
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem
}

@media(prefers-reduced-motion:reduce) {

	.spinner-border,
	.spinner-grow {
		animation-duration: 1.5s
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: ""
}

.link-primary {
	color: #0d6efd
}

.link-primary:hover,
.link-primary:focus {
	color: #0a58ca
}

.link-secondary {
	color: #6c757d
}

.link-secondary:hover,
.link-secondary:focus {
	color: #565e64
}

.link-success {
	color: #198754
}

.link-success:hover,
.link-success:focus {
	color: #146c43
}

.link-info {
	color: #0dcaf0
}

.link-info:hover,
.link-info:focus {
	color: #3dd5f3
}

.link-warning {
	color: #ffc107
}

.link-warning:hover,
.link-warning:focus {
	color: #ffcd39
}

.link-danger {
	color: #dc3545
}

.link-danger:hover,
.link-danger:focus {
	color: #b02a37
}

.link-light {
	color: #f8f9fa
}

.link-light:hover,
.link-light:focus {
	color: #f9fafb
}

.link-dark {
	color: #212529
}

.link-dark:hover,
.link-dark:focus {
	color: #1a1e21
}

.ratio {
	position: relative;
	width: 100%
}

.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: ""
}

.ratio>* {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%
}

.ratio-4x3 {
	--bs-aspect-ratio: calc(3 / 4 * 100%)
}

.ratio-16x9 {
	--bs-aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-21x9 {
	--bs-aspect-ratio: calc(9 / 21 * 100%)
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030
}

.sticky-top {
	position: sticky;
	top: 0;
	z-index: 1020
}

.hstack {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: stretch
}

.vstack {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: ""
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.vr {
	display: inline-block;
	align-self: stretch;
	width: 1px;
	min-height: 1em;
	background-color: currentColor;
	opacity: .25
}

.align-baseline {
	vertical-align: baseline !important
}

.align-top {
	vertical-align: top !important
}

.align-middle {
	vertical-align: middle !important
}

.align-bottom {
	vertical-align: bottom !important
}

.align-text-bottom {
	vertical-align: text-bottom !important
}

.align-text-top {
	vertical-align: text-top !important
}

.float-start {
	float: left !important
}

.float-end {
	float: right !important
}

.float-none {
	float: none !important
}

.opacity-0 {
	opacity: 0 !important
}

.opacity-25 {
	opacity: .25 !important
}

.opacity-50 {
	opacity: .5 !important
}

.opacity-75 {
	opacity: .75 !important
}

.opacity-100 {
	opacity: 1 !important
}

.overflow-auto {
	overflow: auto !important
}

.overflow-hidden {
	overflow: hidden !important
}

.overflow-visible {
	overflow: visible !important
}

.overflow-scroll {
	overflow: scroll !important
}

.d-inline {
	display: inline !important
}

.d-inline-block {
	display: inline-block !important
}

.d-block {
	display: block !important
}

.d-grid {
	display: grid !important
}

.d-table {
	display: table !important
}

.d-table-row {
	display: table-row !important
}

.d-table-cell {
	display: table-cell !important
}

.d-flex {
	display: flex !important
}

.d-inline-flex {
	display: inline-flex !important
}

.d-none {
	display: none !important
}

.shadow {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
	box-shadow: none !important
}

.position-static {
	position: static !important
}

.position-relative {
	position: relative !important
}

.position-absolute {
	position: absolute !important
}

.position-fixed {
	position: fixed !important
}

.position-sticky {
	position: sticky !important
}

.top-0 {
	top: 0 !important
}

.top-50 {
	top: 50% !important
}

.top-100 {
	top: 100% !important
}

.bottom-0 {
	bottom: 0 !important
}

.bottom-50 {
	bottom: 50% !important
}

.bottom-100 {
	bottom: 100% !important
}

.start-0 {
	left: 0 !important
}

.start-50 {
	left: 50% !important
}

.start-100 {
	left: 100% !important
}

.end-0 {
	right: 0 !important
}

.end-50 {
	right: 50% !important
}

.end-100 {
	right: 100% !important
}

.translate-middle {
	transform: translate(-50%, -50%) !important
}

.translate-middle-x {
	transform: translateX(-50%) !important
}

.translate-middle-y {
	transform: translateY(-50%) !important
}

.border {
	border: 1px solid #dee2e6 !important
}

.border-0 {
	border: 0 !important
}

.border-top {
	border-top: 1px solid #dee2e6 !important
}

.border-top-0 {
	border-top: 0 !important
}

.border-end {
	border-right: 1px solid #dee2e6 !important
}

.border-end-0 {
	border-right: 0 !important
}

.border-bottom {
	border-bottom: 1px solid #dee2e6 !important
}

.border-bottom-0 {
	border-bottom: 0 !important
}

.border-start {
	border-left: 1px solid #dee2e6 !important
}

.border-start-0 {
	border-left: 0 !important
}

.border-primary {
	border-color: #0d6efd !important
}

.border-secondary {
	border-color: #6c757d !important
}

.border-success {
	border-color: #198754 !important
}

.border-info {
	border-color: #0dcaf0 !important
}

.border-warning {
	border-color: #ffc107 !important
}

.border-danger {
	border-color: #dc3545 !important
}

.border-light {
	border-color: #f8f9fa !important
}

.border-dark {
	border-color: #212529 !important
}

.border-white {
	border-color: #fff !important
}

.border-1 {
	border-width: 1px !important
}

.border-2 {
	border-width: 2px !important
}

.border-3 {
	border-width: 3px !important
}

.border-4 {
	border-width: 4px !important
}

.border-5 {
	border-width: 5px !important
}

.w-25 {
	width: 25% !important
}

.w-50 {
	width: 50% !important
}

.w-75 {
	width: 75% !important
}

.w-100 {
	width: 100% !important
}

.w-auto {
	width: auto !important
}

.mw-100 {
	max-width: 100% !important
}

.vw-100 {
	width: 100vw !important
}

.min-vw-100 {
	min-width: 100vw !important
}

.h-25 {
	height: 25% !important
}

.h-50 {
	height: 50% !important
}

.h-75 {
	height: 75% !important
}

.h-100 {
	height: 100% !important
}

.h-auto {
	height: auto !important
}

.mh-100 {
	max-height: 100% !important
}

.vh-100 {
	height: 100vh !important
}

.min-vh-100 {
	min-height: 100vh !important
}

.flex-fill {
	flex: 1 1 auto !important
}

.flex-row {
	flex-direction: row !important
}

.flex-column {
	flex-direction: column !important
}

.flex-row-reverse {
	flex-direction: row-reverse !important
}

.flex-column-reverse {
	flex-direction: column-reverse !important
}

.flex-grow-0 {
	flex-grow: 0 !important
}

.flex-grow-1 {
	flex-grow: 1 !important
}

.flex-shrink-0 {
	flex-shrink: 0 !important
}

.flex-shrink-1 {
	flex-shrink: 1 !important
}

.flex-wrap {
	flex-wrap: wrap !important
}

.flex-nowrap {
	flex-wrap: nowrap !important
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important
}

.gap-0 {
	gap: 0 !important
}

.gap-1 {
	gap: .25rem !important
}

.gap-2 {
	gap: .5rem !important
}

.gap-3 {
	gap: 1rem !important
}

.gap-4 {
	gap: 1.5rem !important
}

.gap-5 {
	gap: 3rem !important
}

.justify-content-start {
	justify-content: flex-start !important
}

.justify-content-end {
	justify-content: flex-end !important
}

.justify-content-center {
	justify-content: center !important
}

.justify-content-between {
	justify-content: space-between !important
}

.justify-content-around {
	justify-content: space-around !important
}

.justify-content-evenly {
	justify-content: space-evenly !important
}

.align-items-start {
	align-items: flex-start !important
}

.align-items-end {
	align-items: flex-end !important
}

.align-items-center {
	align-items: center !important
}

.align-items-baseline {
	align-items: baseline !important
}

.align-items-stretch {
	align-items: stretch !important
}

.align-content-start {
	align-content: flex-start !important
}

.align-content-end {
	align-content: flex-end !important
}

.align-content-center {
	align-content: center !important
}

.align-content-between {
	align-content: space-between !important
}

.align-content-around {
	align-content: space-around !important
}

.align-content-stretch {
	align-content: stretch !important
}

.align-self-auto {
	align-self: auto !important
}

.align-self-start {
	align-self: flex-start !important
}

.align-self-end {
	align-self: flex-end !important
}

.align-self-center {
	align-self: center !important
}

.align-self-baseline {
	align-self: baseline !important
}

.align-self-stretch {
	align-self: stretch !important
}

.order-first {
	order: -1 !important
}

.order-0 {
	order: 0 !important
}

.order-1 {
	order: 1 !important
}

.order-2 {
	order: 2 !important
}

.order-3 {
	order: 3 !important
}

.order-4 {
	order: 4 !important
}

.order-5 {
	order: 5 !important
}

.order-last {
	order: 6 !important
}

.m-0 {
	margin: 0 !important
}

.m-1 {
	margin: .25rem !important
}

.m-2 {
	margin: .5rem !important
}

.m-3 {
	margin: 1rem !important
}

.m-4 {
	margin: 1.5rem !important
}

.m-5 {
	margin: 3rem !important
}

.m-auto {
	margin: auto !important
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important
}

.mx-1 {
	margin-right: .25rem !important;
	margin-left: .25rem !important
}

.mx-2 {
	margin-right: .5rem !important;
	margin-left: .5rem !important
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important
}

.my-1 {
	margin-top: .25rem !important;
	margin-bottom: .25rem !important
}

.my-2 {
	margin-top: .5rem !important;
	margin-bottom: .5rem !important
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important
}

.mt-0 {
	margin-top: 0 !important
}

.mt-1 {
	margin-top: .25rem !important
}

.mt-2 {
	margin-top: .5rem !important
}

.mt-3 {
	margin-top: 1rem !important
}

.mt-4 {
	margin-top: 1.5rem !important
}

.mt-5 {
	margin-top: 3rem !important
}

.mt-auto {
	margin-top: auto !important
}

.me-0 {
	margin-right: 0 !important
}

.me-1 {
	margin-right: .25rem !important
}

.me-2 {
	margin-right: .5rem !important
}

.me-3 {
	margin-right: 1rem !important
}

.me-4 {
	margin-right: 1.5rem !important
}

.me-5 {
	margin-right: 3rem !important
}

.me-auto {
	margin-right: auto !important
}

.mb-0 {
	margin-bottom: 0 !important
}

.mb-1 {
	margin-bottom: .25rem !important
}

.mb-2 {
	margin-bottom: .5rem !important
}

.mb-3 {
	margin-bottom: 1rem !important
}

.mb-4 {
	margin-bottom: 1.5rem !important
}

.mb-5 {
	margin-bottom: 3rem !important
}

.mb-auto {
	margin-bottom: auto !important
}

.ms-0 {
	margin-left: 0 !important
}

.ms-1 {
	margin-left: .25rem !important
}

.ms-2 {
	margin-left: .5rem !important
}

.ms-3 {
	margin-left: 15%;
}


.ms-4 {
	margin-left: 1.5rem !important
}

.ms-5 {
	margin-left: 3rem !important
}

.ms-auto {
	margin-left: auto !important
}

.m-n1 {
	margin: -.25rem !important
}

.m-n2 {
	margin: -.5rem !important
}

.m-n3 {
	margin: -1rem !important
}

.m-n4 {
	margin: -1.5rem !important
}

.m-n5 {
	margin: -3rem !important
}

.mx-n1 {
	margin-right: -.25rem !important;
	margin-left: -.25rem !important
}

.mx-n2 {
	margin-right: -.5rem !important;
	margin-left: -.5rem !important
}

.mx-n3 {
	margin-right: -1rem !important;
	margin-left: -1rem !important
}

.mx-n4 {
	margin-right: -1.5rem !important;
	margin-left: -1.5rem !important
}

.mx-n5 {
	margin-right: -3rem !important;
	margin-left: -3rem !important
}

.my-n1 {
	margin-top: -.25rem !important;
	margin-bottom: -.25rem !important
}

.my-n2 {
	margin-top: -.5rem !important;
	margin-bottom: -.5rem !important
}

.my-n3 {
	margin-top: -1rem !important;
	margin-bottom: -1rem !important
}

.my-n4 {
	margin-top: -1.5rem !important;
	margin-bottom: -1.5rem !important
}

.my-n5 {
	margin-top: -3rem !important;
	margin-bottom: -3rem !important
}

.mt-n1 {
	margin-top: -.25rem !important
}

.mt-n2 {
	margin-top: -.5rem !important
}

.mt-n3 {
	margin-top: -1rem !important
}

.mt-n4 {
	margin-top: -1.5rem !important
}

.mt-n5 {
	margin-top: -3rem !important
}

.me-n1 {
	margin-right: -.25rem !important
}

.me-n2 {
	margin-right: -.5rem !important
}

.me-n3 {
	margin-right: -1rem !important
}

.me-n4 {
	margin-right: -1.5rem !important
}

.me-n5 {
	margin-right: -3rem !important
}

.mb-n1 {
	margin-bottom: -.25rem !important
}

.mb-n2 {
	margin-bottom: -.5rem !important
}

.mb-n3 {
	margin-bottom: -1rem !important
}

.mb-n4 {
	margin-bottom: -1.5rem !important
}

.mb-n5 {
	margin-bottom: -3rem !important
}

.ms-n1 {
	margin-left: -.25rem !important
}

.ms-n2 {
	margin-left: -.5rem !important
}

.ms-n3 {
	margin-left: -1rem !important
}

.ms-n4 {
	margin-left: -1.5rem !important
}

.ms-n5 {
	margin-left: -3rem !important
}

.p-0 {
	padding: 0 !important
}

.p-1 {
	padding: .25rem !important
}

.p-2 {
	padding: .5rem !important
}

.p-3 {
	padding: 1rem !important
}

.p-4 {
	padding: 1.5rem !important
}

.p-5 {
	padding: 3rem !important
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important
}

.px-1 {
	padding-right: .25rem !important;
	padding-left: .25rem !important
}

.px-2 {
	padding-right: .5rem !important;
	padding-left: .5rem !important
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important
}

.py-1 {
	padding-top: .25rem !important;
	padding-bottom: .25rem !important
}

.py-2 {
	padding-top: .5rem !important;
	padding-bottom: .5rem !important
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important
}

.pt-0 {
	padding-top: 0 !important
}

.pt-1 {
	padding-top: .25rem !important
}

.pt-2 {
	padding-top: .5rem !important
}

.pt-3 {
	padding-top: 1rem !important
}

.pt-4 {
	padding-top: 1.5rem !important
}

.pt-5 {
	padding-top: 3rem !important
}

.pe-0 {
	padding-right: 0 !important
}

.pe-1 {
	padding-right: .25rem !important
}

.pe-2 {
	padding-right: .5rem !important
}

.pe-3 {
	padding-right: 1rem !important
}

.pe-4 {
	padding-right: 1.5rem !important
}

.pe-5 {
	padding-right: 3rem !important
}

.pb-0 {
	padding-bottom: 0 !important
}

.pb-1 {
	padding-bottom: .25rem !important
}

.pb-2 {
	padding-bottom: .5rem !important
}

.pb-3 {
	padding-bottom: 1rem !important
}

.pb-4 {
	padding-bottom: 1.5rem !important
}

.pb-5 {
	padding-bottom: 3rem !important
}

.ps-0 {
	padding-left: 0 !important
}

.ps-1 {
	padding-left: .25rem !important
}

.ps-2 {
	padding-left: .5rem !important
}

.ps-3 {
	padding-left: 1rem !important
}

.ps-4 {
	padding-left: 1.5rem !important
}

.ps-5 {
	padding-left: 3rem !important
}

.font-monospace {
	font-family: var(--bs-font-monospace) !important
}

.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important
}

.fs-3 {
	font-size: calc(1.3rem + 0.6vw) !important
}

.fs-4 {
	font-size: calc(1.275rem + 0.3vw) !important
}

.fs-5 {
	font-size: 1.25rem !important
}

.fs-6 {
	font-size: 1rem !important
}

.fst-italic {
	font-style: italic !important
}

.fst-normal {
	font-style: normal !important
}

.fw-light {
	font-weight: 300 !important
}

.fw-lighter {
	font-weight: lighter !important
}

.fw-normal {
	font-weight: 400 !important
}

.fw-bold {
	font-weight: 700 !important
}

.fw-bolder {
	font-weight: bolder !important
}

.lh-1 {
	line-height: 1 !important
}

.lh-sm {
	line-height: 1.25 !important
}

.lh-base {
	line-height: 1.5 !important
}

.lh-lg {
	line-height: 2 !important
}

.text-start {
	text-align: left !important
}

.text-end {
	text-align: right !important
}

.text-center {
	text-align: center !important;
}

.starcenter {
	text-align: center !important;
	margin-top: 3%
}

.text-decoration-none {
	text-decoration: none !important
}

.text-decoration-underline {
	text-decoration: underline !important
}

.text-decoration-line-through {
	text-decoration: line-through !important
}

.text-lowercase {
	text-transform: lowercase !important
}

.text-uppercase {
	text-transform: uppercase !important
}

.text-capitalize {
	text-transform: capitalize !important
}

.text-wrap {
	white-space: normal !important
}

.text-nowrap {
	white-space: nowrap !important
}

.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important
}

.text-primary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-b0dy {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-b0dy-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
	--bs-text-opacity: 1;
	color: #6c757d !important
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, .5) !important
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit !important
}

.text-opacity-25 {
	--bs-text-opacity: 0.25
}

.text-opacity-50 {
	--bs-text-opacity: 0.5
}

.text-opacity-75 {
	--bs-text-opacity: 0.75
}

.text-opacity-100 {
	--bs-text-opacity: 1
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-b0dy {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-b0dy-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: transparent !important
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75
}

.bg-opacity-100 {
	--bs-bg-opacity: 1
}

.bg-gradient {
	background-image: var(--bs-gradient) !important
}

.user-select-all {
	user-select: all !important
}

.user-select-auto {
	user-select: auto !important
}

.user-select-none {
	user-select: none !important
}

.pe-none {
	pointer-events: none !important
}

.pe-auto {
	pointer-events: auto !important
}

.rounded {
	border-radius: .25rem !important
}

.rounded-0 {
	border-radius: 0 !important
}

.rounded-1 {
	border-radius: .2rem !important
}

.rounded-2 {
	border-radius: .25rem !important
}

.rounded-3 {
	border-radius: .3rem !important
}

.rounded-circle {
	border-radius: 50% !important
}

.rounded-pill {
	border-radius: 50rem !important
}

.rounded-top {
	border-top-left-radius: .25rem !important;
	border-top-right-radius: .25rem !important
}

.rounded-end {
	border-top-right-radius: .25rem !important;
	border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
	border-bottom-right-radius: .25rem !important;
	border-bottom-left-radius: .25rem !important
}

.rounded-start {
	border-bottom-left-radius: .25rem !important;
	border-top-left-radius: .25rem !important
}

.visible {
	visibility: visible !important
}

.invisible {
	visibility: hidden !important
}

@media(min-width:1200px) {
	.fs-1 {
		font-size: 2.5rem !important
	}

	.fs-2 {
		font-size: 2rem !important
	}

	.fs-3 {
		font-size: 1.75rem !important
	}

	.fs-4 {
		font-size: 1.5rem !important
	}
}

@media print {
	.d-print-inline {
		display: inline !important
	}

	.d-print-inline-block {
		display: inline-block !important
	}

	.d-print-block {
		display: block !important
	}

	.d-print-grid {
		display: grid !important
	}

	.d-print-table {
		display: table !important
	}

	.d-print-table-row {
		display: table-row !important
	}

	.d-print-table-cell {
		display: table-cell !important
	}

	.d-print-flex {
		display: flex !important
	}

	.d-print-inline-flex {
		display: inline-flex !important
	}

	.d-print-none {
		display: none !important
	}
}

b0dy {
	font-size: 13px;
	font-family: roboto, sans-serif !important;
	line-height: 24px !important;
	color: #727272 !important;
	background-color: #eef2f1
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	color: #1f1f1f;
	font-family: inter, sans-serif !important;
	margin-bottom: 5px;
	letter-spacing: -.8px
}

h1 {
	font-size: 24px;
	line-height: 30px;
	padding-bottom: 5px
}

h2 {
	font-size: 21px;
	line-height: 25px
}

h3 {
	font-size: 20px;
	line-height: 24px
}

h4 {
	font-size: 18px;
	line-height: 23px
}

h5 {
	font-size: 16px;
	line-height: 22px
}

a {
	text-decoration: none !important
}

p1 {
	color: #727272;
	margin-bottom: 30px;
	padding-bottom: 0
}

b0dy {
	min-height: 60vh
}

.notch-clear {
	padding-top: calc(0px + (constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc(0px + (env(safe-area-inset-top))*0.8) !important
}

.notch-push {
	transform: translateY(-150%) !important;
	top: calc(0px + (constant(safe-area-inset-top))*0.8) !important;
	top: calc(0px + (env(safe-area-inset-top))*0.8) !important
}

#page {
	position: relative;
	min-height: 60vh
}

.page-content {
	overflow-x: hidden;
	transition: all 300ms ease;
	z-index: 90;
	padding-bottom: 80px;
	padding-bottom: calc(80px + (constant(safe-area-inset-bottom))*1.1);
	padding-bottom: calc(80px + (env(safe-area-inset-bottom))*1.1)
}

.c0ntent-box {
	background-color: #fff;
	border-radius: 20px;
	margin-left: 15px;
	margin-right: 15px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .08)
}

.c0ntent {
	margin: 20px 15px
}

.c0ntent p:last-child {
	margin-bottom: 0
}

.c0ntent-full {
	margin: 0
}

.c0ntent-boxed {
	padding: 20px 15px 0
}

.he4der-logo {
	background-size: 28px 28px;
	width: 50px
}

.footer .footer-logo {
	background-size: 80px 80px;
	height: 80px !important;
	margin-bottom: 20px !important
}

.theme-light .he4der .he4der-logo {
	background-image: url(../images/preload-logo.png)
}

.theme-dark .he4der .he4der-logo {
	background-image: url(../images/preload-logo.png)
}

.theme-light .footer .footer-logo {
	background-image: url(../images/preload-logo.png)
}

.theme-dark .footer .footer-logo {
	background-image: url(../images/preload-logo.png)
}

.color-highlight {
	color: #4a89dc !important
}

.bg-highlight {
	background-color: #4a89dc !important;
	color: #fff !important
}

.border-highlight {
	border-color: #4a89dc !important
}

.gradient-highlight {
	background-image: linear-gradient(to bottom, #5D9CEC, #4A89DC) !important;
	color: #fff
}

.color-highlight-light {
	color: #5d9cec !important
}

.bg-highlight-light {
	background-color: #5d9cec !important;
	color: #fff !important
}

/* .bg-fade-highlight-dark {
	background-color:  !important;
	color: #fff !important
} */

/* .bg-fade-highlight-light {
	background-color:  !important;
	color: #fff !important
} */

.color-highlight-dark {
	color: #4a89dc !important
}

/* .color-icon-highlight {
	stroke: #4a89dc !important;
	fill:  !important
} */

.bg-highlight-dark {
	background-color: #4a89dc !important;
	color: #fff !important
}

.border-highlight-light {
	border-color: #5d9cec !important
}

.border-highlight-dark {
	border-color: #4a89dc !important
}

.focus-highlight:focus {
	border-color: #4a89dc !important
}

.gradient-highlight {
	background-image: linear-gradient(to bottom, #5D9CEC, #4A89DC)
}

.color-green-light {
	color: #a0d468 !important
}

.bg-green-light {
	background-color: #a0d468 !important;
	color: #fff !important
}

.bg-fade-green-dark {
	background-color: rgba(140, 193, 82, .7) !important;
	color: #fff !important
}

.bg-fade-green-light {
	background-color: rgba(140, 193, 82, .3) !important;
	color: #fff !important
}

.color-green-dark {
	color: #8cc152 !important
}

.color-icon-green {
	stroke: #8cc152 !important;
	fill: rgba(140, 193, 82, .3) !important
}

.bg-green-dark {
	background-color: #8cc152 !important;
	color: #fff !important
}

.border-green-light {
	border-color: #a0d468 !important
}

.border-green-dark {
	border-color: #8cc152 !important
}

.focus-green:focus {
	border-color: #8cc152 !important
}

.gradient-green {
	background-image: linear-gradient(to bottom, #A0D468, #8CC152)
}

.color-grass-light {
	color: #34cc73 !important
}

.bg-grass-light {
	background-color: #34cc73 !important;
	color: #fff !important
}

.bg-fade-grass-dark {
	background-color: rgba(140, 193, 82, .7) !important;
	color: #fff !important
}

.bg-fade-grass-light {
	background-color: rgba(140, 193, 82, .3) !important;
	color: #fff !important
}

.color-grass-dark {
	color: #2aba66 !important
}

.color-icon-grass {
	stroke: #2aba66 !important;
	fill: rgba(140, 193, 82, .3) !important
}

.bg-grass-dark {
	background-color: #2aba66 !important;
	color: #fff !important
}

.border-grass-light {
	border-color: #34cc73 !important
}

.border-grass-dark {
	border-color: #2aba66 !important
}

.focus-grass:focus {
	border-color: #2aba66 !important
}

.gradient-grass {
	background-image: linear-gradient(to bottom, #34cc73, #2ABA66)
}

.color-red-light {
	color: #ed5565 !important
}

.bg-red-light {
	background-color: #ed5565 !important;
	color: #fff !important
}

.bg-fade-red-dark {
	background-color: rgba(218, 68, 83, .7) !important;
	color: #fff !important
}

.bg-fade-red-light {
	background-color: rgba(218, 68, 83, .3) !important;
	color: #fff !important
}

.color-red-dark {
	color: #da4453 !important
}

.color-icon-red {
	stroke: #da4453 !important;
	fill: rgba(218, 68, 83, .3) !important
}

.bg-red-dark {
	background-color: #da4453 !important;
	color: #fff !important
}

.border-red-light {
	border-color: #ed5565 !important
}

.border-red-dark {
	border-color: #da4453 !important
}

.focus-red:focus {
	border-color: #da4453 !important
}

.gradient-red {
	background-image: linear-gradient(to bottom, #ED5565, #DA4453)
}

.color-orange-light {
	color: #fc6e51 !important
}

.bg-orange-light {
	background-color: #fc6e51 !important;
	color: #fff !important
}

.bg-fade-orange-dark {
	background-color: rgba(233, 87, 63, .7) !important;
	color: #fff !important
}

.bg-fade-orange-light {
	background-color: rgba(233, 87, 63, .3) !important;
	color: #fff !important
}

.color-orange-dark {
	color: #e9573f !important
}

.color-icon-orange {
	stroke: #e9573f !important;
	fill: rgba(233, 87, 63, .3) !important
}

.bg-orange-dark {
	background-color: #e9573f !important;
	color: #fff !important
}

.border-orange-light {
	border-color: #fc6e51 !important
}

.border-orange-dark {
	border-color: #e9573f !important
}

.focus-orange:focus {
	border-color: #e9573f !important
}

.gradient-orange {
	background-image: linear-gradient(to bottom, #FC6E51, #E9573F)
}

.color-yellow-light {
	color: #ffce54 !important
}

.bg-yellow-light {
	background-color: #ffce54 !important;
	color: #fff !important
}

.bg-fade-yellow-dark {
	background-color: rgba(246, 187, 66, .7) !important;
	color: #fff !important
}

.bg-fade-yellow-light {
	background-color: rgba(246, 187, 66, .3) !important;
	color: #fff !important
}

.color-yellow-dark {
	color: #f6bb42 !important
}

.color-icon-yellow {
	stroke: #f6bb42 !important;
	fill: rgba(246, 187, 66, .3) !important
}

.bg-yellow-dark {
	background-color: #f6bb42 !important;
	color: #fff !important
}

.border-yellow-light {
	border-color: #ffce54 !important
}

.border-yellow-dark {
	border-color: #f6bb42 !important
}

.focus-yellow:focus {
	border-color: #f6bb42 !important
}

.gradient-yellow {
	background-image: linear-gradient(to bottom, #FFCE54, #F6BB42)
}

.color-sunny-light {
	color: #f0b31b !important
}

.bg-sunny-light {
	background-color: #f0b31b !important;
	color: #fff !important
}

.bg-fade-sunny-dark {
	background-color: rgba(246, 187, 66, .7) !important;
	color: #fff !important
}

.bg-fade-sunny-light {
	background-color: rgba(246, 187, 66, .3) !important;
	color: #fff !important
}

.color-sunny-dark {
	color: #d99914 !important
}

.color-icon-sunny {
	stroke: #d99914 !important;
	fill: rgba(246, 187, 66, .3) !important
}

.bg-sunny-dark {
	background-color: #d99914 !important;
	color: #fff !important
}

.border-sunny-light {
	border-color: #f0b31b !important
}

.border-sunny-dark {
	border-color: #d99914 !important
}

.focus-sunny:focus {
	border-color: #d99914 !important
}

.gradient-sunny {
	background-image: linear-gradient(to bottom, #f0b31b, #d99914)
}

.color-blue-light {
	color: #5d9cec !important
}

.bg-blue-light {
	background-color: #5d9cec !important;
	color: #fff !important
}

.bg-fade-blue-dark {
	background-color: rgba(74, 137, 220, .7) !important;
	color: #fff !important
}

.bg-fade-blue-light {
	background-color: rgba(74, 137, 220, .3) !important;
	color: #fff !important
}

.color-blue-dark {
	color: #4a89dc !important
}

.color-icon-blue {
	stroke: #4a89dc !important;
	fill: rgba(74, 137, 220, .3) !important
}

.bg-blue-dark {
	background-color: #4a89dc !important;
	color: #fff !important
}

.border-blue-light {
	border-color: #5d9cec !important
}

.border-blue-dark {
	border-color: #4a89dc !important
}

.focus-blue:focus {
	border-color: #4a89dc !important
}

.gradient-blue {
	background-image: linear-gradient(to bottom, #5D9CEC, #4A89DC)
}

.color-teal-light {
	color: #a0cecb !important
}

.bg-teal-light {
	background-color: #a0cecb !important;
	color: #fff !important
}

.bg-fade-teal-dark {
	background-color: rgba(125, 177, 177, .7) !important;
	color: #fff !important
}

.bg-fade-teal-light {
	background-color: rgba(125, 177, 177, .3) !important;
	color: #fff !important
}

.color-teal-dark {
	color: #7db1b1 !important
}

.color-icon-teal {
	stroke: #7db1b1 !important;
	fill: rgba(125, 177, 177, .3) !important
}

.bg-teal-dark {
	background-color: #7db1b1 !important;
	color: #fff !important
}

.border-teal-light {
	border-color: #a0cecb !important
}

.border-teal-dark {
	border-color: #7db1b1 !important
}

.focus-teal:focus {
	border-color: #7db1b1 !important
}

.gradient-teal {
	background-image: linear-gradient(to bottom, #A0CECB, #7DB1B1)
}

.color-mint-light {
	color: #48cfad !important
}

.bg-mint-light {
	background-color: #48cfad !important;
	color: #fff !important
}

.bg-fade-mint-dark {
	background-color: rgba(55, 188, 155, .7) !important;
	color: #fff !important
}

.bg-fade-mint-light {
	background-color: rgba(55, 188, 155, .3) !important;
	color: #fff !important
}

.color-mint-dark {
	color: #37bc9b !important
}

.color-icon-mint {
	stroke: #37bc9b !important;
	fill: rgba(55, 188, 155, .3) !important
}

.bg-mint-dark {
	background-color: #37bc9b !important;
	color: #fff !important
}

.border-mint-light {
	border-color: #48cfad !important
}

.border-mint-dark {
	border-color: #37bc9b !important
}

.focus-mint:focus {
	border-color: #37bc9b !important
}

.gradient-mint {
	background-image: linear-gradient(to bottom, #48CFAD, #37BC9B)
}

.color-pink-light {
	color: #ec87c0 !important
}

.bg-pink-light {
	background-color: #ec87c0 !important;
	color: #fff !important
}

.bg-fade-pink-dark {
	background-color: rgba(215, 112, 173, .7) !important;
	color: #fff !important
}

.bg-fade-pink-light {
	background-color: rgba(215, 112, 173, .3) !important;
	color: #fff !important
}

.color-pink-dark {
	color: #d770ad !important
}

.color-icon-pink {
	stroke: #d770ad !important;
	fill: rgba(215, 112, 173, .3) !important
}

.bg-pink-dark {
	background-color: #d770ad !important;
	color: #fff !important
}

.border-pink-light {
	border-color: #ec87c0 !important
}

.border-pink-dark {
	border-color: #d770ad !important
}

.focus-pink:focus {
	border-color: #d770ad !important
}

.gradient-pink {
	background-image: linear-gradient(to bottom, #EC87C0, #D770AD)
}

.color-pink2-light {
	color: #ff5982 !important
}

.bg-pink2-light {
	background-color: #ff5982 !important;
	color: #fff !important
}

.bg-fade-pink2-dark {
	background-color: rgba(215, 112, 173, .7) !important;
	color: #fff !important
}

.bg-fade-pink2-light {
	background-color: rgba(215, 112, 173, .3) !important;
	color: #fff !important
}

.color-pink2-dark {
	color: #fb3365 !important
}

.color-icon-pink2 {
	stroke: #fb3365 !important;
	fill: rgba(215, 112, 173, .3) !important
}

.bg-pink2-dark {
	background-color: #fb3365 !important;
	color: #fff !important
}

.border-pink2-light {
	border-color: #ff5982 !important
}

.border-pink2-dark {
	border-color: #fb3365 !important
}

.focus-pink2:focus {
	border-color: #fb3365 !important
}

.gradient-pink2 {
	background-image: linear-gradient(to bottom, #ff5982, #fb3365)
}

.color-magenta-light {
	color: #ac92ec !important
}

.bg-magenta-light {
	background-color: #ac92ec !important;
	color: #fff !important
}

.bg-fade-magenta-dark {
	background-color: rgba(150, 122, 220, .7) !important;
	color: #fff !important
}

.bg-fade-magenta-light {
	background-color: rgba(150, 122, 220, .3) !important;
	color: #fff !important
}

.color-magenta-dark {
	color: #967adc !important
}

.color-icon-magenta {
	stroke: #967adc !important;
	fill: rgba(150, 122, 220, .3) !important
}

.bg-magenta-dark {
	background-color: #967adc !important;
	color: #fff !important
}

.border-magenta-light {
	border-color: #ac92ec !important
}

.border-magenta-dark {
	border-color: #967adc !important
}

.focus-magenta:focus {
	border-color: #967adc !important
}

.gradient-magenta {
	background-image: linear-gradient(to bottom, #AC92EC, #967ADC)
}

.color-brown-light {
	color: #baa286 !important
}

.bg-brown-light {
	background-color: #baa286 !important;
	color: #fff !important
}

.bg-fade-brown-dark {
	background-color: rgba(170, 142, 105, .7) !important;
	color: #fff !important
}

.bg-fade-brown-light {
	background-color: rgba(170, 142, 105, .3) !important;
	color: #fff !important
}

.color-brown-dark {
	color: #aa8e69 !important
}

.color-icon-brown {
	stroke: #aa8e69 !important;
	fill: rgba(170, 142, 105, .3) !important
}

.bg-brown-dark {
	background-color: #aa8e69 !important;
	color: #fff !important
}

.border-brown-light {
	border-color: #baa286 !important
}

.border-brown-dark {
	border-color: #aa8e69 !important
}

.focus-brown:focus {
	border-color: #aa8e69 !important
}

.gradient-brown {
	background-image: linear-gradient(to bottom, #BAA286, #AA8E69)
}

.color-gray-light {
	color: #e2e5ea !important
}

.bg-gray-light {
	background-color: #e2e5ea !important;
	color: #fff !important
}

.bg-fade-gray-dark {
	background-color: rgba(170, 178, 189, .7) !important;
	color: #fff !important
}

.bg-fade-gray-light {
	background-color: rgba(170, 178, 189, .3) !important;
	color: #fff !important
}

.color-gray-dark {
	color: #aab2bd !important
}

.color-icon-gray {
	stroke: #aab2bd !important;
	fill: rgba(170, 178, 189, .3) !important
}

.bg-gray-dark {
	background-color: #aab2bd !important;
	color: #fff !important
}

.border-gray-light {
	border-color: #e2e5ea !important
}

.border-gray-dark {
	border-color: #aab2bd !important
}

.focus-gray:focus {
	border-color: #aab2bd !important
}

.gradient-gray {
	background-image: linear-gradient(to bottom, #e2e5ea, #AAB2BD)
}

.color-aqua-light {
	color: #4fc1e9 !important
}

.bg-aqua-light {
	background-color: #4fc1e9 !important;
	color: #fff !important
}

.bg-fade-aqua-dark {
	background-color: rgba(67, 74, 84, .7) !important;
	color: #fff !important
}

.bg-fade-aqua-light {
	background-color: rgba(67, 74, 84, .3) !important;
	color: #fff !important
}

.color-aqua-dark {
	color: #3bafda !important
}

.color-icon-aqua {
	stroke: #3bafda !important;
	fill: rgba(67, 74, 84, .3) !important
}

.bg-aqua-dark {
	background-color: #3bafda !important;
	color: #fff !important
}

.border-aqua-light {
	border-color: #4fc1e9 !important
}

.border-aqua-dark {
	border-color: #3bafda !important
}

.focus-aqua:focus {
	border-color: #3bafda !important
}

.gradient-aqua {
	background-image: linear-gradient(to bottom, #4FC1E9, #3BAFDA)
}

.color-night-light {
	color: #222529 !important
}

.bg-night-light {
	background-color: #222529 !important;
	color: #fff !important
}

.bg-fade-night-dark {
	background-color: rgba(67, 74, 84, .7) !important;
	color: #fff !important
}

.bg-fade-night-light {
	background-color: rgba(67, 74, 84, .3) !important;
	color: #fff !important
}

.color-night-dark {
	color: #16181c !important
}

.color-icon-night {
	stroke: #16181c !important;
	fill: rgba(67, 74, 84, .3) !important
}

.bg-night-dark {
	background-color: #16181c !important;
	color: #fff !important
}

.border-night-light {
	border-color: #222529 !important
}

.border-night-dark {
	border-color: #16181c !important
}

.focus-night:focus {
	border-color: #16181c !important
}

.gradient-night {
	background-image: linear-gradient(to bottom, #222529, #16181c)
}

.color-dark-light {
	color: #656d78 !important
}

.bg-dark-light {
	background-color: #656d78 !important;
	color: #fff !important
}

.bg-fade-dark-dark {
	background-color: rgba(67, 74, 84, .7) !important;
	color: #fff !important
}

.bg-fade-dark-light {
	background-color: rgba(67, 74, 84, .3) !important;
	color: #fff !important
}

.color-dark-dark {
	color: #434a54 !important
}

.color-icon-dark {
	stroke: #434a54 !important;
	fill: rgba(67, 74, 84, .3) !important
}

.bg-dark-dark {
	background-color: #434a54 !important;
	color: #fff !important
}

.border-dark-light {
	border-color: #656d78 !important
}

.border-dark-dark {
	border-color: #434a54 !important
}

.focus-dark:focus {
	border-color: #434a54 !important
}

.gradient-dark {
	background-image: linear-gradient(to bottom, #656D78, #434A54)
}

.theme-light input:not([type=submit]):not(.focus-color):focus,
.theme-light select:focus,
.theme-light textarea:active {
	border-color: rgba(0, 0, 0, .3) !important
}

.theme-light [data-card-height=cover] input:not([type=submit]):not(.focus-color):focus,
.theme-light [data-card-height=cover] select:focus,
.theme-light [data-card-height=cover] textarea:active {
	border-color: rgba(255, 255, 255, .3) !important
}

.theme-dark input:not([type=submit]):not(.focus-color):focus,
.theme-dark select:focus,
.theme-dark textarea:active {
	border-color: rgba(255, 255, 255, .3) !important
}

.color-facebook {
	color: #3b5998 !important
}

.bg-facebook {
	background-color: #3b5998 !important;
	color: #fff
}

.color-linkedin {
	color: #0077b5 !important
}

.bg-linkedin {
	background-color: #0077b5 !important;
	color: #fff
}

.color-twitter {
	color: #4099ff !important
}

.bg-twitter {
	background-color: #4099ff !important;
	color: #fff
}

.color-google {
	color: #d34836 !important
}

.bg-google {
	background-color: #d34836 !important;
	color: #fff
}

.color-whatsapp {
	color: #34af23 !important
}

.bg-whatsapp {
	background-color: #34af23 !important;
	color: #fff
}

.color-pinterest {
	color: #c92228 !important
}

.bg-pinterest {
	background-color: #c92228 !important;
	color: #fff
}

.color-mail {
	color: #3498db !important
}

.bg-mail {
	background-color: #3498db !important;
	color: #fff
}

.color-phone {
	color: #27ae60 !important
}

.bg-phone {
	background-color: #27ae60 !important;
	color: #fff
}

.color-instagram {
	color: #e1306c !important
}

.bg-instagram {
	background-color: #e1306c !important;
	color: #fff
}

.color-white {
	color: #fff !important
}

.color-black {
	color: #000 !important
}

.bg-white {
	background-color: #fff !important
}

.bg-black {
	background-color: #000 !important
}

.border-transparent {
	border-color: transparent !important
}

.footer-bar-2 .active-nav,
.footer-bar-5 strong,
.footer-bar-4 strong,
.splide__pagination__page.is-active {
	background-color: #4a89dc !important
}

.footer-bar-1 .active-nav i,
.footer-bar-1 .active-nav span,
.footer-bar-3 .active-nav i {
	color: #4a89dc !important
}



.form-floating-over>.form-control:focus~label {
	color: #4a89dc
}

.form-floating-over>.form-control:not(:placeholder-shown)~label {
	color: #4a89dc
}

.form-floating-over>.form-select~label {
	color: #4a89dc
}

.color-highlight {
	color: #4a89dc
}

.bg-highlight {
	background-color: #4a89dc;
	color: #fff !important
}

.he4der-title span {
	font-family: inter, sans-serif
}

.footer-title span {
	font-family: inter, sans-serif
}

.he4der-logo {
	color: transparent !important
}

.he4der-logo span {
	display: none;
	display: none
}

.he4der {
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
	background-color: rgba(255, 255, 255, .7);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 50px;
	z-index: 99;
	transition: all 300ms ease;
	border-bottom: solid 1px rgba(0, 0, 0, .08)
}

.he4der .he4der-title {
	position: absolute;
	height: 50px;
	line-height: 49px;
	font-size: 14px;
	color: #000;
	font-weight: 600;
	z-index: 1;
	font-family: inter, sans-serif
}

.he4der .he4der-logo {
	background-repeat: no-repeat;
	background-position: center center;
	height: 100%;
	position: absolute;
	z-index: 1;
	left: 50%
}

.he4der .he4der-icon {
	position: absolute;
	height: 50px;
	width: 45px;
	text-align: center;
	line-height: 49px;
	font-size: 12px;
	color: #1f1f1f;
	border: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent !important
}

.he4der .dropdown-menu {
	z-index: 99
}

.footer .footer-logo {
	background-repeat: no-repeat;
	background-position: center center;
	height: 100%;
	position: absolute;
	z-index: 1;
	left: 50%
}

.he4der-auto-show {
	opacity: 0;
	pointer-events: none;
	transition: all 300ms ease
}

.he4der-active {
	opacity: 1;
	pointer-events: all;
	transition: all 300ms ease
}

.he4der-always-show .he4der {
	margin-top: 0 !important
}

.he4der-static {
	position: relative !important
}

.he4der-transparent {
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important
}

.he4der-clear {
	padding-top: 50px
}

.he4der-clear-small {
	padding-top: 20px
}

.he4der-clear-medium {
	padding-top: 70px
}

.he4der-clear-large {
	padding-top: 85px
}

.he4der-icon .badge {
	position: absolute;
	margin-left: -5px;
	color: #fff !important;
	width: 16px;
	text-align: center;
	line-height: 16px;
	padding: 0;
	padding-left: 1px !important;
	border-radius: 18px;
	margin-top: 7px;
	font-size: 11px
}

.he4der-transparent-light {
	background-color: transparent;
	box-shadow: none !important
}

.he4der-transparent-light a {
	color: #fff
}

.he4der-transparent-dark {
	background-color: transparent;
	box-shadow: none !important
}

.he4der-transparent-dark a {
	color: #000
}

.he4der-logo-center .he4der-icon-1 {
	left: 0
}

.he4der-logo-center .he4der-icon-2 {
	left: 45px
}

.he4der-logo-center .he4der-icon-3 {
	right: 45px
}

.he4der-logo-center .he4der-icon-4 {
	right: 0
}

.he4der-logo-center .he4der-title {
	width: 150px;
	left: 50%;
	margin-left: -75px;
	text-align: center
}

.he4der-logo-center .he4der-logo {
	background-position: center center;
	width: 150px !important;
	left: 50%;
	margin-left: -75px;
	text-align: center
}

.he4der-logo-left .he4der-icon-1 {
	right: 0
}

.he4der-logo-left .he4der-icon-2 {
	right: 45px
}

.he4der-logo-left .he4der-icon-3 {
	right: 90px
}

.he4der-logo-left .he4der-icon-4 {
	right: 135px
}

.he4der-logo-left .he4der-logo {
	left: 0 !important;
	margin-left: 0 !important
}

.he4der-logo-left .he4der-title {
	left: 15px !important;
	margin-left: 0 !important
}

.he4der-logo-right .he4der-icon-1 {
	left: 0
}

.he4der-logo-right .he4der-icon-2 {
	left: 45px
}

.he4der-logo-right .he4der-icon-3 {
	left: 90px
}

.he4der-logo-right .he4der-icon-4 {
	left: 135px
}

.he4der-logo-right .he4der-logo {
	left: auto !important;
	right: 20px !important;
	margin-left: 0 !important
}

.he4der-logo-right .he4der-title {
	left: auto !important;
	right: 20px !important;
	margin-left: 0 !important
}

.he4der-logo-app .he4der-icon-1 {
	left: 0
}

.he4der-logo-app .he4der-icon-2 {
	right: 2px
}

.he4der-logo-app .he4der-icon-3 {
	right: 45px
}

.he4der-logo-app .he4der-icon-4 {
	right: 90px
}

.he4der-logo-app .he4der-logo {
	left: 0 !important;
	margin-left: 40px !important
}

.he4der-logo-app .he4der-title {
	left: 0 !important;
	margin-left: 50px !important
}

.footer-logo span {
	display: none
}

.he4der-demo {
	z-index: 98 !important;
	position: relative
}

.notch-clear {
	padding-top: calc(0px + (constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc(0px + (env(safe-area-inset-top))*0.8) !important
}

.he4der-clear-notch {
	padding-top: calc(0px + (constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc(0px + (env(safe-area-inset-top))*0.8) !important
}

.he4der-clear-small {
	padding-top: calc(20px + (constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc(20px + (env(safe-area-inset-top))*0.8) !important
}

.he4der-clear-medium {
	padding-top: calc(70px + (constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc(70px + (env(safe-area-inset-top))*0.8) !important
}

.he4der-clear-large {
	padding-top: calc(85px + (constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc(85px + (env(safe-area-inset-top))*0.8) !important
}

.he4der {
	height: calc(50px + (constant(safe-area-inset-top))*0.8) !important;
	height: calc(50px + (env(safe-area-inset-top))*0.8) !important
}

.he4der-icon {
	margin-top: calc((constant(safe-area-inset-top))*0.8) !important;
	margin-top: calc((env(safe-area-inset-top))*0.8) !important
}

.he4der-title {
	margin-top: calc((constant(safe-area-inset-top))*0.8) !important;
	margin-top: calc((env(safe-area-inset-top))*0.8) !important
}

.page-title {
	padding-top: calc((constant(safe-area-inset-top))*0.8) !important;
	padding-top: calc((env(safe-area-inset-top))*0.8) !important
}

.page-title {
	display: flex;
	transition: all 300ms ease;
	margin-top: 23px;
	margin-bottom: 30px
}

.page-title h1 {
	margin-right: auto;
	font-weight: 700 !important;
	font-size: 28px;
	padding-top: 3px;
	margin-left: 15px
}

.page-title div {
	margin-right: auto
}

.page-title p1 {
	font-weight: 500;
	margin-top: -9px;
	margin-bottom: -10px;
	margin-left: 15px
}

.page-title-fixed {
	dislay: flex;
	position: fixed;
	left: 0;
	right: 0
}

.page-title-over {
	z-index: 99
}

.page-title-clear {
	height: calc(90px + (constant(safe-area-inset-top))*0.8) !important;
	height: calc(90px + (env(safe-area-inset-top))*0.8) !important
}

.page-title-icon {
	float: left;
	z-index: 2;
	height: 38px;
	width: 38px;
	margin-right: 13.3333333333px;
	line-height: 39px;
	border-radius: 16px;
	text-align: center
}

.page-content-top {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 53
}

.he4der.he4der-auto-show {
	pointer-events: none
}

.he4der.he4der-active {
	pointer-events: all !important
}












.footer-bar-2 .active-nav {
	color: #fff !important
}

.footer-bar-2 .active-nav strong {
	position: absolute;
	width: 80px;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	bottom: 0
}

.footer-bar-4 .active-nav {
	color: #fff !important
}

.footer-bar-4 .active-nav strong {
	position: absolute;
	width: 47px;
	height: 47px;
	border-radius: 60px;
	left: 50%;
	top: 30px;
	transform: translate(-50%, -50%);
	bottom: 0
}

.footer-bar-4 span {
	display: none !important
}

.footer-bar-4 i {
	padding-top: 10px
}

.footer-bar-5 .active-nav strong {
	position: absolute;
	width: 50px;
	height: 2px;
	border-radius: 60px;
	left: 50%;
	top: 0;
	transform: translateX(-50%)
}

.footer-bar-3 span {
	display: none !important
}

.footer-bar-3 .active-nav {
	padding-top: 11px !important
}

.footer-bar-3 .active-nav span {
	display: block !important
}

.footer-bar-3 a {
	padding-top: 18px !important
}

.footer-menu-hidden {
	transition: all 100ms ease;
	transform: translateY(100%) !important
}

.footer-bar-white * {
	color: #fff
}





.is-not-ios .footer-menu-clear {
	height: 70px;
	display: block
}

.is-not-ios .footer {
	padding-bottom: 0
}

.is-not-ios #footer-menu a i {
	padding-top: 13px
}

.is-not-ios #footer-menu a span {
	opacity: .6
}






@keyframes footerAni {
	0% {
		transform: scale(1, 1) translateX(-50%)
	}

	50% {
		transform: scale(1.05, 1.05) translateX(-48%)
	}
}









.menu-title {
	position: relative
}

.menu-title h1 {
	font-size: 28px;
	font-weight: 700;
	padding: 20px 0 10px 15px
}

.menu-title p1 {
	font-weight: 600;
	font-size: 12px;
	margin: 15px 0 -25px 15px !important;
	padding: 0
}

.menu-title a {
	font-size: 20px;
	color: #dc3545;
	position: absolute;
	text-align: center;
	width: 55px;
	right: 0;
	top: 50%;
	margin-top: -13px
}

.menu-bg {
	background-color: #eef2f1
}

.menu-hider {
	position: fixed;
	top: -100px;
	bottom: -100px;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .35);
	z-index: 100;
	pointer-events: none;
	opacity: 0;
	transition: all 300ms ease
}

.menu {
	position: fixed;
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
	background-color: rgba(255, 255, 255, .8);
	z-index: 101;
	overflow: scroll;
	transition: all 300ms ease;
	-webkit-overflow-scrolling: touch
}

.menu .c0ntent {
	margin-bottom: 0 !important
}

.menu-box-left {
	left: 0;
	top: 0 !important;
	width: 250px;
	height: 100%;
	bottom: 0;
	transform: translate3d(-100%, 0, 0)
}

.menu-box-right {
	display: none;
	right: 0;
	transform: translateX(100%);
	top: 0 !important;
	height: 100%;
	bottom: 0;
	width: 250px
}

.menu-box-top {
	transform: translate3d(0, -115%, 0);
	top: 0;
	left: 0;
	right: 0
}

.menu-box-bottom {
	bottom: 0;
	left: 0;
	right: 0;
	transform: translate3d(0, 110%, 0)
}

.menu-box-modal {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -40%);
	opacity: 0;
	pointer-events: none;
	transition: all 200ms ease
}

.menu-box-top.menu-box-detached {
	top: 10px;
	left: 10px;
	right: 10px
}

.menu-box-bottom.menu-box-detached {
	bottom: 20px;
	left: 10px;
	right: 10px
}

.menu-box-left.menu-active {
	transform: translate3d(0%, 0, 0) !important
}

.menu-box-right.menu-active {
	transform: translate3d(0%, 0, 0) !important
}

.menu-box-top.menu-active {
	transform: translate3d(0%, 0, 0) !important
}

.is-on-homescreen .menu-box-top.menu-active {
	transform: translate3d(0%, 40px, 0) !important
}

.menu-box-bottom.menu-active {
	transform: translate3d(0%, 20px, 0) !important
}

.menu-box-bottom-full.menu-active {
	transform: translate3d(0%, 0px, 0) !important
}

.menu-box-modal.menu-active {
	transition: all 200ms ease;
	pointer-events: all !important;
	opacity: 1 !important;
	transform: translate(-50%, -50%) !important
}

.menu-hider.menu-active {
	transition: all 300ms ease;
	opacity: 1;
	pointer-events: all
}

.menu-hider.menu-active.menu-active-clear {
	background-color: transparent !important
}

.menu-box-left[data-menu-effect=menu-reveal] {
	opacity: 0;
	pointer-events: none;
	transform: translate(0px) !important;
	z-index: 96
}

.menu-box-right[data-menu-effect=menu-reveal] {
	opacity: 0;
	pointer-events: none;
	transform: translate(0px) !important;
	z-index: 96
}

.menu-box-bottom[data-menu-height=cover],
.menu-box-top[data-menu-height=cover] {
	overflow: scroll;
	-webkit-overflow-scrolling: touch
}

.menu-active.menu {
	opacity: 1 !important;
	transition: all 300ms ease;
	pointer-events: all !important
}

#menu-update {
	background-color: #fff;
	position: fixed;
	z-index: 9999;
	width: 310px;
	height: 430px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	pointer-events: none;
	border-radius: 10px;
	transition: all 300ms ease
}

.menu-active {
	opacity: 1 !important
}

.menu-active-no-click {
	opacity: 1;
	background-color: rgba(0, 0, 0, .7);
	pointer-events: all !important
}

#menu-install-pwa-ios {
	overflow: visible !important
}

.fa-ios-arrow {
	transform: translateY(4px)
}

.theme-light .fa-ios-arrow {
	color: #fff !important
}

.menu-open {
	overflow: hidden !important
}

.menu-divider {
	padding-left: 16px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 11px;
	letter-spacing: .3px
}

.menu-list a {
	padding-left: 55px;
	color: #7e8793;
	font-size: 13px;
	font-weight: 500;
	font-family: inter, sans-serif;
	line-height: 48px;
	display: block
}

.menu-list a i {
	font-size: 15px;
	height: 45px;
	color: #707883 !important;
	width: 50px;
	text-align: center;
	line-height: 48px;
	position: absolute;
	margin-left: -50px
}

.menu-list-image a {
	position: relative
}

.menu-list-image {
	padding-top: 5px;
	padding-left: 10px
}

.menu-list-image img {
	height: 33px;
	color: #707883 !important;
	width: 33px;
	margin-top: 8px;
	border-radius: 13px !important;
	text-align: center;
	line-height: 45px;
	position: absolute;
	margin-left: -47px
}

.theme-light .menu .active-nav {
	font-weight: 700;
	color: #000 !important
}

.theme-dark .menu .active-nav {
	font-weight: 700;
	color: #fff !important
}

.menu-list .active-nav i {
	color: #4a89dc !important
}

.menu-content {
	min-height: 100%
}

.menu-list-large a {
	position: relative;
	padding-left: 65px;
	color: #7e8793;
	font-size: 13px;
	font-weight: 500;
	font-family: inter, sans-serif;
	line-height: 50px;
	display: block
}

.menu-list-large a span {
	border-radius: 12px;
	position: absolute;
	height: 32px;
	line-height: 32px;
	width: 32px;
	text-align: center;
	left: 18px;
	top: 50%;
	margin-top: -15px
}

.menu-list-large .badge,
.menu-list-image .badge {
	position: absolute;
	right: 18px;
	top: 50%;
	margin-top: -12px;
	font-weight: 600;
	font-size: 10px;
	padding: 6px 9px;
	border-radius: 7px
}

.menu-list-large .fa-angle-right,
.menu-list-image .fa-angle-right {
	position: absolute;
	right: 27px;
	font-size: 10px;
	opacity: .5;
	margin-top: -6px;
	top: 50%
}

.list-menu {
	font-family: inter, sans-serif;
	margin: 0 25px 0 10px
}

.list-menu a i:first-child {
	border-radius: 12px;
	height: 32px !important;
	line-height: 33px !important;
	width: 32px !important;
	box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .02)
}

.list-menu a img {
	border-radius: 12px;
	height: 32px !important;
	line-height: 32px !important;
	width: 32px !important;
	margin-left: 5px !important;
	box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .02)
}

.list-menu a {
	border: none !important
}

.list-menu .badge {
	padding: 6px 9px !important;
	font-size: 10px !important;
	font-weight: 500 !important;
	margin-right: 2px;
	transform: translateY(-2px);
	border: 0 !important;
	border-radius: 8px
}

.list-menu .custom-control {
	margin-top: -44px !important
}

.list-menu span {
	font-weight: 500;
	padding-left: 7px;
	color: #7e8793
}

.list-menu .active-nav span {
	color: #000;
	font-weight: 600
}

.rounded-0 {
	border-radius: 0 !important
}

.rounded-xs {
	border-radius: 5px !important
}

.rounded-s {
	border-radius: 8px !important
}

.rounded-sm {
	border-radius: 12px !important
}

.rounded-m {
	border-radius: 20px !important
}

.rounded-l {
	border-radius: 30px !important
}

.rounded-xl {
	border-radius: 50px !important
}

.shadow-none {
	box-shadow: 0 0 0 0 transparent !important
}

.shadow-0 {
	box-shadow: 0 0 0 0 transparent !important
}

.shadow-xs {
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, .04) !important
}

.shadow-s {
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1) !important
}

.shadow-m {
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .08) !important
}

.shadow-l {
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .09) !important
}

.shadow-xl {
	box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .02) !important
}

.border-xxs {
	border: solid 1px !important
}

.border-xs {
	border: solid 2px !important
}

.border-s {
	border: solid 3px !important
}

.border-m {
	border: solid 4px !important
}

.border-l {
	border: solid 5px !important
}

.border-xl {
	border: solid 6px !important
}

.border-xxl {
	border: solid 7px !important
}

.line-height-xs {
	line-height: 14px !important
}

.line-height-s {
	line-height: 17px !important
}

.line-height-m {
	line-height: 24px !important
}

.line-height-l {
	line-height: 26px !important
}

.line-height-xl {
	line-height: 37px !important
}

.text-shadow-s {
	text-shadow: 0 1px 0 rgba(0, 0, 0, .4)
}

.text-shadow-l {
	text-shadow: 0 1px 2px rgba(0, 0, 0, .4)
}

.boxed-text-xl,
.boxed-text-l,
.boxed-text-m,
.boxed-text-s {
	display: block;
	text-align: center;
	margin-left: auto;
	margin-right: auto
}

.boxed-text-xl {
	width: 90%;
	max-width: 340px
}

.boxed-text-l {
	width: 85%;
	max-width: 300px
}

.boxed-text-m {
	width: 80%;
	max-width: 300px
}

.boxed-text-s {
	width: 70%;
	max-width: 260px
}

.font-200 {
	font-weight: 200
}

.font-300 {
	font-weight: 300
}

.font-400 {
	font-weight: 400
}

.font-500 {
	font-weight: 500
}

.font-600 {
	font-weight: 600
}

.font-700 {
	font-weight: 700
}

.font-800 {
	font-weight: 800
}

.font-900 {
	font-weight: 900
}

.opacity-10 {
	opacity: .1 !important
}

.opacity-20 {
	opacity: .2 !important
}

.opacity-30 {
	opacity: .3 !important
}

.opacity-40 {
	opacity: .4 !important
}

.opacity-50 {
	opacity: .5 !important
}

.opacity-60 {
	opacity: .6 !important
}

.opacity-70 {
	opacity: .7 !important
}

.opacity-80 {
	opacity: .8 !important
}

.opacity-85 {
	opacity: .85 !important
}

.opacity-90 {
	opacity: .9 !important
}

.font-8 {
	font-size: 8px !important
}

.font-9 {
	font-size: 9px !important
}

.font-10 {
	font-size: 10px !important
}

.font-11 {
	font-size: 11px !important
}

.font-12 {
	font-size: 12px !important
}

.font-13 {
	font-size: 13px !important
}

.font-14 {
	font-size: 14px !important
}

.font-15 {
	font-size: 15px !important
}

.font-16 {
	font-size: 16px !important
}

.font-17 {
	font-size: 17px !important
}

.font-18 {
	font-size: 18px !important
}

.font-19 {
	font-size: 19px !important
}

.font-20 {
	font-size: 20px !important
}

.font-21 {
	font-size: 21px !important
}

.font-22 {
	font-size: 22px !important
}

.font-23 {
	font-size: 23px !important
}

.font-24 {
	font-size: 24px !important
}

.font-25 {
	font-size: 25px !important
}

.font-26 {
	font-size: 26px !important
}

.font-27 {
	font-size: 27px !important
}

.font-28 {
	font-size: 28px !important
}

.font-29 {
	font-size: 29px !important
}

.font-30 {
	font-size: 30px !important
}

.font-31 {
	font-size: 31px !important
}

.font-32 {
	font-size: 32px !important
}

.font-33 {
	font-size: 33px !important
}

.font-34 {
	font-size: 34px !important
}

.font-35 {
	font-size: 35px !important
}

.font-36 {
	font-size: 36px !important
}

.font-37 {
	font-size: 37px !important
}

.font-38 {
	font-size: 38px !important
}

.font-39 {
	font-size: 39px !important
}

.font-40 {
	font-size: 40px !important
}

.font-41 {
	font-size: 41px !important
}

.font-42 {
	font-size: 42px !important
}

.font-43 {
	font-size: 43px !important
}

.font-44 {
	font-size: 44px !important
}

.font-45 {
	font-size: 45px !important
}

.font-46 {
	font-size: 46px !important
}

.font-47 {
	font-size: 47px !important
}

.font-48 {
	font-size: 48px !important
}

.font-49 {
	font-size: 49px !important
}

.font-50 {
	font-size: 50px !important
}

.rotate-45 {
	transform-origin: center;
	transform: rotate(45deg) !important
}

.rotate-90 {
	transform-origin: center;
	transform: rotate(90deg) !important
}

.rotate-135 {
	transform-origin: center;
	transform: rotate(135deg) !important
}

.rotate-180 {
	transform-origin: center;
	transform: rotate(180deg) !important
}

.rotate-225 {
	transform-origin: center;
	transform: rotate(225deg) !important
}

.rotate-270 {
	transform-origin: center;
	transform: rotate(270deg) !important
}

.rotate-315 {
	transform-origin: center;
	transform: rotate(315deg) !important
}

@keyframes scale-animation {
	0% {
		transform: scale(1, 1)
	}

	50% {
		transform: scale(1.05, 1.05)
	}

	0% {
		transform: scale(1, 1)
	}
}

.scale-box {
	animation: scale-animation 1.5s;
	animation-iteration-count: infinite
}

@keyframes scale-icon {
	0% {
		transform: scale(1, 1)
	}

	50% {
		transform: scale(1.3, 1.3)
	}

	0% {
		transform: scale(1, 1)
	}
}

.scale-icon {
	animation: scale-icon 1.5s;
	animation-iteration-count: infinite
}

::-webkit-scrollbar {
	display: none
}

img.mx-auto {
	display: block
}

.disabled {
	display: none !important
}

.no-click {
	pointer-events: none !important
}

.no-outline {
	outline: none !important
}

.theme-dark .drag-line {
	background-color: rgba(255, 255, 255, .05)
}

.drag-line {
	width: 45px;
	height: 3px;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, .1);
	margin: 15px auto -15px;
	animation: moveDragLine 1.5s infinite
}

@keyframes moveDragLine {
	0% {
		transform: translateY(0px)
	}

	20% {
		transform: translateY(-3px)
	}

	40% {
		transform: translateY(1px)
	}

	45% {
		transform: translateY(0px)
	}
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	transition: all 200ms ease
}

#preloader .spinner-border {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -15px
}

.preloader-hide {
	opacity: 0;
	pointer-events: none;
	transition: all 150ms ease !important
}

.theme-light #preloader {
	background-color: #fff
}

.theme-dark #preloader {
	background-color: #0f1117
}

.btn {
	font-family: inter, sans-serif;
	transition: all 300ms ease;
	color: #fff
}

.btn:hover {
	opacity: .9;
	color: #fff
}

.btn-full {
	display: block
}

.btn-xxs,
.btn-xs,
.btn-s,
.btn-sm,
.btn-m,
.btn-l {
	font-size: 12px !important
}

.btn-xxs {
	padding: 6px 10px !important
}

.btn-xs {
	padding: 7px 14px !important
}

.btn-s {
	padding: 8px 18px !important
}

.btn-sm {
	padding: 10px 18px !important
}

.btn-m {
	padding: 13px 20px !important
}

.btn-l {
	padding: 14px 26px !important
}

.btn-xl {
	padding: 15px 30px !important;
	font-size: 15px !important
}

.btn-xxl {
	padding: 17px 34px !important;
	font-size: 17px !important
}

.btn-uppercase {
	text-transform: uppercase
}

.btn-center-xs {
	width: 100px;
	margin: 0 auto;
	display: block
}

.btn-center-s {
	width: 120px;
	margin: 0 auto;
	display: block
}

.btn-center-m {
	width: 160px;
	margin: 0 auto;
	display: block
}

.btn-center-l {
	width: 220px;
	margin: 0 auto;
	display: block
}

.btn-center-xl {
	width: 250px;
	margin: 0 auto;
	display: block
}

.btn.bg-highlight {
	color: #fff
}

.btn-3d {
	transition: all 150ms ease;
	border: none;
	border-bottom: solid 4px
}

.btn-3d:hover {
	border-width: 2px !important
}

.btn:focus {
	outline: none !important
}

.btn-margins {
	margin-left: 17px;
	margin-right: 17px;
	margin-bottom: 30px
}

.under-slider-btn {
	z-index: 50;
	position: relative;
	margin-top: -20px
}

.under-btn {
	z-index: 50;
	position: relative;
	margin-top: -20px !important
}

.btn-icon:hover {
	color: #fff
}

.btn-icon {
	padding-left: 50px !important;
	overflow: hidden;
	position: relative
}

.btn-icon i {
	position: absolute;
	left: 0;
	top: 0;
	line-height: 43px;
	width: 40px;
	height: 100%;
	background-color: rgba(0, 0, 0, .1)
}

button {
	background: 0 0;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit
}

.icon:hover {
	color: #fff
}

.icon {
	display: inline-block;
	position: relative;
	text-align: center
}

.icon-xxs i {
	width: 32px;
	line-height: 32px;
	font-size: 13px
}

.icon-xs i {
	width: 38px;
	line-height: 38px;
	font-size: 14px
}

.icon-s i {
	width: 40px;
	line-height: 40px;
	font-size: 15px
}

.icon-m i {
	width: 45px;
	line-height: 45px;
	font-size: 16px
}

.icon-l i {
	width: 51px;
	line-height: 51px;
	font-size: 17px
}

.icon-xl i {
	width: 55px;
	line-height: 55px;
	font-size: 18px
}

.icon-xxl i {
	width: 60px;
	line-height: 60px;
	font-size: 19px
}

.icon-center {
	display: block;
	margin: 0 auto
}

.icon-20 {
	width: 20px
}

.icon-30 {
	width: 30px
}

.icon-40 {
	width: 40px
}

.icon-50 {
	width: 50px
}

.icon-60 {
	width: 60px
}

.icon-70 {
	width: 70px
}

.icon-80 {
	width: 80px
}

.icon-90 {
	width: 90px
}

.accordion * {
	outline: 0 !important;
	box-shadow: 0 !important;
	box-shadow: none !important
}

.accordion .btn:first-child {
	width: 100%
}

.accordion .card {
	transition: all 0ms ease !important
}

.accordion button {
	transition: all 0ms ease !important
}

.accordion [data-card-height] button {
	width: 100%
}

.btn.accordion-btn {
	text-align: left !important;
	padding: 15px 17px;
	font-size: 13px;
	font-weight: 700
}

.accordion-icon {
	float: right;
	transition: all 250ms ease;
	margin-top: 5px
}

.offline-message {
	position: fixed;
	transform: translateY(-200%);
	padding-bottom: 0;
	text-align: center;
	text-transform: uppercase;
	font-weight: 900;
	transition: all 300ms ease;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	line-height: 55px
}

.online-message {
	position: fixed;
	transform: translateY(-200%);
	padding-bottom: 0;
	text-align: center;
	text-transform: uppercase;
	font-weight: 900;
	transition: all 300ms ease;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	line-height: 55px
}

.is-on-homescreen .offline-message {
	padding-top: 30px !important
}

.is-on-homescreen .online-message {
	padding-top: 30px !important
}

.offline-message-active {
	transform: translateY(0%)
}

.online-message-active {
	transform: translateY(0%)
}

.add-to-home-visible {
	opacity: 1 !important;
	pointer-events: all !important
}

.add-to-home {
	position: fixed;
	background-color: #000;
	z-index: 99;
	left: 15px;
	right: 15px;
	border-radius: 10px;
	height: 80px;
	opacity: 0;
	pointer-events: none;
	transition: all 250ms ease
}

.add-to-home .close-add-to-home {
	pointer-events: none
}

.add-to-home h1 {
	position: absolute;
	padding-left: 75px;
	padding-top: 8px;
	color: #fff;
	font-size: 13px
}

.add-to-home p1 {
	color: #ced4da;
	position: absolute;
	font-size: 11px;
	padding-right: 20px;
	padding-left: 75px;
	padding-top: 35px;
	line-height: 14px
}

.add-to-home img {
	width: 45px;
	margin-top: 17px;
	margin-left: 15px
}

.add-to-home-ios {
	bottom: 25px
}

.add-to-home-ios i {
	width: 40px;
	text-align: center;
	position: absolute;
	font-size: 30px;
	left: 50%;
	bottom: -18px !important;
	margin-left: -21px;
	transform: rotate(180deg);
	color: #000;
	display: block
}

.add-to-home-ios .close-add-to-home {
	position: absolute;
	right: 13px;
	top: 13px;
	transform: scale(.5, .5)
}

.add-to-home-android {
	top: 20px
}

.add-to-home-android i {
	width: 40px;
	text-align: center;
	position: absolute;
	top: -18px;
	right: 15px;
	font-size: 30px;
	margin-left: -20px;
	color: #000;
	display: block
}

.add-to-home-android .close-add-to-home {
	position: absolute;
	right: 5px;
	top: 20px;
	transform: scale(.5, .5)
}

.ad-300x50 {
	margin: 0 auto;
	width: 300px;
	height: 50px
}

.ad-300x250 {
	margin: 0 auto;
	width: 300px;
	height: 250px
}

.ad-300x50-fixed {
	left: 50%;
	margin-left: -150px;
	bottom: 80px;
	bottom: calc(85px + (constant(safe-area-inset-bottom))*1.1) !important;
	bottom: calc(85px + (env(safe-area-inset-bottom))*1.1) !important;
	position: fixed;
	z-index: 90
}

.ad-time-close {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 30px;
	height: 30px;
	line-height: 27px;
	text-align: center;
	border: solid 2px;
	border-radius: 30px
}

.scroll-ad-visible {
	transform: translateY(0%) !important
}

.is-on-homescreen .scroll-ad {
	bottom: 110px
}

.scroll-ad {
	position: fixed;
	bottom: 75px;
	left: 10px;
	right: 10px;
	background-color: #fff;
	z-index: 93;
	height: 80px;
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, .04);
	border-radius: 12px;
	transform: translateY(150%);
	transition: all 300ms ease
}

.scroll-ad img {
	width: 60px;
	position: absolute;
	margin: 10px;
	border-radius: 10px
}

.scroll-ad h1 {
	display: block;
	font-size: 18px;
	padding: 7px 0 0 80px;
	margin-bottom: -3px
}

.scroll-ad em {
	padding: 0 85px 0 80px;
	font-style: normal;
	font-size: 11px;
	opacity: .7;
	display: block;
	line-height: 15px
}

.scroll-ad a {
	width: 60px;
	font-size: 9px;
	right: 15px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	position: absolute;
	top: 50%;
	border-radius: 5px;
	transform: translateY(-50%)
}

.alert h4 {
	padding-left: 47px;
	margin-bottom: -5px
}

.alert button {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	text-align: center
}

.alert button span {
	background-color: transparent
}

.alert button:active {
	box-shadow: none !important;
	border: none !important;
	outline: none !important
}

.alert button:focus {
	box-shadow: none !important;
	border: none !important;
	outline: none !important
}

.alert span {
	background-color: rgba(255, 255, 255, .2);
	position: absolute;
	left: -1px;
	top: -1px;
	bottom: -1px;
	width: 47px
}

.alert span i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

.alert strong {
	padding-left: 47px;
	font-weight: 400
}

.notification {
	position: fixed;
	top: 10px;
	left: 10px;
	right: 10px;
	z-index: 101;
	border-radius: 10px;
	opacity: .98;
	transform: translateY(-120%);
	transition: all 250ms ease
}

.notification .notification-icon {
	padding: 5px;
	display: block;
	background-color: #343a40
}

.notification .notification-icon i:first-child {
	margin: 0 5px 0 10px;
	font-size: 10px
}

.notification .notification-icon i:last-child {
	position: absolute;
	right: 0;
	line-height: 30px;
	width: 40px;
	text-align: center
}

.notification .notification-icon em {
	font-size: 11px;
	text-transform: uppercase;
	font-style: normal
}

.notification h1 {
	margin: 10px 15px -7px
}

.notification p1 {
	line-height: 15px;
	margin: 5px 15px 18px;
	color: #fff
}

.notification.show {
	transform: translateY(0%) !important
}

.is-ios.is-on-homescreen .notification.show {
	transform: translateY(30px) !important
}

.notification-android {
	left: 5px;
	right: 5px;
	top: 5px;
	border-radius: 3px
}

.notification-android i {
	position: absolute;
	top: 50%;
	transform: translateY(-55%);
	width: 35px;
	margin-left: 15px;
	line-height: 35px;
	border-radius: 35px;
	text-align: center;
	font-size: 13px
}

.notification-android h1 {
	padding-left: 55px
}

.notification-android p1 {
	padding-right: 60px;
	padding-left: 55px;
	font-size: 12px
}

.notification-android strong {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 10px;
	font-weight: 400;
	font-style: normal
}

.notification-android .notification-buttons {
	width: 100%;
	margin-left: 70px;
	padding-top: 5px;
	border-top: solid 1px rgba(255, 255, 255, .1)
}

.notification-android .notification-buttons i {
	opacity: .6;
	font-size: 11px;
	position: relative;
	float: left;
	margin: 0 10px 0 0;
	width: auto;
	transform: translateY(-22px)
}

.notification-android a {
	font-size: 11px;
	color: #fff;
	text-transform: uppercase;
	text-align: left;
	font-weight: 500
}

.notification-material {
	left: 0;
	right: 0;
	top: 0;
	border-radius: 0
}

.notification-material strong {
	font-size: 10px;
	font-weight: 400
}

.notification-material em {
	text-transform: none !important
}

.notification-material .notification-icon {
	background-color: transparent !important;
	margin-bottom: -15px
}

.notification-material img {
	position: absolute;
	width: 50px;
	border-radius: 50px;
	right: 20px;
	top: 50%;
	transform: translateY(-50%)
}

.notification-material p1 {
	padding-right: 50px
}

.c0ntent .back-to-top-badge {
	transform: translate(0, 0) !important;
	width: 130px;
	margin: 10px auto 20px !important;
	left: auto;
	bottom: auto;
	display: block;
	position: relative !important
}

.c0ntent .back-to-top-icon {
	transform: scale(1, 1) translate(0, 0px) !important;
	margin: 0 auto 30px;
	right: auto;
	bottom: auto;
	display: block;
	position: relative !important
}

.back-to-top-badge {
	position: fixed;
	z-index: 90;
	bottom: 80px;
	left: 50%;
	transform: translate(-50%, 70px);
	display: inline-block;
	padding: 5px 20px;
	border-radius: 35px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	transition: all 300ms ease
}

.back-to-top-badge i {
	padding: 0 10px 0 0
}

.back-to-top-icon {
	position: fixed;
	z-index: 9999;
	bottom: 70px;
	right: 15px;
	display: inline-block;
	width: 35px;
	line-height: 35px;
	border-radius: 35px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	transform: scale(0, 0);
	transition: all 300ms ease
}

.back-to-top-icon span {
	display: none
}

.back-to-top-badge.back-to-top-visible {
	transform: translate(-50%, 0px)
}

.back-to-top-icon.back-to-top-visible {
	transform: scale(1, 1)
}

.back-to-top-icon-square {
	border-radius: 0
}

.back-to-top-icon-round {
	border-radius: 5px
}

.back-to-top-icon-circle {
	border-radius: 40px
}

.row {
	margin-bottom: 30px
}

.card {
	-webkit-transform: translate3d(0, 0, 0);
	background-size: cover;
	border: 0 !important;
	max-height: 150000px;
	margin-bottom: 30px;
	background-position: center center !important
}

.cover-slider .card {
	margin-bottom: 0 !important
}

[data-card-height=cover].card {
	margin-bottom: 0 !important
}

.card-style {
	overflow: hidden;
	margin: 0 15px 30px;
	border-radius: 20px;
	border: none;
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .1)
}

.card-style .card-b0dy {
	padding: 15px
}

.card-style .card-overlay {
	border-radius: 20px
}

.card-style-full {
	margin-left: 0;
	margin-right: 0
}

.card-b0dy {
	z-index: 2
}

.card-he4der {
	z-index: 2
}

.card-footer {
	z-index: 2
}

.card-top {
	z-index: 2;
	position: absolute;
	left: 0;
	right: 0;
	top: 0
}

.card-bottom {
	z-index: 2;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0
}

.footballcard-bottom {
	z-index: 2;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 5%
}

.card-center {
	z-index: 2;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%)
}

.card-overlay {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	z-index: 1
}

.card-image {
	display: block;
	width: 100%;
	min-height: 250px;
	overflow: hidden;
	position: absolute;
	width: 100%
}

.card-image img {
	transition: all 450ms ease
}

.card-fixed {
	position: fixed;
	left: 0;
	right: 0
}

.card-clear {
	pointer-events: none;
	margin-bottom: -30px;
	opacity: 0
}

.card-overflow {
	overflow: visible !important;
	z-index: 20
}

[data-card-height] p1 {
	padding-bottom: 0
}

.card-scale-image {
	transform: scale(1.05, 1.05) !important
}

.card-rotate-image {
	transform: rotate(5deg) scale(1.2, 1.2) !important
}

.card-grayscale-image {
	filter: grayscale(100%)
}

.card-blur-image {
	filter: blur(5px)
}

.card-hide-image {
	opacity: 0 !important
}

.card-hide div {
	transition: all 300ms ease
}

.card-hide * {
	transition: all 300ms ease
}

.polaroid-effect {
	background-color: #fff;
	padding: 5px;
	border: solid 1px rgba(0, 0, 0, .1)
}

.card-scale * {
	transition: all 300ms ease
}

.card-rotate * {
	transition: all 300ms ease
}

.card-grayscale * {
	transition: all 300ms ease
}

.card-blur * {
	transition: all 300ms ease
}

.card-overlay {
	background-position: center center;
	background-size: cover !important
}

.card-overlay-infinite {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 2000px;
	height: 100%;
	z-index: 0;
	animation: infiniteBG 600s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	background-image: url(../images/mountain.jpg);
	background-size: cover;
	background-repeat: repeat;
	background-position: left center;
	transform: translateX(0px)
}

@keyframes infiniteBG {
	0% {
		transform: translateX(0px)
	}

	50% {
		transform: translateX(-1400px)
	}

	100% {
		transform: translateX(0px)
	}
}

.card-overlay-zoom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	height: 100%;
	z-index: 0;
	animation: zoomBG 30s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	background-size: cover;
	background-repeat: repeat;
	background-position: center center;
	transform: translateX(0px)
}

@keyframes zoomBG {
	0% {
		transform: scale(1, 1)
	}

	50% {
		transform: scale(1.5, 1.5)
	}

	100% {
		transform: scale(1, 1)
	}
}

.card-overlay-rotate {
	position: absolute;
	margin-top: -25%;
	margin-left: -25%;
	width: 150%;
	height: 160%;
	z-index: 0;
	animation: rotateBG 50s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	background-repeat: repeat;
	background-position: center center
}

@keyframes rotateBG {
	0% {
		transform: rotate(0deg)
	}

	50% {
		transform: rotate(45deg)
	}

	100% {
		transform: rotate(0deg)
	}
}

.theme-dark .drag-line {
	background-color: rgba(255, 255, 255, .05)
}

.drag-line {
	width: 45px;
	height: 3px;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, .1);
	margin: 15px auto -15px;
	animation: moveDragLine 1.5s infinite
}

@keyframes moveDragLine {
	0% {
		transform: translateY(0px)
	}

	20% {
		transform: translateY(-3px)
	}

	40% {
		transform: translateY(1px)
	}

	45% {
		transform: translateY(0px)
	}
}

.card-full-left,
.card-full-left .card-overlay {
	margin-left: 0 !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important
}

.card-full-right,
.card-full-right .card-overlay {
	margin-right: 0 !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important
}

.card-scale-image {
	transform: scale(1.05, 1.05) !important
}

.card-rotate-image {
	transform: rotate(5deg) scale(1.2, 1.2) !important
}

.card-grayscale-image {
	filter: grayscale(100%)
}

.card-blur-image {
	filter: blur(5px)
}

.card-hide-image {
	opacity: 0 !important
}

.card-hide div {
	transition: all 300ms ease
}

.card-hide * {
	transition: all 300ms ease
}

.polaroid-effect {
	background-color: #fff;
	padding: 5px;
	border: solid 1px rgba(0, 0, 0, .1)
}

.card-scale * {
	transition: all 300ms ease
}

.card-rotate * {
	transition: all 300ms ease
}

.card-grayscale * {
	transition: all 300ms ease
}

.card-blur * {
	transition: all 300ms ease
}

.card-overlay {
	background-position: center center;
	background-size: cover !important
}

.card-overlay-infinite {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 2000px;
	height: 100%;
	z-index: 0;
	animation: infiniteBG 600s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	background-size: cover;
	background-repeat: repeat;
	background-position: left bottom;
	transform: translateX(0px)
}

@keyframes infiniteBG {
	0% {
		transform: translateX(0px)
	}

	50% {
		transform: translateX(-1400px)
	}

	100% {
		transform: translateX(0px)
	}
}

.walk-card {
	z-index: 2
}

.walk-bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 10000px;
	background-repeat: repeat-y;
	background-position: center;
	background-image: url(../images/walk.jpg);
	animation: infibg 700s infinite
}

@keyframes infibg {
	0% {
		transform: translateY(-45%)
	}

	50% {
		transform: translateY(-100%)
	}
}

.gradient-fade-top {
	z-index: 1;
	top: 0;
	position: fixed;
	left: 0;
	right: 0;
	height: 600px
}

.gradient-fade-bottom {
	z-index: 1;
	bottom: 0;
	position: fixed;
	left: 0;
	right: 0;
	height: 300px;
	background: linear-gradient(to bottom, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.98) 85%, #f0f0f0 100%)
}

.theme-light .gradient-fade-top {
	background: linear-gradient(to top, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.95) 60%, #f0f0f0 100%)
}

.theme-light .gradient-fade-bottom {
	background: linear-gradient(to bottom, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.98) 85%, #f0f0f0 100%)
}

.theme-dark .gradient-fade-top {
	background: linear-gradient(to top, rgba(27, 29, 33, 0) 0%, rgba(27, 29, 33, 0.95) 70%, #1b1d21 100%)
}

.theme-dark .gradient-fade-bottom {
	background: linear-gradient(to bottom, rgba(27, 29, 33, 0) 0%, rgba(27, 29, 33, 0.8) 75%, #1b1d21 100%)
}

.chip {
	display: inline-block;
	margin: 0 10px 15px 0;
	border-radius: 30px;
	padding-right: 15px
}

.chip * {
	font-size: 12px
}

.chip span {
	line-height: 29px
}

.chip i {
	width: 30px;
	line-height: 30px;
	border-radius: 30px;
	display: inline-block;
	text-align: center;
	margin-right: 5px
}

.chip img {
	display: inline;
	margin-right: 9px;
	border-radius: 30px;
	width: 31px;
	line-height: 31px
}

.chip-small {
	transform: scale(1, 1)
}

.chip-medium {
	transform: scale(1.05, 1.05)
}

.chip-large {
	transform: scale(1.15, 1.15)
}

.divider {
	height: 1px;
	display: block;
	background-color: rgba(0, 0, 0, .05);
	margin-bottom: 30px
}

.divider-margins {
	margin: 0 17px 30px
}

.divider-icon {
	height: 1px;
	display: block;
	background-color: rgba(0, 0, 0, .05);
	margin-bottom: 50px
}

.divider-icon i {
	position: absolute;
	width: 50px;
	background-color: #f0f0f0;
	text-align: center;
	margin-top: -8px;
	left: 50%;
	transform: translateX(-50%)
}

.c0ntent-boxed .divider-icon i {
	background-color: #f0f0f0
}

.divider-small {
	height: 2px;
	width: 80px;
	margin: auto
}

.custom-control * {
	cursor: pointer;
	user-select: none !important
}

.switch-is-unchecked {
	display: none
}

.switch-is-checked {
	display: block
}

.scale-switch {
	transform: scale(.9, .9)
}

.small-switch {
	transform: scale(.8, .8)
}

.menu .custom-control {
	margin-top: -42px !important
}

.ios-switch {
	margin-right: -32px;
	margin-top: 12px !important;
	position: relative;
	padding-left: 10px
}

.ios-switch label::before {
	content: "";
	display: block !important;
	background-color: #e8e9ea;
	pointer-events: all;
	margin-top: -5px;
	margin-left: -1px;
	width: 58px !important;
	height: 32px !important;
	border: solid 1px rgba(152, 152, 152, .4);
	border-radius: 50px !important;
	transition: all 250ms ease
}

.ios-switch label::after {
	content: "";
	display: block !important;
	margin-top: -31px;
	z-index: 6;
	width: 30px !important;
	height: 30px !important;
	transition: all 250ms ease;
	border-radius: 50px !important;
	background-color: #fff;
	border: solid 1px rgba(152, 152, 152, .4);
	transition: all 250ms ease
}

.ios-switch input {
	display: none
}

.ios-input:checked~.custom-control-label::after {
	-webkit-transform: translateX(26px) !important
}

.ios-input:checked~.custom-control-label::before {
	background-color: #8cc152 !important
}

.ios-switch-icon label::before {
	background-color: #da4453 !important
}

.ios-switch-icon i {
	line-height: 32px;
	pointer-events: none;
	z-index: 5;
	position: absolute;
	left: 20px;
	margin-top: -5px !important
}

.ios-switch-icon i:last-child {
	z-index: 5;
	position: absolute;
	margin-left: 26px
}

.ios-switch-icon span {
	font-size: 9px;
	font-weight: 700;
	color: #fff;
	line-height: 32px;
	pointer-events: none;
	z-index: 5;
	position: absolute;
	margin-top: -5px !important;
	left: 19px
}

.ios-switch-icon span:last-child {
	z-index: 5;
	position: absolute;
	margin-left: 24px
}

.android-switch {
	padding-left: 10px;
	margin-right: -32px
}

.android-switch label::before {
	display: block !important;
	content: "";
	pointer-events: all;
	margin-top: 5px;
	margin-left: -2px;
	width: 53px !important;
	height: 17px !important;
	border-radius: 50px !important;
	background-color: #aab2bd;
	transition: all 250ms ease
}

.android-switch label::after {
	display: block !important;
	content: "";
	border: solid 1px rgba(0, 0, 0, .1) !important;
	margin-top: -22px;
	margin-left: -3px;
	width: 28px !important;
	height: 28px !important;
	background-color: #fff !important;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .2) !important;
	border-radius: 50px !important;
	transition: all 250ms ease
}

.android-switch input {
	display: none !important
}

.android-input {
	transform: translateY(1px) !important
}

.android-input:checked~.custom-control-label::after {
	background-color: #458be3 !important;
	border: none !important;
	-webkit-transform: translateX(30px) !important
}

.android-input:checked~.custom-control-label::before {
	background-color: #458be3 !important
}

[data-activate] {
	cursor: pointer;
	display: block;
	width: 100%
}

.classic-switch input {
	display: none
}

.classic-switch label::before {
	pointer-events: all;
	width: 40px !important;
	height: 40px !important;
	transform: translateX(11px);
	margin-top: -10px;
	border: none;
	background-color: transparent !important
}

.classic-switch i {
	pointer-events: none;
	transition: all 250ms ease
}

.classic-input:checked~.custom-control-label::after {
	-webkit-transform: translateX(26px) !important
}

.classic-input:checked~.fa-plus {
	color: #da4453 !important;
	transform: rotate(45deg);
	transition: all 250ms ease
}

.classic-input:checked~.fa-angle-down {
	color: #da4453 !important;
	transform: rotate(180deg);
	transition: all 250ms ease
}

.classic-input:checked~.fa-circle {
	color: #da4453 !important;
	transform: scale(1.3, 1.3);
	transition: all 250ms ease
}

.icon-check {
	margin-left: -30px;
	margin-right: 10px;
	cursor: pointer;
	position: relative;
	width: auto;
	display: block
}

.icon-check input {
	position: absolute !important;
	transform: translateX(-10000px)
}

.icon-check input:checked~.icon-check-1 {
	opacity: 0 !important;
	display: none !important
}

.icon-check input:checked~.icon-check-2 {
	display: block
}

.icon-check .fa-check-circle {
	display: none
}

.icon-check i {
	width: 20px;
	text-align: center;
	pointer-events: none;
	position: absolute;
	left: 30px;
	top: 1px;
	height: 24px;
	line-height: 24px
}

.icon-check label {
	cursor: pointer;
	font-size: 13px;
	padding: 0 15px 0 40px
}

.icon-check .icon-check-2 {
	display: none
}

.fac {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	display: block;
	vertical-align: middle;
	font-size: 14px
}

.fac label {
	margin: 0 1em 0 0;
	padding-left: 1.5em;
	cursor: pointer;
	font-weight: inherit
}

.fac>input+label:before {
	font-family: "font awesome 5 free";
	font-weight: 900;
	position: absolute;
	left: 0;
	margin-top: .5px;
	transition: opacity 150ms ease-in-out
}

.fac>input:checked+label:before {
	opacity: 1
}

.fac>span::after {
	font-family: "font awesome 5 free";
	font-weight: 900;
	position: absolute;
	left: 0;
	margin-top: .5px
}

.fac input {
	display: none
}

.checkboxes-demo .fac {
	display: block
}

.fac-checkbox>input+label:before {
	opacity: 0
}

.fac-checkbox>input:checked+label:before {
	opacity: 1
}

.fac-checkbox>span::after {
	content: "";
	opacity: 1
}

.fac-checkbox>input[type=checkbox]+label:before {
	content: "";
	color: #fff !important;
	font-size: 10px !important;
	padding-left: 2px
}

.fac-checkbox-round>input+label:before {
	opacity: 0
}

.fac-checkbox-round>span::after {
	content: ""
}

.fac-checkbox-round>input[type=checkbox]+label:before {
	content: "";
	color: #fff !important;
	font-size: 8px !important;
	padding-left: 3px
}

.fac-radio>input+label:before {
	opacity: 0
}

.fac-radio>input:checked+label:before {
	opacity: 1
}

.fac-radio>span::after {
	content: ""
}

.fac-radio>input[type=radio]+label:before {
	content: "";
	color: #fff !important;
	font-size: 10px !important;
	padding-left: 2px
}

.fac-checkbox-o>input+label:before {
	opacity: 0
}

.fac-checkbox-o>input:checked+label:before {
	opacity: 1
}

.fac-checkbox-round-o>input+label:before {
	opacity: 0
}

.fac-checkbox-round-o>input:checked+label:before {
	opacity: 1
}

.fac-default>input+label:before {
	color: #adb5bd
}

.fac-default>span::after {
	color: #adb5bd
}

.fac-blue>input+label:before {
	color: #0d6efd
}

.fac-blue>span::after {
	color: #0d6efd
}

.fac-green>input+label:before {
	color: #198754
}

.fac-green>span::after {
	color: #198754
}

.fac-orange>input+label:before {
	color: #ffc107
}

.fac-orange>span::after {
	color: #ffc107
}

.fac-red>input+label:before {
	color: #dc3545
}

.fac-red>span::after {
	color: #dc3545
}

.fac-radio-full>span::after {
	content: "";
	font-size: 12px !important
}

.fac-radio-full>input[type=radio]+label:before {
	content: "\c"
}

.ios-switch input,
.android-input input {
	position: absolute !important;
	transform: translateX(-15000px) !important
}

input[type=range] {
	-webkit-appearance: none;
	height: 20px;
	width: 100%;
	outline: none
}

input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	background: #f26b5e;
	position: relative;
	z-index: 3
}

input[type=range]::-webkit-slider-thumb:after {
	content: " ";
	height: 10px;
	position: absolute;
	z-index: 1;
	right: 20px;
	top: 5px;
	background: #ff5b32;
	background: linear-gradient(to right, #f088fc 1%, #AC6CFF 70%)
}

.upload-file {
	text-indent: -999px;
	outline: none;
	width: 100%;
	height: 45px;
	color: transparent !important
}

.upload-file-text {
	pointer-events: none;
	margin-top: -36px !important;
	text-align: center;
	color: #fff;
	height: 10px;
	text-transform: uppercase;
	font-weight: 900
}

.file-data input[type=file] {
	font-size: 0 !important
}

input {
	font-size: 12px !important
}

input::placeholder {
	font-size: 12px !important
}

input:focus {
	outline: none
}

select:focus {
	outline: none
}

textarea:focus {
	outline: none
}

button:focus {
	outline: none
}

.tooltip {
	font-size: 13px
}

code {
	font-size: 11px;
	line-height: 22px
}

.stepper {
	width: 102px;
	border: solid 1px rgba(0, 0, 0, .1)
}

.stepper:after {
	content: "";
	display: table;
	clear: both
}

.stepper input {
	height: 33px;
	width: 33px;
	float: left;
	border: none !important;
	text-align: center;
	display: block;
	line-height: 33px;
	font-size: 11px !important;
	background-color: transparent
}

.stepper input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

.stepper input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0
}

.stepper a {
	text-align: center;
	width: 33px;
	height: 33px;
	line-height: 33px;
	font-size: 9px
}

.stepper a:first-child {
	float: left;
	border-right: solid 1px rgba(0, 0, 0, .1)
}

.stepper a:last-child {
	float: right;
	border-left: solid 1px rgba(0, 0, 0, .1)
}

.btn,
input,
select {
	box-shadow: none !important;
	outline: 0 !important
}

input:hover,
input:focus,
input:active,
select:hover,
select:focus,
select:active,
select option,
textarea:hover,
textarea:focus,
textarea:active,
.btn:hover,
.btn:focus,
.btn:active,
button:hover,
button:focus,
button:active {
	outline: none;
	box-shadow: none !important;
	-webkit-appearance: none !important
}

.theme-light input,
select,
textarea {
	border-color: rgba(0, 0, 0, .08) !important
}

.input-style {
	position: relative;
	margin-bottom: 15px !important
}

.input-style input,
.input-style select,
.input-style textarea {
	font-size: 12px;
	width: 100%;
	line-height: 45px;
	height: 45px;
	border-top-width: 0;
	border-right-width: 0;
	border-left-width: 0;
	padding: 0;
	border-radius: 0
}

.input-style select,
.input-style select option,
.input-style select option:disabled {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important
}

.input-style label {
	opacity: 0;
	left: 0 !important;
	transform: translateX(30px);
	position: absolute;
	top: -12px;
	height: 10px;
	font-size: 10px;
	transition: all 150ms ease;
	background-color: #fff
}

.input-style-active label {
	opacity: 1;
	transform: none !important
}

.input-style.has-icon i:first-child {
	position: absolute;
	top: 50%;
	width: 10px;
	text-align: center;
	margin-top: -8px
}

.input-style.has-icon.has-borders i:first-child {
	position: absolute;
	top: 50%;
	width: 10px;
	padding-left: 15px !important;
	text-align: center;
	margin-top: -7px
}

.input-style.has-icon.has-borders input,
.input-style.has-icon.has-borders select,
.input-style.has-icon.has-borders textarea {
	padding-left: 35px !important
}

.input-style.has-icon input,
.input-style.has-icon select,
.input-style.has-icon textarea {
	padding-left: 25px
}

.input-style input[type=date]::-webkit-calendar-picker-indicator {
	opacity: .3
}

.input-style textarea {
	line-height: 32px !important;
	padding-top: 10px !important;
	transition: all 250ms ease !important
}

.input-style textarea:focus {
	line-height: 24px !important
}

.input-style select {
	color: inherit
}

.input-style input[type=date] {
	color: inherit
}

.input-style em,
.input-style span,
.input-style .valid,
.input-style .invalid {
	position: absolute;
	top: 50%;
	margin-top: -5px;
	right: 5px;
	pointer-events: none
}

.input-style em,
.input-style span {
	margin-top: -12px;
	font-size: 10px;
	font-style: normal;
	opacity: .3
}

.input-style.has-borders input,
.input-style.has-borders select,
.input-style.has-borders textarea {
	height: 53px;
	border-left-width: 1px !important;
	border-right-width: 1px !important;
	border-top-width: 1px !important;
	padding-left: 13px !important;
	padding-right: 10px !important;
	border-radius: 10px !important
}

.input-style.has-borders .valid,
.input-style.has-borders .invalid,
.input-style.has-borders span,
.input-style.has-borders em {
	margin-right: 10px
}

.input-style.input-style-active label {
	left: 4px !important;
	transform: translateX(-14px) !important;
	position: absolute;
	padding: 0 8px !important;
	height: 23px;
	font-size: 12px;
	transition: all 250ms ease;
	background-color: #fff
}

.input-style.has-borders.input-style-active label {
	margin-left: 14px !important
}

.input-style textarea:focus {
	height: 150px;
	transition: all 250ms ease !important
}

.input-style.no-borders.input-style-always-active label {
	opacity: 1;
	left: 6px !important;
	transform: translateX(-14px) !important;
	margin-left: 0 !important;
	position: absolute;
	padding: 0 8px !important;
	height: 23px;
	font-size: 12px;
	transition: all 250ms ease;
	background-color: #fff
}

.input-style.has-borders.input-style-always-active label {
	opacity: 1;
	left: 23px !important;
	transform: translateX(-14px) !important;
	margin-left: 0 !important;
	position: absolute;
	padding: 0 5px !important;
	height: 23px;
	font-size: 12px;
	transition: all 250ms ease;
	background-color: #fff
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0
}

input[type=number] {
	-moz-appearance: textfield
}

.input-transparent * {
	color: #fff !important;
	background-color: transparent !important
}

.input-transparent.no-borders input {
	border-bottom-width: 1px !important;
	border-color: rgba(255, 255, 255, .1) !important
}

.menu .input-style input,
.menu .input-style select {
	background-color: transparent !important
}

.device-is-android .input-style input[type=date] {
	color: inherit !important;
	line-height: 25px !important;
	padding-top: 12px !important;
	height: 55px !important;
	transform: translateY(7px) translateX(0px) !important;
	margin-bottom: 30px !important
}

.device-is-android .input-style input[type=date]~label.color-highlight {
	margin-top: 7px !important
}

.min-ios15 select {
	background-color: inherit !important
}

.min-ios15 input[type=date] {
	-webkit-appearance: none;
	text-align: left !important;
	text-indent: calc(-100% + 70px) !important
}

.otp {
	width: 45px;
	height: 45px;
	border: solid 1px #929292
}

.ios-slider {
	height: 40px !important;
	background: linear-gradient(to right, #5D9CEC 0%, #5D9CEC 100%);
	background-size: 100% 3px;
	background-position: center;
	background-repeat: no-repeat
}

.ios-slider::-webkit-slider-thumb {
	background-color: #fff !important;
	border: solid 1px rgba(0, 0, 0, .1);
	border-radius: 30px;
	height: 25px !important;
	width: 25px !important;
	box-shadow: -3px 3px 10px 1px rgba(0, 0, 0, .2) !important
}

.material-slider {
	height: 40px !important;
	background: linear-gradient(to right, #CCD1D9 0%, #CCD1D9 100%);
	background-size: 100% 18px;
	background-position: center;
	background-repeat: no-repeat;
	padding: 0 3px
}

.material-slider::-webkit-slider-thumb {
	background-color: #1f1f1f !important;
	border: solid 1px rgba(0, 0, 0, .1);
	height: 10px !important;
	width: 30px !important
}

.classic-slider {
	height: 20px !important;
	background-color: #fff;
	border: solid 1px rgba(0, 0, 0, .1);
	border-radius: 30px !important;
	background-size: 100% 25px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: transparent;
	padding: 0 3px;
	-webkit-box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, .1);
	box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, .1)
}

.classic-slider::-webkit-slider-thumb {
	background-color: #0d6efd !important;
	border: solid 1px transparent;
	height: 16px !important;
	border-radius: 20px;
	width: 16px !important;
	box-shadow: -3px 3px 10px 1px rgba(0, 0, 0, .2) !important
}

.range-slider-icons {
	padding: 0 30px
}

.range-slider.range-slider-icons i {
	position: absolute;
	top: 0;
	height: 44px;
	line-height: 44px;
	text-align: center
}

.range-slider {
	position: relative
}

.range-slider .fa-range-icon-1 {
	left: 0
}

.range-slider .fa-range-icon-2 {
	right: 0
}

.list-custom-small {
	line-height: 50px
}

.list-custom-small .badge {
	position: absolute;
	right: 15px;
	margin-top: 16px !important;
	font-size: 9px;
	padding: 5px 8px;
	font-weight: 700
}

.list-custom-small a {
	color: #1f1f1f;
	font-weight: 600;
	font-size: 13px;
	border-bottom: solid 1px rgba(0, 0, 0, .05)
}

.list-custom-small a i:first-child {
	margin-left: 0;
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin-top: 10px;
	text-align: center;
	float: left;
	margin-right: 10px
}

.list-custom-small a i:last-child {
	float: right;
	font-size: 10px;
	opacity: .5
}

.list-custom-small img {
	width: 30px;
	height: 30px;
	margin-right: 10px
}

.list-custom-small i {
	line-height: 50px
}

.list-custom-small .custom-control {
	z-index: 10;
	position: absolute;
	right: 40px;
	margin-top: -38px !important
}

.list-custom-small .custom-control input {
	transform: translateY(-5px) !important
}

.menu .list-custom-small a i:first-child {
	margin-left: 6px
}

.menu .list-custom-small img {
	margin-top: -4px !important
}

.list-custom-large a {
	line-height: 70px;
	color: #1f1f1f;
	font-family: inter, sans-serif;
	font-weight: 600;
	font-size: 13px;
	border-bottom: solid 1px rgba(0, 0, 0, .05)
}

.list-custom-large a span:not(.badge) {
	font-size: 14px;
	position: absolute;
	margin-top: -6px
}

.list-custom-large a strong {
	font-size: 7px;
	position: absolute;
	font-size: 11px;
	margin-top: 10px;
	color: #adb5bd;
	font-weight: 500
}

.list-custom-large a i:first-child {
	width: 32px;
	height: 32px;
	line-height: 32px;
	margin-top: 21px;
	text-align: center;
	float: left;
	margin-right: 15px
}

.list-custom-large a i:last-child {
	float: right;
	font-size: 10px
}

.list-custom-large img {
	width: 32px;
	height: 32px;
	margin-right: 10px
}

.list-custom-large .badge {
	position: absolute;
	right: 10px;
	margin-top: 30px;
	font-size: 9px;
	padding: 5px 8px;
	font-weight: 700
}

.list-custom-large i {
	line-height: 74px
}

.list-custom-large .custom-control {
	z-index: 10;
	position: absolute;
	right: 40px;
	margin-top: 22px
}

.list-custom-large .custom-control input {
	transform: translateY(-12px) !important
}

.list-boxes a {
	line-height: 60px;
	margin-bottom: 10px;
	color: #1f1f1f;
	font-weight: 500;
	font-size: 13px;
	border-bottom: solid 1px rgba(0, 0, 0, .05)
}

.list-boxes a span:not(.badge) {
	padding-left: 10px;
	font-size: 14px;
	position: absolute;
	margin-top: -4px
}

.list-boxes a strong {
	padding-left: 10px;
	font-size: 7px;
	position: absolute;
	font-size: 11px;
	margin-top: 10px;
	color: #6c757d;
	font-weight: 500
}

.list-boxes a i:first-child {
	width: 40px;
	float: left;
	padding-left: 15px
}

.list-boxes a i:last-child {
	float: right;
	font-size: 16px;
	margin-top: -5px;
	margin-right: 15px
}

.list-boxes a u {
	position: absolute;
	right: 0;
	margin-right: 9px;
	width: 60px;
	font-size: 7px;
	text-align: center;
	margin-top: 13px;
	text-decoration: none
}

.list-boxes .badge {
	position: absolute;
	right: 10px;
	margin-top: 30px;
	font-size: 9px;
	padding: 5px 8px;
	font-weight: 700
}

.list-boxes i {
	line-height: 64px
}

.short-border {
	margin-left: 45px
}

.short-border i:first-child {
	margin-left: -45px;
	margin-top: 23px
}

.list-group .badge {
	z-index: 2
}

.list-icon-0 a i:first-child {
	margin-left: -3px !important
}

.icon-list {
	margin-left: 0;
	padding-left: 0
}

.icon-list li {
	list-style: none
}

.icon-list li i {
	padding-right: 10px
}

.badge-text {
	margin-top: 13px !important;
	transform: translateY(-3px);
	padding-right: 20px !important
}

.check-visited .visited-link i:last-child::before {
	opacity: 0
}

.check-visited .visited-link i:last-child::after {
	font-family: "font awesome 5 free";
	font-weight: 900;
	position: absolute;
	right: 13px;
	font-size: 16px;
	margin-top: .5px;
	content: "";
	opacity: 1;
	color: #a0d468
}

.list-custom-small {
	line-height: 50px
}

.theme-light .list-custom-small .highlight-active span {
	font-weight: 700;
	color: #1f1f1f
}

.theme-dark .list-custom-small .highlight-active span {
	font-weight: 700;
	color: #fff
}

.todo-list.list-custom-large a {
	min-height: 70px
}

.todo-list.list-custom-small a {
	min-height: 40px
}

.todo-list .icon-check {
	float: right;
	margin-right: 28px
}

.pricing-1 {
	max-width: 280px;
	margin: 0 auto 30px;
	border: solid 1px rgba(0, 0, 0, .05);
	padding: 20px 0 30px
}

.pricing-1 * {
	list-style: none
}

.pricing-1 .pricing-icon {
	font-size: 50px
}

.pricing-1 .pricing-title {
	font-size: 32px;
	padding: 20px 0 0
}

.pricing-1 .pricing-value {
	font-size: 40px;
	font-weight: 300;
	padding: 20px 0 0
}

.pricing-1 .pricing-value sup {
	font-size: 12px;
	top: -18px
}

.pricing-1 .pricing-value sup:first-child {
	padding-right: 5px;
	top: -13px;
	font-size: 20px
}

.pricing-1 .pricing-subtitle {
	opacity: .6;
	font-size: 11px;
	line-height: 18px;
	font-weight: 300;
	padding: 0 0 10px
}

.pricing-1 .pricing-list {
	padding: 0
}

.pricing-1 .pricing-list li {
	line-height: 40px;
	border-bottom: solid 1px rgba(0, 0, 0, .05);
	font-size: 14px;
	font-weight: 500
}

.pricing-1 .pricing-list li:last-child {
	border-bottom: none;
	margin-bottom: -20px
}

.pricing-2 {
	max-width: 280px;
	margin: 0 auto 30px;
	border: solid 1px rgba(0, 0, 0, .05);
	padding: 0 0 10px;
	overflow: hidden
}

.pricing-2 * {
	list-style: none
}

.pricing-2 .pricing-icon {
	font-size: 50px;
	padding: 10px 0
}

.pricing-2 .pricing-title {
	font-size: 25px;
	padding: 15px 0;
	margin: -10px 0 20px
}

.pricing-2 .pricing-overtitle {
	font-size: 25px;
	padding: 15px 0;
	margin: -10px 0 0
}

.pricing-2 .pricing-value {
	font-size: 43px;
	padding: 15px 0 0
}

.pricing-2 .pricing-value sup {
	font-size: 12px;
	top: -18px
}

.pricing-2 .pricing-value sup:first-child {
	padding-right: 5px;
	top: -13px;
	font-size: 20px
}

.pricing-2 .pricing-subtitle {
	font-size: 11px;
	line-height: 18px;
	font-weight: 400;
	padding: 0
}

.pricing-2 .pricing-list {
	padding: 0
}

.pricing-2 .pricing-list li {
	line-height: 40px;
	border-bottom: solid 1px rgba(0, 0, 0, .04);
	font-size: 14px;
	font-weight: 500
}

.pricing-2 .pricing-list li:last-child {
	border-bottom: none
}

.pricing-3 {
	max-width: 280px;
	margin: 0 auto 30px;
	border: solid 1px rgba(0, 0, 0, .05);
	padding: 0 0 10px;
	overflow: hidden
}

.pricing-3 * {
	list-style: none
}

.pricing-3 .btn {
	max-width: 90%;
	margin: -10px auto 10px
}

.pricing-3 .pricing-icon {
	font-size: 50px
}

.pricing-3 .pricing-title {
	font-size: 28px;
	padding: 30px 0 0 20px
}

.pricing-3 .pricing-value {
	text-align: center;
	font-size: 40px;
	font-weight: 300;
	padding: 20px 0
}

.pricing-3 .pricing-value sup {
	font-size: 12px;
	top: -18px
}

.pricing-3 .pricing-value sup:first-child {
	padding-right: 5px;
	top: -13px;
	font-size: 20px
}

.pricing-3 .pricing-subtitle {
	opacity: .6;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	padding: 0 0 30px 20px;
	border-bottom: solid 1px rgba(0, 0, 0, .1)
}

.pricing-3 .pricing-list {
	padding: 0
}

.pricing-3 .pricing-list i {
	padding-right: 20px;
	width: 30px
}

.pricing-3 .pricing-list li {
	line-height: 50px;
	border-bottom: solid 1px rgba(0, 0, 0, .05);
	font-size: 14px;
	font-weight: 500;
	padding: 0 0 0 20px
}

.pricing-4 {
	max-width: 280px;
	margin: 0 auto 30px;
	border: solid 1px rgba(0, 0, 0, .05);
	padding: 0 0 10px;
	overflow: hidden
}

.pricing-4 * {
	list-style: none
}

.pricing-4 .pricing-title {
	font-size: 18px;
	padding: 15px 0;
	margin: 0
}

.pricing-4 .pricing-value {
	font-size: 43px;
	padding: 30px 0 20px
}

.pricing-4 .pricing-value sup {
	font-size: 12px;
	top: -18px
}

.pricing-4 .pricing-value sup:first-child {
	padding-right: 5px;
	top: -13px;
	font-size: 20px
}

.pricing-4 .pricing-subtitle {
	font-size: 11px;
	line-height: 18px;
	font-weight: 400;
	padding: 0 0 20px;
	margin-top: -10px
}

.pricing-4 .pricing-list {
	padding: 0;
	margin-bottom: 20px
}

.pricing-4 .pricing-list li {
	line-height: 40px;
	border-bottom: solid 1px rgba(0, 0, 0, .05);
	font-size: 14px;
	font-weight: 500
}

.pricing-4 .pricing-list li:last-child {
	border-bottom: none
}

.pricing-single {
	max-width: 280px;
	margin: 0 auto
}

.pricing-double-1 {
	width: 48%;
	float: left;
	margin-right: 4%
}

.pricing-double-2 {
	width: 48%;
	float: left
}

.pricing-list {
	list-style: none !important
}

.pricing-list ul {
	list-style: none !important
}

.pricing-list li {
	list-style: none !important
}

.toast-tiny {
	width: 150px;
	z-index: 90;
	position: fixed;
	left: 50%;
	line-height: 35px;
	padding: 0 20px;
	color: #fff;
	transition: all 200ms ease;
	max-width: auto !important;
	font-size: 12px;
	border-radius: 60px;
	border: 0 !important
}

.toast-bottom {
	bottom: 60px;
	bottom: calc(65px + (constant(safe-area-inset-bottom))*1.1) !important;
	bottom: calc(65px + (env(safe-area-inset-bottom))*1.1) !important;
	transform: translate(-50%, 60px)
}

.toast-bottom.toast-tiny.show {
	transform: translate(-50%, 0px)
}

.toast-top {
	top: 60px;
	top: calc(65px + (constant(safe-area-inset-bottom))*1.1) !important;
	top: calc(65px + (env(safe-area-inset-bottom))*1.1) !important;
	transform: translate(-50%, -60px)
}

.toast-top.toast-tiny.show {
	transform: translate(-50%, 0px)
}

.snackbar-toast {
	bottom: 70px !important;
	bottom: calc(70px + (constant(safe-area-inset-bottom))*1.1) !important;
	bottom: calc(70px + (env(safe-area-inset-bottom))*1.1) !important;
	position: fixed;
	left: 10px !important;
	right: 10px !important;
	padding: 0 18px;
	line-height: 50px;
	border-radius: 8px;
	overflow: hidden;
	z-index: 95;
	transform: translateY(300px);
	transition: all 300ms ease
}

.snackbar-toast.show {
	transform: translateY(0px)
}

.pagination {
	margin-bottom: 30px
}

.pagination .page-link {
	padding: 10px 14px
}

.pagination .page-item {
	margin-right: 5px;
	margin-left: 5px
}

.page-item.active a {
	color: #fff !important
}

.search-results {
	overflow: scroll
}

.disabled-search-list {
	position: relative;
	max-height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	overflow: hidden
}

.disabled-search {
	display: none
}

.search-box {
	line-height: 50px;
	border: solid 1px rgba(0, 0, 0, .08)
}

.search-box input {
	outline: none !important;
	width: 100%;
	background-color: transparent;
	padding-left: 40px;
	line-height: 45px
}

.search-box .fa-search {
	position: absolute;
	left: 0;
	line-height: 52px;
	padding: 0 15px 0 30px
}

.search-box a {
	position: absolute;
	right: 15px;
	font-size: 14px;
	line-height: 52px;
	width: 45px;
	text-align: center
}

.search-box input.bg-white {
	color: #000 !important
}

.search-color input {
	color: #fff
}

.search-color input::placeholder {
	color: #fff
}

.search-color i {
	color: #fff !important;
	z-index: 10
}

.search-icon-list {
	padding-left: 10px;
	padding-top: 10px
}

.search-icon-list a {
	color: #1f1f1f;
	line-height: 35px
}

.search-icon-list a i {
	padding-right: 10px
}

.search-page .search-result-list {
	position: relative;
	min-height: 80px;
	margin-bottom: 20px
}

.search-page .search-results img {
	width: 80px;
	position: absolute;
	border-radius: 10px
}

.search-page .search-results h1 {
	font-size: 15px;
	font-weight: 600;
	padding-left: 100px;
	margin-bottom: 0
}

.search-page .search-results p1 {
	font-size: 12px;
	line-height: 20px;
	padding-left: 100px;
	padding-right: 80px
}

.search-page .search-results a {
	position: absolute;
	right: 0;
	height: 30px;
	line-height: 31px;
	padding: 0 12px;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	border-radius: 10px
}

.tab-controls {
	display: flex
}

.tab-controls a {
	font-family: inter, sans-serif;
	color: #1f1f1f;
	background-color: #fff;
	width: 100%;
	text-align: center;
	border-right: solid 0 !important;
	border: solid 1px rgba(0, 0, 0, .07);
	align-self: center;
	margin: 0 auto
}

.tab-controls a:last-child {
	border-right: solid 1px rgba(0, 0, 0, .07) !important
}

.tabs-small {
	line-height: 35px
}

.tabs-medium {
	line-height: 40px
}

.tabs-large {
	line-height: 45px
}

.tabs-rounded a:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.tabs-rounded a:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px
}

.working-hours {
	border-radius: 5px
}

.working-hours p1 {
	padding-top: 34px;
	padding-bottom: 15px !important;
	line-height: 0;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: -8px !important;
	width: 33.3%;
	float: left;
	margin-bottom: 10px;
	display: inline-block
}

.working-hours p:nth-child(2) {
	text-align: center
}

.working-hours p:last-child {
	text-align: right
}

.working-hours::after {
	content: "";
	clear: both;
	display: table
}

.max-iframe {
	max-height: 200px !important;
	height: 300px !important;
	padding-bottom: 0 !important;
	margin: 0 auto
}

.responsive-iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none
}

.responsive-iframe object {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none
}

.responsive-iframe embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none
}

.footer {
	padding: 20px 30px 10px;
	margin-bottom: 0 !important;
	z-index: 11
}

.footer strong {
	font-size: 10px;
	display: block;
	text-align: center;
	font-weight: 700;
	margin-top: 0;
	text-transform: uppercase
}

.footer strong i {
	padding: 0 5px
}

.footer .footer-text {
	font-size: 12px;
	line-height: 22px;
	text-align: center;
	padding: 5px 10px 0
}

.footer .footer-copyright {
	border-top: solid 1px rgba(0, 0, 0, .05)
}

.footer .footer-links {
	border-top: solid 1px rgba(0, 0, 0, .05);
	padding-top: 13px;
	display: block;
	text-align: center;
	font-size: 10px;
	margin-top: 20px;
	margin-bottom: 10px
}

.footer .footer-links a {
	padding: 40px 5px 1px
}

.footer .footer-title {
	color: #1f1f1f;
	width: 100%;
	font-size: 28px;
	text-align: center;
	font-weight: 900;
	font-family: inter, sans-serif
}

.footer .footer-logo {
	position: relative;
	width: 100%;
	left: auto;
	margin: 0 auto;
	color: transparent;
	position: relative;
	width: 100%;
	left: auto;
	margin: 0 auto
}

.footer .footer-logo span {
	display: none !important
}

.footer-title {
	text-align: center;
	display: block
}

.footer-socials {
	max-width: 240px;
	margin: 0 auto 30px
}

.footer-socials a {
	float: left;
	font-size: 14px;
	margin: 0 4px;
	color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .08) !important
}

.footer-socials a i {
	width: 38px;
	line-height: 38px;
	text-align: center;
	display: block;
	float: left
}

.footer-socials:after {
	content: "";
	display: table;
	clear: both
}

.footer-copyright {
	border-top: solid 1px rgba(255, 255, 255, .03);
	text-align: center;
	margin-bottom: -5px;
	padding-top: 15px;
	font-size: 11px;
	line-height: 24px
}

.footer-logo span {
	display: none
}

.under-slider-btn {
	margin-top: -20px;
	position: relative;
	z-index: 50
}

.splide .under-slider-btn {
	margin-top: -45px
}

.splide .card {
	overflow: hidden
}

.double-slider .splide__pagination {
	margin-bottom: -15px !important
}

.splide.slider-no-arrows .splide__arrows {
	display: none !important
}

.splide.slider-no-dots .splide__pagination {
	display: none !important
}

.splide.slider-arrows .splide__arrow--prev {
	margin-left: 30px !important
}

.splide.slider-arrows .splide__arrow--next {
	margin-right: 30px !important
}

.cover-button-top {
	position: absolute;
	width: 170px;
	left: 50%;
	margin-left: -85px;
	top: 20px;
	top: calc(20px + (constant(safe-area-inset-top))*1.1) !important;
	top: calc(20px + (env(safe-area-inset-top))*1.1) !important;
	z-index: 99;
	font-size: 12px
}

.cover-button-bottom {
	position: absolute;
	left: 50px;
	right: 50px;
	bottom: 70px;
	z-index: 99;
	font-size: 12px
}

.cover-next {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 0
}

.cover-prev {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	left: 0
}

.theme-light #walkthrough-slider .is-active {
	background: #1f1f1f !important
}

.theme-dark #walkthrough-slider .is-active {
	background: #fff !important
}

#walkthrough-slider .splide__pagination {
	padding-bottom: 20px
}

.slider-has-dots-over .splide__pagination {
	padding-bottom: 40px
}

.visible-slider {
	padding-right: 70px !important
}

.slider-bottom-button {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2
}

.calendar {
	position: relative;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
	max-width: 90%;
	margin: 0 auto 30px
}

.cal-title {
	color: #fff;
	line-height: 60px;
	margin-bottom: 0;
	padding: 0 15px
}

.cal-sub-title {
	color: #fff;
	line-height: 60px;
	margin-bottom: 0;
	padding: 0 15px
}

.cal-title-right {
	float: right;
	margin-top: -60px;
	line-height: 62px;
	height: 50px;
	padding-right: 20px
}

.cal-title-left {
	float: left;
	margin-top: -60px;
	line-height: 62px;
	height: 50px;
	padding-left: 20px
}

.cal-days a {
	position: relative;
	width: 14.28%;
	float: left;
	text-align: center;
	height: 35px;
	line-height: 35px;
	color: #fff;
	position: realtive;
	z-index: 3;
	font-weight: 800
}

.cal-disabled {
	color: #ced4da !important
}

.cal-selected span {
	z-index: 2;
	position: relative;
	color: #fff
}

.cal-selected i {
	color: #000;
	z-index: 0;
	opacity: 1;
	font-size: 32px;
	position: absolute;
	width: 32px;
	height: 32px;
	top: 50%;
	left: 50%;
	margin: -16px 0 0 -16px;
	text-align: center;
	line-height: 32px
}

.cal-dates a {
	position: relative;
	width: 14.2857142857%;
	float: left;
	text-align: center;
	line-height: 45px;
	color: #1f1f1f;
	font-weight: 500
}

.theme-dark .cal-dates a {
	color: #fff
}

.theme-dark .cal-disabled {
	opacity: .3
}

.cal-dates-border a {
	overflow: hidden;
	border-right: solid 1px rgba(0, 0, 0, .05);
	border-bottom: solid 1px rgba(0, 0, 0, .05)
}

.cal-schedule {
	min-height: 80px;
	border-bottom: solid 1px rgba(0, 0, 0, .055)
}

.cal-schedule em {
	line-height: 20px;
	padding: 20px 0 0 20px;
	position: absolute;
	font-size: 11px;
	font-style: normal
}

.cal-schedule strong {
	color: #000;
	display: block;
	padding: 17px 0 0 100px;
	font-size: 14px
}

.cal-schedule span {
	display: block;
	font-size: 10px;
	color: #adb5bd;
	margin-top: 0
}

.cal-schedule span i {
	width: 50px;
	display: inline-block;
	text-align: center;
	padding: 0 20px 0 102px
}

.theme-dark .cal-schedule strong {
	color: #fff !important
}

.theme-dark .cal-schedule span {
	color: #fff !important;
	opacity: .5
}

.cal-message {
	display: block
}

.cal-message i {
	position: absolute;
	height: 40px;
	line-height: 43px;
	font-size: 27px;
	right: 20px
}

.cal-message strong {
	display: block;
	line-height: 20px;
	font-weight: 400;
	font-size: 11px;
	padding-left: 20px
}

.speech-left {
	max-width: 200px;
	float: right;
	border-bottom-right-radius: 0 !important;
	color: #fff
}

.speech-right {
	float: left;
	max-width: 200px;
	border-bottom-left-radius: 0 !important
}

.speach-image {
	padding: 0 !important
}

.speach-image img {
	margin: 0
}

.speech-last {
	margin-bottom: 0 !important
}

.speech-bubble {
	position: relative;
	border-radius: 17px;
	padding: 10px 15px;
	margin-bottom: 15px;
	line-height: 22px;
	font-size: 13px;
	background-color: #dee2e6;
	overflow: hidden
}

.speech-bubble:after {
	content: "";
	display: block;
	clear: both
}

.speech-read {
	font-size: 10px;
	font-style: normal;
	display: block;
	text-align: right;
	font-weight: 500;
	color: #adb5bd;
	margin-bottom: 80px
}

.speach-input input {
	width: 100%;
	display: block;
	line-height: 45px;
	height: 45px;
	margin-top: 8px;
	border-radius: 45px
}

.speach-icon a {
	padding-top: 0 !important;
	display: block;
	border-radius: 35px;
	width: 35px;
	height: 35px;
	line-height: 35px;
	margin-top: 3px;
	transform: translateY(10px)
}

.speech-icon-left,
.speech-icon-right {
	position: relative;
	display: block
}

.speech-icon-left img,
.speech-icon-right img {
	width: 30px;
	position: absolute;
	bottom: 0
}

.speech-icon-left img {
	left: 0
}

.speech-icon-right img {
	right: 0
}

.speech-icon-left .speech-bubble {
	margin-left: 20px !important
}

.speech-icon-right .speech-bubble {
	margin-right: 20px !important
}

.form-field span {
	position: absolute;
	right: 20px;
	font-size: 9px;
	opacity: .5
}

.form-field input {
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent !important;
	border-radius: 10px;
	margin: 0 0 15px
}

.form-field textarea {
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent !important;
	border-radius: 10px;
	margin: 0 0 15px
}

.form-name input {
	line-height: 40px;
	border: solid 1px rgba(0, 0, 0, .1);
	width: 100%;
	padding: 0 10px
}

.form-email input {
	line-height: 40px;
	border: solid 1px rgba(0, 0, 0, .1);
	width: 100%;
	padding: 0 10px
}

.form-text textarea {
	height: 145px;
	line-height: 24px;
	width: 100%;
	border: solid 1px rgba(0, 0, 0, .1);
	padding: 10px 10px 0;
	margin-bottom: 30px
}

.contactSubmitButton {
	width: 100%;
	display: block;
	border-radius: 10px;
	color: #fff !important;
	padding: 12px 0 !important
}

.fieldHasError {
	border: solid 1px #bf263c !important
}

.map-full .card-overlay {
	transition: all 350ms ease;
	z-index: 2
}

.map-full .card-center {
	z-index: 3
}

.hide-map {
	position: absolute;
	margin-top: -120px;
	width: 200px;
	left: 50%;
	margin-left: -100px;
	text-align: center;
	z-index: 2
}

.map-full .card {
	z-index: 2;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	bottom: 0
}

.map-full iframe {
	position: absolute;
	width: 100vw;
	height: 100vh
}

.activate-map {
	opacity: 1 !important;
	transition: all 250ms ease;
	transition: all 350ms ease
}

.deactivate-map {
	opacity: 0 !important;
	pointer-events: none;
	transition: all 350ms ease
}

.gallery-collection-round a img {
	border-radius: 10px
}

.gallery-collection a {
	width: 48%;
	float: left;
	position: relative
}

.gallery-collection a:nth-child(2n+2) {
	margin-left: 4%;
	margin-bottom: 4%
}

.gallery-collection a img {
	width: 50%;
	float: left;
	padding: 1px
}

.gallery-collection i {
	position: absolute;
	width: 50px;
	height: 50px;
	line-height: 50px;
	left: 50%;
	top: 50%;
	z-index: 10;
	margin-top: -25px;
	margin-left: -25px;
	text-align: center;
	background-color: #fff;
	border-radius: 50px;
	font-size: 17px;
	color: #1f1f1f
}

.gallery-view-controls a {
	float: left;
	width: 33.3%;
	text-align: center;
	height: 50px;
	line-height: 50px;
	font-size: 16px;
	color: #1f1f1f
}

.theme-dark .gallery-view-controls a {
	color: #fff
}

.theme-dark .gallery-view-controls {
	border-bottom: solid 1px rgba(255, 255, 255, .07)
}

.gallery-view-controls {
	border-bottom: solid 1px rgba(0, 0, 0, .05);
	margin-bottom: 30px
}

.gallery-view-1 a {
	width: 30%;
	float: left
}

.gallery-view-1 .caption {
	display: none
}

.gallery-view-2 .caption {
	display: none
}

.gallery-view-3 .caption {
	padding-top: 20px
}

.gallery-view-1 a {
	margin-bottom: 5%
}

.gallery-view-1 a:nth-child(3n-1) {
	margin: auto 5%
}

.gallery-view-2 a {
	width: 47.5%;
	margin-bottom: 5%;
	float: left
}

.gallery-view-2 a:nth-child(2n-1) {
	margin-right: 5%
}

.timeline-cover {
	height: 330px;
	width: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	background-position: center center;
	background-size: cover;
	margin-top: 50px
}

.timeline-cover .overlay {
	opacity: .8
}

.timeline-cover .c0ntent {
	z-index: 10
}

.timeline-b0dy {
	position: relative;
	margin-top: 200px;
	padding-top: 5px;
	z-index: 2;
	background-color: #f0f0f0;
	padding-bottom: 10px;
	box-shadow: none !important
}

.timeline-he4der {
	height: 55px;
	border-bottom: solid 1px rgba(255, 255, 255, .5)
}

.timeline-he4der .back-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 16px;
	color: #fff;
	z-index: 11
}

.timeline-he4der .menu-icon {
	position: absolute;
	top: 2px;
	right: 0;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 16px;
	color: #fff;
	z-index: 11
}

.timeline-he4der .menu-icon em {
	background-color: #fff
}

.timeline-logo {
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	z-index: 10;
	width: 100%
}

.timeline-deco {
	background-color: rgba(0, 0, 0, .08);
	position: absolute;
	top: 0;
	z-index: 0;
	bottom: 0;
	left: 43px;
	width: 1px
}

.timeline-item-content,
.timeline-item-content-full {
	border: solid 1px rgba(0, 0, 0, .1);
	background-color: #fff;
	margin: 0 15px 30px 90px;
	padding: 20px 15px
}

.timeline-item {
	position: relative;
	z-index: 2
}

.timeline-item-content-full {
	padding: 0
}

.timeline-item-content-full h5 {
	padding: 5px 15px 15px
}

.timeline-item .timeline-icon {
	position: absolute;
	width: 45px;
	height: 45px;
	left: 21px;
	line-height: 45px;
	border-radius: 45px;
	top: 25px;
	font-size: 18px;
	text-align: center
}

.timeline-cover-center .timeline-sub-heading,
.timeline-cover-center .timeline-heading {
	padding-left: 0;
	text-align: center
}

.timeline-cover-center .timeline-image {
	top: 50%;
	bottom: auto;
	margin-top: -150px;
	left: 50%;
	margin-left: -60px;
	background-size: 120px 120px;
	width: 120px;
	height: 120px;
	border-radius: 100px
}

.timeline-b0dy-center {
	padding-bottom: 40px
}

.timeline-b0dy-center .timeline-icon {
	width: 60px;
	height: 60px;
	left: 50%;
	line-height: 60px;
	margin-left: -31px;
	margin-top: -115px
}

.timeline-b0dy-center .timeline-deco {
	left: 50%;
	margin-left: -1px
}

.timeline-b0dy-center .timeline-item-content,
.timeline-b0dy-center .timeline-item-content-full {
	margin: 120px 20px 20px !important;
	padding: 20px
}

.interest-check input {
	position: absolute !important;
	transform: translateX(-150524px)
}

.interest-check .fa-check-circle {
	display: none
}

.interest-check i {
	width: 20px;
	text-align: center;
	pointer-events: none;
	position: absolute;
	left: 30px;
	top: 0;
	height: 40px;
	line-height: 40px
}

.interest-check {
	margin-left: -20px;
	margin-right: 10px;
	margin-bottom: 35px;
	height: 22px;
	cursor: pointer;
	position: relative;
	width: auto;
	display: inline-block
}

.interest-check label {
	cursor: pointer;
	font-weight: 500;
	font-size: 13px;
	font-family: inter, sans-serif;
	padding: 8px 15px 8px 40px
}

.interest-check input:checked~.fa {
	display: block !important
}

.interest-check input:checked~label {
	color: #fff !important;
	background-color: #4a89dc !important
}

.interest-check input:checked~.fa:last-child,
.interest-check input:checked~.fab:last-child,
.interest-check input:checked~.fas:last-child,
.interest-check input:checked~.far:last-child {
	display: none !important
}

.vcard-title {
	font-weight: 600;
	font-size: 18px;
	text-transform: uppercase
}

.vcard-field {
	line-height: 50px;
	border-left: 0 !important;
	border-top: 0 !important;
	border-right: 0 !important;
	padding-top: 15px;
	border-bottom: solid 1px rgba(0, 0, 0, .05);
	position: relative
}

.vcard-field strong {
	position: absolute;
	margin-top: -18px;
	color: #ababab;
	font-weight: 400;
	font-size: 10px
}

.vcard-field i {
	position: absolute;
	top: 25px;
	width: 15px;
	text-align: center;
	right: 0;
	opacity: .3
}

.vcard-field a {
	color: #000;
	font-weight: 400;
	font-size: 14px;
	margin-top: -10px;
	margin-bottom: 10px;
	opacity: .7
}

.bg-gradient {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.8) 70%, black 100%) !important
}

.bg-gradient-fade {
	bottom: 0 !important
}

.theme-light .bg-gradient-fade {
	background: linear-gradient(to bottom, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.99) 65%, #f0f0f0 100%)
}

.theme-dark .bg-gradient-fade {
	background: linear-gradient(to bottom, rgba(27, 29, 33, 0) 0%, rgba(27, 29, 33, 0.99) 65%, #1b1d21 100%)
}

.theme-light .bg-gradient-fade-small {
	background: linear-gradient(to bottom, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.2) 50%, rgba(240, 240, 240, 0.9) 75%, #f0f0f0 100%)
}

.theme-dark .bg-gradient-fade-small {
	background: linear-gradient(to bottom, rgba(27, 29, 33, 0) 0%, rgba(27, 29, 33, 0.2) 50%, rgba(27, 29, 33, 0.9) 75%, #1b1d21 100%)
}

.color-white {
	color: #fff !important
}

.color-black {
	color: #000 !important
}

.bg-white {
	background-color: #fff !important
}

.bg-black {
	background-color: #000 !important
}

.theme-dark .bg-theme {
	background-color: #0f1117 !important
}

.theme-light .bg-theme {
	background-color: #fff !important
}

.theme-light .color-theme {
	color: #0f1117 !important
}

.theme-dark .color-theme {
	color: #fff !important
}

.theme-dark .show-on-theme-dark {
	display: block
}

.theme-dark .show-on-theme-light {
	display: none
}

.theme-light .show-on-theme-light {
	display: block
}

.theme-light .show-on-theme-dark {
	display: none
}

.theme-dark p1 {
	color: #898989
}

.theme-dark code {
	color: #898989;
	opacity: .7
}

.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6,


.theme-dark .accordion-btn,
.theme-dark .menu .nav-title,
.theme-dark .menu .nav a i,
.theme-dark .menu .nav a span,
.theme-dark .menu .nav .active-nav i,
.theme-dark .menu .nav .active-nav span,
.theme-dark .he4der .he4der-icon,
.theme-dark .he4der .he4der-title,
.theme-dark .search-he4der .fa-search,
.theme-dark .color-theme,
.theme-dark .countdown div,
.theme-dark .list-group i:last-child,
.theme-dark .search-icon-list a,
.theme-dark .fac label,
.theme-dark .pagination a,
.theme-dark .accordion-style-2 a,
.theme-dark input,
.theme-dark .contact-form i,
.theme-dark .demo-icon-font article,
.theme-dark [data-tab],
.theme-dark span:not(.badge),
.theme-dark .profile-2 .profile-stats a,
.theme-dark .vcard-field *,
.theme-dark .search-he4der input,
.theme-dark .footer .footer-title {
	color: #fff !important
}

.theme-dark .loader-main,
.theme-dark .instant-article,
.theme-dark .divider-icon i,
.theme-dark .table tr.even td,
.theme-dark .timeline-b0dy,
.theme-dark table,
.theme-dark td {
	background-color: #1b1d21 !important
}

.theme-dark,
.theme-dark .page-bg,
.theme-dark #page {
	background-color: #1b1d21
}

.theme-dark .ios-switch label::before,
.theme-dark .android-switch label::before {
	background-color: #1b1d21;
	border: solid 1px rgba(255, 255, 255, .05) !important
}

.theme-dark .bg-theme {
	background-color: #0f1117 !important
}

.theme-dark .form-floating-over>.form-control:focus~label,
.theme-dark .form-floating-over>.form-control:not(:placeholder-shown)~label,
.theme-dark .form-floating-over>.form-select~label,
.theme-dark .footer,
.theme-dark .card,
.theme-dark #preloader {
	background-color: #0f1117
}

.theme-dark .link-list-3 a,
.theme-dark .pricing-1,
.theme-dark .pricing-2,
.theme-dark .pricing-3,
.theme-dark .pricing-4,
.theme-dark .search-box,
.theme-dark .link-list-2 a,
.theme-dark .vcard-field,
.theme-dark table {
	border: solid 1px rgba(255, 255, 255, .05)
}

.theme-dark select,
.theme-dark .form-field input,
.theme-dark .form-field textarea {
	color: #fff !important;
	border-color: rgba(255, 255, 255, .05) !important
}

.theme-dark input,
.theme-dark textarea,
.theme-dark select {
	border-color: rgba(255, 255, 255, .05)
}

.theme-dark input:focus,
.theme-dark textarea:focus,
.theme-dark select:focus {
	border-color: #495057 !important
}

.theme-dark .list-custom-large a,
.theme-dark .list-custom-small a {
	border-color: rgba(255, 255, 255, .05) !important
}

.theme-dark .timeline-deco {
	background-color: rgba(255, 255, 255, .05) !important
}

.theme-dark .footer-links,
.theme-dark #footer-bar,
.theme-dark .footer-copyright {
	border-top: solid 1px rgba(255, 255, 255, .05) !important
}

.theme-dark .border-bottom {
	border-bottom: 1px solid rgba(255, 255, 255, .05) !important
}

.theme-dark .border-top {
	border-top: 1px solid rgba(255, 255, 255, .05) !important
}

.theme-dark .border-left {
	border-left: 1px solid rgba(255, 255, 255, .05) !important
}

.theme-dark .border-right {
	border-right: 1px solid rgba(255, 255, 255, .05) !important
}

.theme-dark th,
.theme-dark .menu,
.theme-dark .he4der,
.theme-dark .polaroid-effect,
.theme-dark .timeline-item-content,
.theme-dark .tab-controls,
.theme-dark #footer-bar,
.theme-dark .menu .form-style label,
.theme-dark .menu .form-floating-style label,
.theme-dark .menu .form-border-bottom label,
.theme-dark .timeline-item-content-full {
	background-color: #21252a !important;
	color: #fff
}

.theme-dark .divider,
.theme-dark .menu .nav a:hover,
.theme-dark .menu .nav .active-subnav,
.theme-dark .menu .nav .divider {
	background-color: rgba(255, 255, 255, .05)
}

.theme-dark .menu-hider {
	background-color: rgba(0, 0, 0, .7)
}

.theme-dark .gallery-filter-controls li {
	color: #fff
}

.theme-dark .gallery-view-controls a {
	color: #fff
}

.theme-dark .gallery-view-controls {
	border-bottom: solid 1px rgba(255, 255, 255, .05)
}

.theme-dark .tab-controls a {
	border: solid 1px rgba(255, 255, 255, .05)
}

.theme-dark .form-control:focus,
.theme-dark .form-control {
	background-color: transparent !important
}

.theme-dark[data-gradient=b0dy-default] #page,
.b0dy1-default {
	background-image: none !important
}

.theme-dark input::placeholder,
.theme-dark textarea::placeholder {
	color: #fff !important
}

.theme-dark input:not([type=file]),
.theme-dark select,
.theme-dark textarea {
	color: #fff !important;
	background-color: transparent !important;
	border-color: rgba(255, 255, 255, .08) !important
}

.theme-dark .stepper a:first-child {
	border-right: solid 1px rgba(255, 255, 255, .1) !important
}

.theme-dark .stepper a:last-child {
	border-left: solid 1px rgba(255, 255, 255, .1) !important
}

.theme-dark .stepper {
	border: solid 1px rgba(255, 255, 255, .1) !important
}

.theme-dark label {
	background-color: #0f1117 !important
}

.theme-dark .menu label {
	background-color: #21252a !important
}

.theme-dark [data-card-height=cover] label {
	background-color: transparent !important
}

.bg-0 {
	background-image: url(../images/pictures/0l.jpg)
}

.bg-1 {
	background-image: url(../images/pictures/1.jpg)
}

.bg-2 {
	background-image: url(../images/pictures/2.jpg)
}

.bg-3 {
	background-image: url(../images/pictures/3.jpg)
}

.bg-4 {
	background-image: url(../images/pictures/4.jpg)
}

.bg-5 {
	background-image: url(../images/pictures/5.jpg)
}

.bg-6 {
	background-image: url(../images/pictures/6.jpg)
}

.bg-7 {
	background-image: url(../images/pictures/7.jpg)
}

.bg-8 {
	background-image: url(../images/pictures/8.jpg)
}

.bg-9 {
	background-image: url(../images/pictures/9.jpg)
}

.bg-10 {
	background-image: url(../images/pictures/10.jpg)
}

.bg-11 {
	background-image: url(../images/pictures/11.jpg)
}

.bg-12 {
	background-image: url(../images/pictures/12.jpg)
}

.bg-13 {
	background-image: url(../images/pictures/13.jpg)
}

.bg-14 {
	background-image: url(../images/pictures/14.jpg)
}

.bg-15 {
	background-image: url(../images/pictures/15.jpg)
}

.bg-16 {
	background-image: url(../images/pictures/16.jpg)
}

.bg-17 {
	background-image: url(../images/pictures/17.jpg)
}

.bg-18 {
	background-image: url(../images/pictures/18.jpg)
}

.bg-19 {
	background-image: url(../images/pictures/19.jpg)
}

.bg-20 {
	background-image: url(../images/pictures/20.jpg)
}

.bg-21 {
	background-image: url(../images/pictures/21.jpg)
}

.bg-22 {
	background-image: url(../images/pictures/22.jpg)
}

.bg-23 {
	background-image: url(../images/pictures/23.jpg)
}

.bg-24 {
	background-image: url(../images/pictures/24.jpg)
}

.bg-25 {
	background-image: url(../images/pictures/25.jpg)
}

.bg-26 {
	background-image: url(../images/pictures/26.jpg)
}

.bg-27 {
	background-image: url(../images/pictures/27.jpg)
}

.bg-28 {
	background-image: url(../images/pictures/28.jpg)
}

.bg-29 {
	background-image: url(../images/pictures/29.jpg)
}

.bg-30 {
	background-image: url(../images/pictures/30.jpg)
}

.bg-31 {
	background-image: url(../images/pictures/31.jpg)
}

.bg-32 {
	background-image: url(../images/pictures/32.jpg)
}

.bg-33 {
	background-image: url(../images/pictures/33.jpg)
}

.bg-34 {
	background-image: url(../images/pictures/34.jpg)
}

.demo-color {
	display: block;
	width: 100%;
	line-height: 45px;
	padding-left: 10px;
	text-transform: capitalize;
	border-bottom: solid 1px rgba(255, 255, 255, .05)
}

.demo-color strong {
	display: block;
	font-size: 9px;
	margin-top: -25px;
	padding-bottom: 3px;
	font-weight: 400;
	opacity: .8
}

.demo-color span {
	font-size: 10px;
	position: absolute;
	right: 20px;
	line-height: 48px;
	color: rgba(255, 255, 255, .5)
}

.theme-change-transition {
	transition: all 150ms ease !important
}

.background-changer a {
	width: 20%;
	float: left;
	text-align: center;
	line-height: 42px;
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: -5px
}

.background-changer a i {
	margin-top: 10px;
	display: block;
	width: 26px;
	height: 26px;
	border-radius: 100%;
	color: transparent;
	margin: 10px auto 0
}

.background-changer .bg-theme {
	border: solid 1px rgba(0, 0, 0, .2)
}

.background-changer a span {
	font-size: 11px;
	display: block;
	margin-top: -10px;
	margin-bottom: -5px
}

@keyframes splide-loading {
	0% {
		transform: rotate(0)
	}

	to {
		transform: rotate(1turn)
	}
}

.splide__container {
	position: relative;
	box-sizing: border-box
}

.splide__list {
	margin: 0 !important;
	padding: 0 !important;
	width: -webkit-max-content;
	width: max-content;
	will-change: transform
}

.splide.is-active .splide__list {
	display: flex
}

.splide__pagination {
	display: inline-flex;
	align-items: center;
	width: 95%;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0
}

.splide__pagination li {
	list-style-type: none;
	display: inline-block;
	line-height: 1;
	margin: 0
}

.splide {
	visibility: hidden
}

.splide,
.splide__slide {
	position: relative;
	outline: none
}

.splide__slide {
	box-sizing: border-box;
	list-style-type: none !important;
	margin: 0;
	flex-shrink: 0
}

.splide__slide img {
	vertical-align: bottom
}

.splide__slider {
	position: relative
}

.splide__spinner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #999;
	border-left-color: transparent;
	animation: splide-loading 1s linear infinite
}

.splide__track {
	position: relative;
	z-index: 0
}

.splide--draggable>.splide__track>.splide__list>.splide__slide {
	-webkit-user-select: none;
	user-select: none
}

.splide--fade>.splide__track>.splide__list {
	display: block
}

.splide--fade>.splide__track>.splide__list>.splide__slide {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	opacity: 0
}

.splide--fade>.splide__track>.splide__list>.splide__slide.is-active {
	position: relative;
	z-index: 1;
	opacity: 1
}

.splide--rtl {
	direction: rtl
}

.splide--ttb>.splide__track>.splide__list {
	display: block
}

.splide--ttb>.splide__pagination {
	width: auto
}

.splide__arrow {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	width: 2em;
	height: 2em;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	padding: 0;
	opacity: .7;
	background: #ccc
}

.splide__arrow svg {
	width: 1.2em;
	height: 1.2em
}

.splide__arrow:hover {
	cursor: pointer;
	opacity: .9
}

.splide__arrow:focus {
	outline: none
}

.splide__arrow--prev {
	left: 1em
}

.splide__arrow--prev svg {
	transform: scaleX(-1)
}

.splide__arrow--next {
	right: 1em
}

.splide__pagination {
	position: absolute;
	z-index: 1;
	bottom: .5em;
	left: 50%;
	transform: translateX(-50%);
	padding: 0
}

.splide__pagination__page {
	display: inline-block;
	width: 8px;
	height: 8px;
	background: #ccc;
	border-radius: 50%;
	margin: 0 7px;
	padding: 0;
	transition: transform .2s linear;
	border: none;
	opacity: .7
}

.splide__pagination__page.is-active {
	transform: scale(1.4);
	background: #fff
}

.splide__pagination__page:hover {
	cursor: pointer;
	opacity: .9
}

.splide__pagination__page:focus {
	outline: none
}

.splide__progress__bar {
	width: 0;
	height: 3px;
	background: #ccc
}

.splide--nav>.splide__track>.splide__list>.splide__slide {
	border: 3px solid transparent
}

.splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
	border-color: #000
}

.splide--nav>.splide__track>.splide__list>.splide__slide:focus {
	outline: none
}

.splide--rtl>.splide__arrows .splide__arrow--prev,
.splide--rtl>.splide__track>.splide__arrows .splide__arrow--prev {
	right: 1em;
	left: auto
}

.splide--rtl>.splide__arrows .splide__arrow--prev svg,
.splide--rtl>.splide__track>.splide__arrows .splide__arrow--prev svg {
	transform: scaleX(1)
}

.splide--rtl>.splide__arrows .splide__arrow--next,
.splide--rtl>.splide__track>.splide__arrows .splide__arrow--next {
	left: 1em;
	right: auto
}

.splide--rtl>.splide__arrows .splide__arrow--next svg,
.splide--rtl>.splide__track>.splide__arrows .splide__arrow--next svg {
	transform: scaleX(-1)
}

.splide--ttb>.splide__arrows .splide__arrow,
.splide--ttb>.splide__track>.splide__arrows .splide__arrow {
	left: 50%;
	transform: translate(-50%)
}

.splide--ttb>.splide__arrows .splide__arrow--prev,
.splide--ttb>.splide__track>.splide__arrows .splide__arrow--prev {
	top: 1em
}

.splide--ttb>.splide__arrows .splide__arrow--prev svg,
.splide--ttb>.splide__track>.splide__arrows .splide__arrow--prev svg {
	transform: rotate(-90deg)
}

.splide--ttb>.splide__arrows .splide__arrow--next,
.splide--ttb>.splide__track>.splide__arrows .splide__arrow--next {
	top: auto;
	bottom: 1em
}

.splide--ttb>.splide__arrows .splide__arrow--next svg,
.splide--ttb>.splide__track>.splide__arrows .splide__arrow--next svg {
	transform: rotate(90deg)
}

.splide--ttb>.splide__pagination {
	display: flex;
	flex-direction: column;
	bottom: 50%;
	left: auto;
	right: .5em;
	transform: translateY(50%)
}

.slider-cover-dots .splide__pagination {
	transform: translate(-50%, -40px) !important
}

.page-title-fixed {
	transition: transform 0.3s ease-in-out;
  }
  
  .page-title-hidden {
	transform: translateY(-100%);
  }