.footerBar6 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
    background-color: #1f1f1f;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    min-height: 61px;
    text-align: center;
    transition: all 0.3s ease;
    padding: 0px 0px 0px 0px;
  }
  
  .footerBar6 a {
    color: #ffffff;
    opacity: 0.6;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .footerBar6 a:hover,
  .footerBar6 a.activeNav {
    opacity: 1;
  }
  
  .footerBar6 a svg {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
    fill: currentColor;
    transition: all 0.3s ease;
  }
  
  .footerBar6 a span {
    font-size: 10px;
    display: block;
    text-align: center;
  }
  
  .activeNav {
    opacity: 1;
    z-index: 9999;
  }
  
  .activeNav svg {
    background: linear-gradient(to bottom, #5d9cec, #4a89dc);
    border-radius: 50%;
    padding: 10px;
    margin-top: -20px;
    width: 30px !important;
    height: 30px !important;
    color: white;
    z-index: 9999;
  }
  
  .activeNav span {
    margin-top: 5px;
    z-index: 9999;
  }
  
  @keyframes pulseAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }